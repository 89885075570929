import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';

export const MerchantStandardRow = styled(TableRow)`
  background-color: white;
  border-top: ${({ theme }) => theme.color.beige} solid .5rem;
`;

export const MerchantExtendedRow = styled(TableRow)`
  background-color: white;
`;
