import React, { useContext, useEffect } from 'react';
import L from 'leaflet';
import {
  FeatureGroup,
  LayersControl,
  Polygon,
  Popup,
  TileLayer,
  WMSTileLayer,
  ZoomControl,
  Tooltip,
  useMap,
} from 'react-leaflet';
import { LanguageContext } from '../../../lib/Language';
import * as S from './styles';
import { QuoteShape } from '../proptypes/quote';

const getCoordinates = (roof) => {
  if (roof?.geometry?.coordinates?.length > 0) {
    const points = roof.geometry.coordinates[0].map((arr) => [arr[1], arr[0]]);
    return points;
  }
  return null;
};

const PolyGroup = L.featureGroup();

const Bounds = (props) => {
  const { roofs } = props;
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    PolyGroup.clearLayers();

    roofs.forEach((roof) => {
      const coordinates = getCoordinates(roof);

      if (coordinates) {
        PolyGroup.addLayer(
          L.polygon(coordinates, {
            stroke: false,
            fill: false,
          }),
        );
        map.fitBounds(PolyGroup.getBounds(), { padding: [20, 20] });
      }
    });
  }, [map]);

  return null;
};

const RoofMapInteractive = ({
  quote,
  setSelectedRoofId,
}) => {
  const { language } = useContext(LanguageContext);

  if (!quote) return null;

  return (
    <S.MapContainer
      center={[quote.map_position.y, quote.map_position.x]}
      zoomControl={false}
      zoom={20}
      maxZoom={20}
      scrollWheelZoom={false}
      tap={false}
      preferCanvas
    >
      <LayersControl position="topright">

        <LayersControl.BaseLayer checked name="Kart">
          <TileLayer
            maxZoom={20}
            url="https://wwwnew.webatlas.no/maptiles/tiles/webatlas-gray-vektor/wa_grid/{z}/{x}/{y}.png?api_key=680ae64b-8423-4a3c-b303-39f32adcea41"
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Flyfoto (med navn)">
          <TileLayer
            maxZoom={20}
            url="https://waapi.webatlas.no/maptiles/tiles/webatlas-standard-hybrid/wa_grid/{z}/{x}/{y}.jpeg?api_key=680ae64b-8423-4a3c-b303-39f32adcea41"
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Flyfoto (uten navn)">
          <TileLayer
            maxZoom={20}
            url="https://waapi.webatlas.no/maptiles/tiles/webatlas-orto-newup/wa_grid/{z}/{x}/{y}.jpeg?api_key=680ae64b-8423-4a3c-b303-39f32adcea41"
          />
        </LayersControl.BaseLayer>

        <LayersControl.Overlay name="Takflater">
          <WMSTileLayer
            url="https://waapi.webatlas.no/WMS-Takhelning/?service=WMS&request=GetMap&api_key=680ae64b-8423-4a3c-b303-39f32adcea41"
            layers="takhelning"
            styles="takflate-solcelle-norkart"
            transparent="true"
            format="image/png"
            maxZoom={20}
          />
        </LayersControl.Overlay>

        <LayersControl.Overlay checked name="Polygoner">
          <FeatureGroup>
            {quote.roofs.filter((r) => r.geometry && r.geometry.coordinates && r.geometry.coordinates.length > 0).map((roof = {}, index) => {
              const coords = roof.geometry.coordinates[0].map((coord) => (
                [coord[1], coord[0]]
              ));
              return (
                <Polygon
                  key={roof.id}
                  fillColor={roof.isSelected ? '#6c8d87' : 'rgb(61,68,78)'}
                  fillOpacity={roof.isSelected ? 0.75 : 0.5}
                  color="rgb(61,68,78)"
                  weight={1}
                  opacity={0.75}
                  positions={coords}
                  eventHandlers={{
                    click: () => {
                      setSelectedRoofId(roof.id);
                    },
                  }}
                >
                  <Tooltip>
                    <strong>{`Tak ${index + 1}`}</strong>
                  </Tooltip>
                  <Popup
                    onClose={() => setSelectedRoofId(null)}
                    onOpen={() => setSelectedRoofId(roof.id)}
                  >
                    <div style={{ fontSize: '1.1rem' }}><b>{` Tak ${index + 1}`}</b></div>
                    <div>
                      <div>{`Lengde: ${new Intl.NumberFormat(language).format(Math.round(roof.length * 10) / 10)} m`}</div>
                      <div>{`Bredde: ${new Intl.NumberFormat(language).format(Math.round(roof.width * 10) / 10)} m`}</div>
                      <div>{`Areal: ${new Intl.NumberFormat(language).format(Math.round(roof.area3d * 10) / 10)} m²`}</div>
                      <div>{`Grunnflate: ${new Intl.NumberFormat(language).format(Math.round(roof.area2d * 10) / 10)} m²`}</div>
                      <div>{`Helning: ${new Intl.NumberFormat(language).format(Math.round(roof.slope * 10) / 10)} grader`}</div>
                      <div>{`Tilgjengelig areal: ${new Intl.NumberFormat(language).format(Math.round(roof.availableSpace * 10) / 10)} m²`}</div>
                      <div>{`Maks antall panel: ${roof.panelCountMax}`}</div>
                      <div>{`Retning: ${new Intl.NumberFormat(language).format(Math.round(roof.azimuth * 10) / 10)} grader`}</div>
                      <div>{`Årlig produksjon: ${new Intl.NumberFormat(language).format(Math.round(roof.estimatedAnnualProduction * 10) / 10)} kWh`}</div>
                      {
                        roof.eavesHeight != null
                          ? <div>{`Takfot høyde: ${new Intl.NumberFormat(language).format(Math.round(roof.eavesHeight * 10) / 10)} m`}</div>
                          : null
                      }
                    </div>
                  </Popup>
                </Polygon>
              );
            })}
          </FeatureGroup>
        </LayersControl.Overlay>
        <Bounds roofs={quote.roofs} />
      </LayersControl>
      <ZoomControl position="topleft" />
    </S.MapContainer>
  );
};

export default RoofMapInteractive;

RoofMapInteractive.defaultProps = {
  quote: undefined,
};

RoofMapInteractive.propTypes = {
  quote: QuoteShape,
};
