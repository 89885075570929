export const productRowsSolcellekraft = [
  {
    name: 'Solcellepaneler (*)',
    price: 'solarPanels',
    purchaseDiscountPercentage: 'solarPanelsPercentage',
    priceAdjustmentPercentage: 'solarPanelsPercentage',
    heading: 'Utstyr',
  },
  {
    name: 'Invertere (*)',
    price: 'inverters',
    purchaseDiscountPercentage: 'invertersPercentage',
    priceAdjustmentPercentage: 'invertersPercentage',
  },
  {
    name: 'Kabler per panel',
    price: 'cableCostPerPanel',
    purchaseDiscountPercentage: 'cableCostPerPanelPercentage',
    priceAdjustmentPercentage: 'cableCostPerPanelPercentage',
  },
  {
    name: 'Kontakter per panel',
    price: 'connectorCostPerPanel',
    purchaseDiscountPercentage: 'connectorCostPerPanelPercentage',
    priceAdjustmentPercentage: 'connectorCostPerPanelPercentage',
  },
  {
    name: 'Monteringssett per panel (Skifer)',
    price: 'mountingKitPerPanelSlate',
    purchaseDiscountPercentage: 'mountingKitPerPanelSlatePercentage',
    priceAdjustmentPercentage: 'mountingKitPerPanelSlatePercentage',
  },
  {
    name: 'Monteringssett per panel (Takstein)',
    price: 'mountingKitPerPanelRoofTiles',
    purchaseDiscountPercentage: 'mountingKitPerPanelRoofTilesPercentage',
    priceAdjustmentPercentage: 'mountingKitPerPanelRoofTilesPercentage',
  },
  {
    name: 'Monteringssett per panel (Takstein stålplater)',
    price: 'mountingKitPerPanelRoofTilesSteelPlates',
    purchaseDiscountPercentage: 'mountingKitPerPanelRoofTilesSteelPlatesPercentage',
    priceAdjustmentPercentage: 'mountingKitPerPanelRoofTilesSteelPlatesPercentage',
  },
  {
    name: 'Monteringssett per panel (Takpapp)',
    price: 'mountingKitPerPanelRoofFelt',
    purchaseDiscountPercentage: 'mountingKitPerPanelRoofFeltPercentage',
    priceAdjustmentPercentage: 'mountingKitPerPanelRoofFeltPercentage',
  },
  {
    name: 'Monteringssett per panel (Takshingel)',
    price: 'mountingKitPerPanelRoofShingles',
    purchaseDiscountPercentage: 'mountingKitPerPanelRoofShinglesPercentage',
    priceAdjustmentPercentage: 'mountingKitPerPanelRoofShinglesPercentage',
  },
  {
    name: 'Monteringssett per panel (Stålplater)',
    price: 'mountingKitPerPanelRoofSteelPlates',
    purchaseDiscountPercentage: 'mountingKitPerPanelRoofSteelPlatesPercentage',
    priceAdjustmentPercentage: 'mountingKitPerPanelRoofSteelPlatesPercentage',
  },
  {
    name: 'Monteringssett per panel (Integrert solcellepanel)',
    price: 'mountingKitPerPanelIntegratedSolarPanels',
    purchaseDiscountPercentage: 'mountingKitPerPanelIntegratedSolarPanelsPercentage',
    priceAdjustmentPercentage: 'mountingKitPerPanelIntegratedSolarPanelsPercentage',
  },
  {
    name: 'Monteringssett per panel (Decra)',
    price: 'mountingKitPerPanelDecra',
    purchaseDiscountPercentage: 'mountingKitPerPanelDecraPercentage',
    priceAdjustmentPercentage: 'mountingKitPerPanelDecraPercentage',
  },
  {
    name: 'Monteringssett per panel (Ballast)',
    price: 'mountingKitPerPanelBallast',
    purchaseDiscountPercentage: 'mountingKitPerPanelBallastPercentage',
    priceAdjustmentPercentage: 'mountingKitPerPanelBallastPercentage',
  },
  // Removing ballast systems may be temporarily!
  // {
  //   name: 'Ballastsystem',
  //   price: 'ballastSystemPerFlatRoof',
  //   purchaseDiscountPercentage: 'ballastSystemPerFlatRoofPercentage',
  //   priceAdjustmentPercentage: 'ballastSystemPerFlatRoofPercentage',
  // },
  {
    name: 'Wifi-modul',
    price: 'wifiModule',
    purchaseDiscountPercentage: 'wifiModulePercentage',
    priceAdjustmentPercentage: 'wifiModulePercentage',
  },
  {
    name: 'Prosjektering fastpris',
    price: 'engineeringCostPerInstallation',
    purchaseDiscountPercentage: 'engineeringCostPerInstallationPercentage',
    priceAdjustmentPercentage: 'engineeringCostPerInstallationPercentage',
  },
  {
    name: 'Prosjektering per panel',
    price: 'engineeringCostPerPanel',
    purchaseDiscountPercentage: 'engineeringCostPerPanelPercentage',
    priceAdjustmentPercentage: 'engineeringCostPerPanelPercentage',
  },
  {
    name: 'Oppstart',
    price: 'mountingStartupCost',
    purchaseDiscountPercentage: 'mountingStartupCostPercentage',
    priceAdjustmentPercentage: 'mountingStartupCostPercentage',
    heading: 'Takmontering',
  },
  {
    name: 'Per panel',
    price: 'mountingCostPerPanel',
    purchaseDiscountPercentage: 'mountingCostPerPanelPercentage',
    priceAdjustmentPercentage: 'mountingCostPerPanelPercentage',
  },
  {
    name: 'Oppstart',
    price: 'electricityMountingStartupCost',
    purchaseDiscountPercentage: 'electricityMountingStartupCostPercentage',
    priceAdjustmentPercentage: 'electricityMountingStartupCostPercentage',
    heading: 'Elektrisk montering',
  },
  {
    name: 'Per panel',
    price: 'electricityMountingCostPerPanel',
    purchaseDiscountPercentage: 'electricityMountingCostPerPanelPercentage',
    priceAdjustmentPercentage: 'electricityMountingCostPerPanelPercentage',
  },
];

export const productRowsTakbytte = [
  {
    name: 'Type takstein (*)',
    price: 'roofingType',
    purchaseDiscountPercentage: 'roofingTypePercentage',
    priceAdjustmentPercentage: 'roofingTypePercentage',
    heading: 'Utstyr',
  },
  {
    name: 'Gavelstein',
    price: 'ridgeTiles',
    purchaseDiscountPercentage: 'ridgeTilesPercentage',
    priceAdjustmentPercentage: 'ridgeTilesPercentage',
  },
  {
    name: 'Lekter',
    price: 'battens',
    purchaseDiscountPercentage: 'battensPercentage',
    priceAdjustmentPercentage: 'battensPercentage',
  },
  {
    name: 'Rekker',
    price: 'battensRows',
    purchaseDiscountPercentage: 'battensRowsPercentage',
    priceAdjustmentPercentage: 'battensRowsPercentage',
  },
  {
    name: 'Festemateriell',
    price: 'fasteningMaterial',
    purchaseDiscountPercentage: 'fasteningMaterialPercentage',
    priceAdjustmentPercentage: 'fasteningMaterialPercentage',
  },
  {
    name: 'Konteiner',
    price: 'container',
    purchaseDiscountPercentage: 'containerPercentage',
    priceAdjustmentPercentage: 'containerPercentage',
  },
  {
    name: 'Takrenne',
    price: 'gutter',
    purchaseDiscountPercentage: 'gutterPercentage',
    priceAdjustmentPercentage: 'gutterPercentage',
  },
  {
    name: 'Nedløp',
    price: 'drain',
    purchaseDiscountPercentage: 'drainPercentage',
    priceAdjustmentPercentage: 'drainPercentage',
  },
  {
    name: 'Stillas, tak lavere enn 5m',
    price: 'scaffoldingLow',
    purchaseDiscountPercentage: 'scaffoldingLowPercentage',
    priceAdjustmentPercentage: 'scaffoldingLowPercentage',
  },
  {
    name: 'Stillas, høyere enn 5m',
    price: 'scaffoldingMedium',
    purchaseDiscountPercentage: 'scaffoldingMediumPercentage',
    priceAdjustmentPercentage: 'scaffoldingMediumPercentage',
  },
  {
    name: 'Klips',
    price: 'clips',
    purchaseDiscountPercentage: 'clipsPercentage',
    priceAdjustmentPercentage: 'clipsPercentage',
  },
  {
    name: 'Undertak',
    price: 'roofCovering',
    purchaseDiscountPercentage: 'roofCoveringPercentage',
    priceAdjustmentPercentage: 'roofCoveringPercentage',
  },
  {
    name: 'Diverse',
    price: 'others',
    purchaseDiscountPercentage: 'othersPercentage',
    priceAdjustmentPercentage: 'othersPercentage',
  },
  {
    name: 'Prosjektering fastpris',
    price: 'engineeringCostPerInstallation',
    purchaseDiscountPercentage: 'engineeringCostPerInstallationPercentage',
    priceAdjustmentPercentage: 'engineeringCostPerInstallationPercentage',
    heading: 'Takmontering',
  },
  {
    name: 'Ekstra kostnad per tak',
    price: 'extraCostPerRoof',
    purchaseDiscountPercentage: 'costPerRoofPercentage',
    priceAdjustmentPercentage: 'costPerRoofPercentage',
  },
  {
    name: 'Prosjektering per tak',
    price: 'engineeringCostPerRoof',
    purchaseDiscountPercentage: 'engineeringCostPerRoofPercentage',
    priceAdjustmentPercentage: 'engineeringCostPerRoofPercentage',
  },
  {
    name: 'Ekstra kostnad takhelling over 35°',
    price: 'extraCostHighPitch',
    purchaseDiscountPercentage: 'extraCostHighPitchPercentage',
    priceAdjustmentPercentage: 'extraCostHighPitchPercentage',
  },
];
