import styled from 'styled-components';
import Button from '@mui/material/Button';

export const UserInfo = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  b:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const StandardButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.lightOrange};
  width: 8rem;
  color: white;
  
  &:hover {
    background: ${({ theme }) => `${theme.color.primaryHover}60`};
  }
`;
