export const saleStatusEnum = {
  NOT_ORDERED: 'NOT_ORDERED',
  ORDERED: 'ORDERED',
  OFFER_SENT: 'OFFER_SENT',
  REJECTED: 'REJECTED',
  SALE: 'SALE',
};

export const saleStatuses = [
  saleStatusEnum.NOT_ORDERED,
  saleStatusEnum.ORDERED,
  saleStatusEnum.OFFER_SENT,
  saleStatusEnum.REJECTED,
  saleStatusEnum.SALE,
];

export const isValidSaleStatus = (value) => saleStatuses.some((saleStatus) => value === saleStatus);
