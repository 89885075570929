import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import Collapse from '@mui/material/Collapse';
import Add from '@mui/icons-material/Add';
import ProgressWithBackDrop from '../../progressWithBackDrop/ProgressWithBackDrop';
import UserForm from '../userform/UserForm';
import * as SLocal from '../../../pages/dashboard/users/styles';

const UserPageHeader = ({ userInfo, merchantList, refreshUserList }) => {
  const [showNewUser, setShowNewUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [errorMsg, setErrorMsg] = useState('');

  const createUser = useCallback(async (newUser) => {
    setLoading(true);

    const body = {
      email: newUser.email,
      firstname: newUser.firstName,
      lastname: newUser.lastName,
      role: newUser.role,
      merchantId: userInfo.merchantId || newUser.merchantId,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify(body),
    };

    const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_USERS_ENDPOINT}`;
    const res = await fetch(url, requestOptions);

    if (res.status > 199 && res.status < 299) {
      await refreshUserList(false);
      setShowNewUser(false);
    }
    if (res.status === 409) {
      setErrorMsg('Det finnes allerede en bruker med denne epost adressen. Prøv igjen med en annen epost adresse');
    }
    if (res.status === 500) {
      setErrorMsg('Noe feilet, prøv igjen.');
    }

    setLoading(false);
  }, [userInfo.merchantId]);

  return (
    <ProgressWithBackDrop loading={loading} fitContent>
      <Collapse in={!showNewUser} timeout="auto" unmountOnExit>
        <SLocal.UserInfo>
          <div>
            <b>Innlogget: </b>
            {userInfo?.name}
            <b>Rolle: </b>
            {userInfo?.role}
          </div>
          <SLocal.StandardButton
            style={{ width: '250px' }}
            variant="outlined"
            onClick={() => { setShowNewUser(true); setErrorMsg(''); }}
            startIcon={<Add />}
          >
            Legg til bruker
          </SLocal.StandardButton>
        </SLocal.UserInfo>
      </Collapse>
      <Collapse in={showNewUser} timeout="auto" unmountOnExit>
        <b>Legg til bruker:</b>
        <UserForm
          onSubmit={createUser}
          onCancel={() => { setShowNewUser(false); setErrorMsg(''); }}
          userInfo={userInfo}
          merchants={merchantList}
          isNew
        />
        <SLocal.ErrorMsg>{errorMsg}</SLocal.ErrorMsg>
      </Collapse>
    </ProgressWithBackDrop>
  );
};

UserPageHeader.defaultProps = {
  userInfo: {},
  merchantList: [],
  refreshUserList: () => {},
};

UserPageHeader.propTypes = {
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    merchantId: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    isAdmin: PropTypes.bool,
  }),
  merchantList: PropTypes.arrayOf(PropTypes.shape({
    merchant_id: PropTypes.string,
    name: PropTypes.string,
  })),
  refreshUserList: PropTypes.func,
};

export default UserPageHeader;
