import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isURL from 'validator/es/lib/isURL';
import { useAuth0 } from '@auth0/auth0-react';
import ProgressWithBackDrop from '../../progressWithBackDrop/ProgressWithBackDrop';
import PriceDifferentiationTableButtons from '../pricedifferentiationtable/PriceDifferentiationTableButtons';
import * as S from './styles';

const FORM_CONFIG = [
  { label: 'Webhook beregning opprettet', name: 'webhook_quote_created' },
  { label: 'Webhook beregning oppdatert', name: 'webhook_quote_updated' },
  { label: 'Webhook beregning selttet', name: 'webhook_quote_deleted' },
];

const updateWebhooks = async (merchantId, getAccessTokenSilently, webhookData) => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}/${merchantId}/webhooks`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessTokenSilently()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(webhookData),
  });

  return res.json();
};

const DEFAULT_FORM_VALUES = {
  webhook_quote_created: '',
  webhook_quote_deleted: '',
  webhook_quote_updated: '',
};

const MerchantWebhookForm = ({ merchant, refreshMerchant }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [webhooks, setWebhooks] = useState(merchant.webhooks || DEFAULT_FORM_VALUES);
  const [formErrors, setFormErrors] = useState([]);
  const [error, setError] = useState('');

  const savedWebhooks = useRef(DEFAULT_FORM_VALUES);
  const isLoaded = useRef(true);

  useEffect(() => {
    if (merchant.webhooks) {
      savedWebhooks.current = merchant.webhooks;
    }
  }, [merchant.webhooks?.webhook_quote_created, merchant.webhooks?.webhook_quote_deleted, merchant.webhooks?.webhook_quote_updated]);

  useEffect(() => {
    isLoaded.current = true;

    return () => {
      isLoaded.current = false;
    };
  }, []);

  const onUpdate = async () => {
    setLoading(true);
    setError('');

    const data = await updateWebhooks(merchant.merchant_id, getAccessTokenSilently, webhooks)
      .catch((e) => {
        console.error(e);
        setError('Oppdatering av pris data feilet');
        setLoading(false);
      });

    if (isLoaded.current && data) {
      refreshMerchant(false);
      savedWebhooks.current = data;
      setWebhooks(data);
      setLoading(false);
      setEditMode(false);
    }
  };

  const onChange = useCallback(({ target = {} }) => {
    const { value = '', name = '' } = target;
    if (name) {
      setWebhooks((prevState) => ({ ...prevState, [name]: value }));
    }

    if (value?.trim() && !isURL(value)) {
      setFormErrors((prevState) => ({ ...prevState, [name]: 'Ugyldig URL' }));
    } else {
      setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
    }
  }, []);

  const onCancel = useCallback(() => {
    setWebhooks({ ...savedWebhooks.current });
    setEditMode(false);
  }, [merchant.webhooks?.webhook_quote_created, merchant.webhooks?.webhook_quote_deleted, merchant.webhooks?.webhook_quote_updated]);

  return (
    <ProgressWithBackDrop loading={loading} fitContent>
      <S.MerchantForm>
        <p>{error}</p>
        <S.FormControl>
          {
            FORM_CONFIG.map(({ label, name, maxLength = 100 }) => (
              <S.StandardTextField
                key={`TextField-${name}`}
                error={!!formErrors[name]}
                label={label}
                placeholder={label}
                helperText={formErrors[name] || ''}
                value={webhooks[name] || (editMode ? '' : ' ')}
                type="text"
                InputProps={{ inputProps: { maxLength } }}
                onChange={onChange}
                name={name}
                disabled={!editMode}
                variant="standard"
              />
            ))
          }
        </S.FormControl>
        <PriceDifferentiationTableButtons
          editMode={editMode}
          onUpdate={onUpdate}
          onCancel={onCancel}
          onEditMode={() => setEditMode(true)}
          loading={loading}
        />
      </S.MerchantForm>
    </ProgressWithBackDrop>
  );
};

MerchantWebhookForm.defaultProps = {
  refreshMerchant: () => {},
};

MerchantWebhookForm.propTypes = {
  merchant: PropTypes.shape({
    merchant_id: PropTypes.string.isRequired,
    webhooks: PropTypes.shape({
      webhook_quote_created: PropTypes.string,
      webhook_quote_deleted: PropTypes.string,
      webhook_quote_updated: PropTypes.string,
    }),
  }).isRequired,
  refreshMerchant: PropTypes.func,
};

export default MerchantWebhookForm;
