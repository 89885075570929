import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth0 } from '@auth0/auth0-react';
import { SettingsContext } from '../../../lib/Settings';
import { productRowsSolcellekraft } from '../../../lib/productTableRows';
import * as S from './styles';

const LANGUAGE = 'no';

const MerchantPriceAdjustTable = ({ merchant, user, onSave }) => {
  const settings = useContext(SettingsContext);
  const [editMode, setEditMode] = useState(false);
  const [newPriceAdjustment, setNewPriceAdjustment] = useState();
  const [isUpdateRequestOngoing, setIsUpdateRequestOngoing] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const updatePriceAdjustment = async () => {
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify(newPriceAdjustment),
    };
    const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}/${merchant.merchant_id}/priceAdjustment`;
    const result = await fetch(url, requestOptions);
    return result.json();
  };

  if (!settings || !merchant || !user) return null;
  const isMerchantUser = user.role === 'merchant_user';

  if (!merchant.purchase_discount) {
    return null;
  }

  return (
    <S.Container>
      <S.TableHeading>
        Prisjustering rabatt / påslag
      </S.TableHeading>
      <table style={{ fontSize: '1.0rem', padding: '20px', width: '100%' }}>
        <thead>
          <tr>
            <th width={170}>Vare</th>
            {!isMerchantUser ? (
              <th width={170}>Innkjøpspris</th>
            ) : null}
            <th width={170}>Veil. pris</th>
            <th width={170} style={{ fontStyle: 'italic' }}>Rabatt (-) / påslag (+)</th>
            <th width={170}>Pris eks. mva.</th>
            <th width={170}>Pris inkl. mva.</th>
          </tr>
        </thead>
        <tbody>
          {productRowsSolcellekraft.map((row) => {
            let productPrice = settings.prices[row.price];
            if (Number.isNaN(Number.parseFloat(productPrice))) {
              if (row.price === 'solarPanels') {
                productPrice = 1000;
              } else if (row.price === 'inverters') {
                productPrice = 30000;
              } else if (row.price === 'roofingType') {
                productPrice = 1000;
              } else {
                productPrice = -1;
              }
            }
            const productPurchaseDiscountPercentage = merchant.purchase_discount[row.purchaseDiscountPercentage] ?? 0;
            const productPurchasePrice = productPrice * (1 + (productPurchaseDiscountPercentage / 100));
            const productPriceAdjustmentPercentage = merchant.price_adjustment[row.priceAdjustmentPercentage] ?? 0;
            const productAdjustedPrice = productPrice * (1 + (productPriceAdjustmentPercentage / 100));
            const productAdjustedPriceWithTax = productAdjustedPrice * 1.25;
            return (
              <React.Fragment key={row.price}>
                {row.heading ? (
                  <tr>
                    <td colSpan="5">
                      <S.TableGroupHeading>{row.heading}</S.TableGroupHeading>
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td>{row.name}</td>
                  {!isMerchantUser ? (
                    <td>
                      {new Intl.NumberFormat(LANGUAGE, { style: 'currency', currency: 'NOK' })
                        .format(productPurchasePrice)}
                    </td>
                  ) : null}
                  <td>
                    {new Intl.NumberFormat(LANGUAGE, { style: 'currency', currency: 'NOK' })
                      .format(productPrice)}
                  </td>
                  <td>
                    {editMode ? (
                      <>
                        <S.InputTextField
                          value={(newPriceAdjustment[row.priceAdjustmentPercentage] ?? 0).toString().replaceAll('.', ',')}
                          inputProps={{
                            style: { textAlign: 'center' },
                          }}
                          onFocus={(e) => e.target.select()}
                          onChange={(e) => {
                            const copy = { ...newPriceAdjustment };
                            copy[row.priceAdjustmentPercentage] = e.target.value.replaceAll(',', '.');
                            setNewPriceAdjustment(copy);
                          }}
                          onBlur={(e) => {
                            const copy = { ...newPriceAdjustment };
                            const number = parseFloat(e.target.value.replaceAll(',', '.'));
                            copy[row.priceAdjustmentPercentage] = !Number.isNaN(number)
                              ? Math.min(Math.max(number, -1000), 1000)
                              : 0;
                            setNewPriceAdjustment(copy);
                          }}
                          variant="standard"
                        />
                        {' %'}
                      </>
                    ) : (
                      `${productPriceAdjustmentPercentage.toString().replace('.', ',')} %`
                    )}
                  </td>
                  <td>
                    {new Intl.NumberFormat(LANGUAGE, { style: 'currency', currency: 'NOK' })
                      .format(productAdjustedPrice)}
                  </td>
                  <td>
                    {new Intl.NumberFormat(LANGUAGE, { style: 'currency', currency: 'NOK' })
                      .format(productAdjustedPriceWithTax)}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <S.BottomRow>
        (*) Eksempelpris - avhengig av kundes valg
        {user.role === 'merchant_admin' && (
          !editMode ? (
            <S.ButtonWrapper>
              <S.StandardButton
                onClick={() => {
                  setNewPriceAdjustment(merchant.price_adjustment);
                  setEditMode(true);
                }}
              >
                Rediger
              </S.StandardButton>
            </S.ButtonWrapper>
          ) : (
            <S.ButtonWrapper>
              <S.StandardButton
                onClick={() => {
                  setIsUpdateRequestOngoing(true);
                  updatePriceAdjustment()
                    .then(async () => {
                      await onSave();
                      setEditMode(false);
                      setIsUpdateRequestOngoing(false);
                    });
                }}
              >
                Lagre
              </S.StandardButton>
              <S.StandardButton
                onClick={() => {
                  setEditMode(false);
                }}
              >
                Avbryt
              </S.StandardButton>
            </S.ButtonWrapper>
          )
        )}
      </S.BottomRow>
      {isUpdateRequestOngoing && (
        <S.CircularProgressPanel>
          <CircularProgress />
        </S.CircularProgressPanel>
      )}
    </S.Container>
  );
};

const pricesTypes = PropTypes.shape({
  ballastSystemPerFlatRoofPercentage: PropTypes.number,
  cableCostPerPanelPercentage: PropTypes.number,
  connectorCostPerPanelPercentage: PropTypes.number,
  electricityMountingCostPerPanelPercentage: PropTypes.number,
  electricityMountingStartupCostPercentage: PropTypes.number,
  engineeringCostPerInstallationPercentage: PropTypes.number,
  engineeringCostPerPanelPercentage: PropTypes.number,
  invertersPercentage: PropTypes.number,
  mountingCostPerPanelPercentage: PropTypes.number,
  mountingKitPerPanelBallastPercentage: PropTypes.number,
  mountingKitPerPanelDecraPercentage: PropTypes.number,
  mountingKitPerPanelIntegratedSolarPanelsPercentage: PropTypes.number,
  mountingKitPerPanelRoofFeltPercentage: PropTypes.number,
  mountingKitPerPanelRoofShinglesPercentage: PropTypes.number,
  mountingKitPerPanelRoofSteelPlatesPercentage: PropTypes.number,
  mountingKitPerPanelRoofTilesPercentage: PropTypes.number,
  mountingKitPerPanelRoofTilesSteelPlatesPercentage: PropTypes.number,
  mountingKitPerPanelSlatePercentage: PropTypes.number,
  mountingStartupCostPercentage: PropTypes.number,
  solarPanelsPercentage: PropTypes.number,
  wifiModulePercentage: PropTypes.number,
});

MerchantPriceAdjustTable.propTypes = {
  merchant: PropTypes.shape({
    county: PropTypes.string,
    merchant_id: PropTypes.string,
    price_adjustment: pricesTypes,
    purchase_discount: pricesTypes,
  }),
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
};

MerchantPriceAdjustTable.defaultProps = {
  merchant: null,
  user: null,
};

export default MerchantPriceAdjustTable;
