import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import ToolBar from '@mui/material/Toolbar';

export const StyledAppBar = styled(AppBar)`
  background-color: ${(props) => {
    if (props.$menuOpen) return 'orange';
    if (props.$scrollTrigger) return props.theme.color.primaryLight;
    return 'transparent';
  }};

  height: ${(props) => {
    if (props.$menuOpen) return '20rem';
    if (props.$scrollTrigger) return '4rem';
    return '6rem';
  }};

  ${(props) => !props.$menuOpen && !props.$scrollTrigger && `
    box-shadow: none;
  `}

  transition: all 0.2s ease-out;
`;

export const StyledToolBar = styled(ToolBar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: ${(props) => {
    if (props.$menuOpen) return '6rem';
    if (props.$scrollTrigger) return '4rem';
    return '6rem';
  }};

  align-items: center;
  padding: 0 4rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    padding: 0 2rem;
  }
`;

export const LogoWrapper = styled.div`
  flex-grow: 1;
  margin-right: 1rem;
`;

export const NavLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-gap: 2rem;
  margin-right: 2rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    grid-gap: 1.2rem;
    margin-right: 1.2rem;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    display: none;
  }
`;

export const SmallScreenNavLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => (props.$menuOpen ? '2rem' : '0')};
  overflow: hidden;
  height: ${(props) => (props.$menuOpen ? '14rem' : '0')};
  transition: all 0.2s ease-out;
  transition-delay: ${(props) => (props.$menuOpen ? '0.2s' : '0')};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;

  color: ${(props) => {
    if (props.$menuOpen) return props.theme.color.primaryLight;
    if (props.$scrollTrigger) return props.theme.color.primaryDark;
    if (props.$navLinkExpandedFontColor) return props.$navLinkExpandedFontColor;
    return props.theme.color.primaryDark;
  }};

  &:hover { color: ${(props) => (props.$menuOpen
    ? props.theme.color.primaryDark
    : props.theme.color.primaryHover
  )}}

  transition: all 0.2s ease-out;
  font-size: 1.3rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    font-size: 1.1rem;
  }
`;

export const BurgerIconButton = styled.button`
  height: 2rem;
  width: 2rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  border: 0;
  background: transparent;
  display: none;
  margin-left: 1.2rem;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    display: inherit;
  }

  div {
    height: 0.35rem;
    width: 2rem;  
    border-radius: 0.175rem;

    background: ${(props) => {
    if (props.$menuOpen) return props.theme.color.primaryLight;
    if (props.$scrollTrigger) return props.theme.color.primaryDark;
    if (props.$navLinkExpandedFontColor) return props.$navLinkExpandedFontColor;
    return props.theme.color.primaryDark;
  }};
    transition: all 0.2s ease-out;
    transform-origin: 0.175rem;

    :nth-child(1) {
      transform: ${(props) => (props.$menuOpen ? 'rotate(45deg)' : 'none')};
    }

    :nth-child(2) {
      width: ${(props) => (props.$menuOpen ? '0' : '2rem')};
    }

    :nth-child(3) {
      transform: ${(props) => (props.$menuOpen ? 'rotate(-45deg)' : 'none')};
    }
  }

  :hover div {
    background: ${(props) => (props.$menuOpen
    ? props.theme.color.primaryDark
    : props.theme.color.primaryHover
  )} 
  }
`;
