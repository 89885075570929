import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const MerchantPageBody = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 100rem;
`;
