import {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import useDebouncedCallback from './useDebounce';

const getUrlParamFromQueries = (queries = {}) => {
  const keys = Object.keys(queries).filter((key) => !!queries[key]);
  return keys.length
    ? `?${keys.map((key) => `${key}=${queries[key]}`).join('&')}`
    : '';
};

/**
 * @param url
 * @param options {{ initialData?: any, initialQueries?: object, redirect?: boolean, enabled?: boolean, initialLoadingState?: boolean }}
 * @returns {{data: *[], onChangeUrlSearch: ((function(*=, *=): void)|*), urlQueries: {},
 * loading: boolean, refreshData: ((function(*=): Promise<void>)|*)}}
 */
const useFetchData = (url, options = {}) => {
  const {
    initialData = [],
    initialQueries = {},
    redirect = true,
    enabled = true,
    initialLoadingState = true,
  } = options;

  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const isLoaded = useRef(true);
  const abortControllerRef = useRef({});

  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(initialLoadingState && enabled);
  const urlQueriesRef = useRef(initialQueries);
  const lastUrlQueriesRef = useRef({});
  const [, reRender] = useState(0);

  /**
   * @param showLoading?: {boolean}
   * @param queries?: {object}
   */
  const fetchData = useCallback(async (showLoading = true, queries = {}) => {
    if (isLoaded.current && showLoading) setLoading(true);
    const urlWithQuery = `${url}${getUrlParamFromQueries({ ...urlQueriesRef.current, ...queries })}`;
    if (abortControllerRef.current.abort) abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    const response = await fetch(urlWithQuery, {
      signal: abortControllerRef.current.signal,
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    }).catch((error) => {
      if (error.name === 'AbortError') {
        return { status: 103, statusText: 'aborted by user' };
      }
      console.error(error);
      return {};
    });

    switch (response.status) {
      case 200: {
        if (isLoaded.current) setData((await response.json()) ?? initialData);
        break;
      }

      case 403:
      case 401:
        if (isLoaded.current && redirect) history.push('/forbidden');
        break;

      // request is aborted.
      case 103:
        return;

      default:
        console.warn(`${response.status}: ${response.statusText}`);
        break;
    }

    if (isLoaded.current && showLoading) {
      setLoading(false);
    }
  }, [JSON.stringify(urlQueriesRef.current), url]);

  const fetchDataWithDebounce = useDebouncedCallback(fetchData, 500);

  /**
   * @param queries {object}
   * @param options {{ showLoading?: boolean, preservePrevious?: boolean, debounce?: boolean }}
   */
  const changeUrlQuery = useCallback((queries = {}, { showLoading = true, preservePrevious = true, debounce = true } = {}) => {
    urlQueriesRef.current = { ...(preservePrevious ? urlQueriesRef.current : {}), ...queries };
    reRender((prev) => prev + 1);

    if (getUrlParamFromQueries(lastUrlQueriesRef.current) !== getUrlParamFromQueries(urlQueriesRef.current)) {
      lastUrlQueriesRef.current = urlQueriesRef.current;
      if (debounce) {
        fetchDataWithDebounce(showLoading, urlQueriesRef.current);
      } else {
        fetchData(showLoading, urlQueriesRef.current);
      }
    }
  }, [fetchDataWithDebounce]);

  useEffect(() => {
    isLoaded.current = true;
    if (JSON.stringify(urlQueriesRef.current) !== JSON.stringify(initialData)) {
      urlQueriesRef.current = initialQueries;
      reRender((prev) => prev + 1);
    }

    if (url && enabled) fetchData(true, initialQueries);

    return () => {
      isLoaded.current = false;
      if (abortControllerRef.current.abort) abortControllerRef.current.abort();
    };
  }, [url, enabled, JSON.stringify(initialQueries)]);

  return {
    loading,
    data,
    refreshData: fetchData,
    changeUrlQuery,
    urlQueries: urlQueriesRef.current,
  };
};

export default useFetchData;
