import styled from 'styled-components';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

export const RowWrapper = styled.div`
  width: 100%;
  display: grid;
  padding: 1rem;
  background: ${({ theme }) => theme.color.primaryLight};
  color: ${({ theme }) => theme.color.primaryDark};
  border-radius: 0.5rem;
  align-items: center;
  margin-left: 10px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const Name = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
`;

export const Email = styled.div`
  color: gray;
  font-size: 1rem;
`;

export const Address = styled.div`
  font-size: 1.1rem;
`;

export const MerchantName = styled.div`
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
`;

export const StandardButton = styled(Button)`
  width: 8rem;
  background-color: #f7a618;
  margin-top: 5px;
  color: white;
`;

export const StandardSelect = styled(Select)`
  width: 15rem;
`;

export const SelectCheckbox = styled(Checkbox)`
  margin-right: 1rem;
  color: ${({ theme }) => theme.color.black};
  
  &.Mui-checked {
    color: ${({ theme }) => theme.color.orange};
  }
`;
