import React from 'react';

import SolarPanelSelector from './SolarPanelSelector';
import RoofTypeSelector from './RoofTypeSelector';
import DashboardQuotePageLowerPanel from './DashboardQuotePageLowerPanel';
import DashboardQuotePageAdminViewOfMerchantQuote from './DashboardQuotePageAdminViewOfMerchantQuote';
import DashboardQuotePageDetailMerchantPanel from './DashboardQuotePageDetailMerchantPanel';
import DashboardQuotePageDetailPanel from './DashboardQuotePageDetailPanel';
import * as S from './styles';

const TabSelector = ({
  quote,
  settings,
  isMerchantQuote,
  isMerchantUser,
  activeTab,
  updateSolarPanelType,
  updateRoofingType,
  updateQuoteRoofs,
  onUpdateFreeTextValues,
}) => {
  if (activeTab === 'solarSystem') {
    const roofingTypes = settings.roofSettings.roofingTypes.map((roof) => ({
      value: roof.slug.split('-select-option-')[1],
      label: roof.string.no,
    }));

    return (
      <>
        <S.DropDownWrapper>
          <S.DropDownContainer>
            <span>Panel</span>
            <SolarPanelSelector
              quote={quote}
              availableSolarPanels={settings.solar.solarPanels.filter((panel) => panel.isActive)}
              updateSolarPanelType={updateSolarPanelType}
            />
          </S.DropDownContainer>
          <S.DropDownContainer>
            <span>Taktype</span>
            <RoofTypeSelector
              quote={quote}
              roofingTypes={roofingTypes}
              updateRoofingType={updateRoofingType}
            />
          </S.DropDownContainer>
        </S.DropDownWrapper>
        <br />
        <DashboardQuotePageLowerPanel
          quote={quote}
          roofingTypes={roofingTypes}
          updateQuoteRoofs={updateQuoteRoofs}
        />
      </>
    );
  }

  if (activeTab === 'prices') {
    return (
      <>
        {isMerchantQuote && (
          <DashboardQuotePageAdminViewOfMerchantQuote
            quote={quote}
          />
        )}
        {isMerchantUser ? (
          <DashboardQuotePageDetailMerchantPanel
            quote={quote}
            onUpdateFreeTextValues={onUpdateFreeTextValues}
          />
        ) : (
          <DashboardQuotePageDetailPanel
            quote={quote}
            onUpdateFreeTextValues={onUpdateFreeTextValues}
          />
        )}
      </>

    );
  }

  return null;
};

export default TabSelector;
