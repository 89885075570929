import React from 'react';
import * as S from './styles';
import { QuoteShape } from '../proptypes/quote';

const RoofTableDefault = ({
  quote,
  setEditRoofs,
  selectedRoofId,
  setSelectedRoofId,
}) => {
  if (!quote) return null;

  return (
    <>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <S.Thead>
          <S.LowerMapTableRow>
            <th style={{ padding: '8px 12px 10px' }}>Tak</th>
            <th style={{ padding: '8px 12px 10px' }}>kWp</th>
            <th style={{ padding: '8px 12px 10px' }}>Produksjon</th>
            <th style={{ padding: '8px 12px 10px' }}>Antall paneler</th>
          </S.LowerMapTableRow>
        </S.Thead>
        <tbody style={{ textAlign: 'right' }}>
          {
            quote.roofs.map((r, index) => (
              <S.LowerMapTableRow key={r.id} style={{ backgroundColor: selectedRoofId && selectedRoofId === r.id ? '#fedfb2' : 'transparent' }}>
                <S.LowerMapTableColumn style={{ textAlign: 'left' }}><strong>{`Tak ${index + 1}${r.manuallyAdded ? ' *' : ''}`}</strong></S.LowerMapTableColumn>
                <S.LowerMapTableColumn>{`${new Intl.NumberFormat('nb').format(Math.round(r.pvgisAnnualProductionPerKwp))} kWp`}</S.LowerMapTableColumn>
                <S.LowerMapTableColumn>{r.isSelected && `${new Intl.NumberFormat('nb').format(Math.round(r.estimatedAnnualProduction * 10) / 10)} kWh`}</S.LowerMapTableColumn>
                <S.LowerMapTableColumn>{r.isSelected ? `${r.panelCount} av ${r.panelCountMax} paneler` : `0 av ${r.panelCountMax} paneler`}</S.LowerMapTableColumn>
              </S.LowerMapTableRow>
            ))
          }
        </tbody>
      </table>
      <br />
      <S.StandardButton
        type="button"
        onClick={() => {
          setEditRoofs(true);
          setSelectedRoofId(null);
        }}
      >
        Rediger tak
      </S.StandardButton>
      <br />
      <p>{`Utnyttelsesgrad per tak: ${quote.options.roofSpaceThreshold ? quote.options.roofSpaceThreshold : '75'}%`}</p>
      {quote.roofs.filter((r) => r.manuallyAdded).length > 0 && (
        <p><em>Tak merket med stjerne (*) er lagt til manuelt</em></p>
      )}
    </>
  );
};

export default RoofTableDefault;

RoofTableDefault.defaultProps = {
  quote: undefined,
};

RoofTableDefault.propTypes = {
  quote: QuoteShape,
};
