import styled from 'styled-components';
import { Select as SelectBase } from '@mui/material';

export const Select = styled(SelectBase)`
  font-family: 'BrandonText', sans-serif;
  font-size: ${({ theme }) => theme.fontSize.large.md};
  font-weight: 700;
  min-width: 6rem;

  color: ${(props) => {
    if (props.$menuOpen) return props.theme.color.primaryLight;
    if (props.$scrollTrigger) return props.theme.color.primaryDark;
    if (props.$navLinkExpandedFontColor) return props.$navLinkExpandedFontColor;
    return props.theme.color.primaryDark;
  }};

  &:not(.Mui-disabled):hover::before, ::before, ::after {
    border-color: ${(props) => {
    if (props.$menuOpen) return props.theme.color.primaryLight;
    if (props.$scrollTrigger) return props.theme.color.primaryDark;
    if (props.$navLinkExpandedFontColor) return props.$navLinkExpandedFontColor;
    return props.theme.color.primaryDark;
  }};
  }

  .MuiSelect-icon {
    color: ${(props) => {
    if (props.$menuOpen) return props.theme.color.primaryLight;
    if (props.$scrollTrigger) return props.theme.color.primaryDark;
    if (props.$navLinkExpandedFontColor) return props.$navLinkExpandedFontColor;
    return props.theme.color.primaryDark;
  }};
  }

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    font-size: 1.1rem;
  }
`;
