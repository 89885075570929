import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import UserListItem from '../users/UserListItem';

const UserList = ({
  userList,
  refreshUserList,
  merchantList,
  userInfo,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const updateUser = async (updatedUser) => {
    const body = {
      firstname: updatedUser.firstName,
      lastname: updatedUser.lastName,
      role: updatedUser.role,
      password: updatedUser.password || undefined,
      merchantId: updatedUser.merchantId || undefined,
    };
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify(body),
    };

    const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_USERS_ENDPOINT}/${updatedUser.id}`;
    const result = await fetch(url, requestOptions);
    await result.json();
    await refreshUserList(false);
  };

  const deleteUser = async (userId) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    };
    const deleteUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_USERS_ENDPOINT}/${userId}`;
    const result = await fetch(deleteUrl, requestOptions);
    const { status } = await result;
    if (status < 200 || status > 299) {
      console.log('Feil under sletting');
    } else {
      await refreshUserList(false);
    }
  };

  return userList?.length
    ? (
      <table style={{ width: '100%' }}>
        <tbody>
          {
            userList.map((u) => (
              <tr key={u.user_id}>
                <td>
                  <UserListItem
                    user={u}
                    updateFunc={updateUser}
                    deleteFunc={deleteUser}
                    merchantList={merchantList}
                    refreshUserList={refreshUserList}
                    userInfo={userInfo}
                  />
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    )
    : <div>Ingen brukere i listen</div>;
};

UserList.defaultProps = {
  userList: [],
  refreshUserList: () => {},
  merchantList: [],
  userInfo: {},
};

UserList.propTypes = {
  userList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  refreshUserList: PropTypes.func,
  merchantList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  userInfo: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
    role: PropTypes.string.isRequired,
    merchantId: PropTypes.string,
  }),
};

export default UserList;
