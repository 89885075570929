import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import EditIcon from '@mui/icons-material/Edit';
import ProgressWithBackDrop from '../../progressWithBackDrop/ProgressWithBackDrop';
import UserForm from '../userform/UserForm';
import * as S from './styles';

const UserListItem = ({
  user,
  updateFunc,
  deleteFunc,
  merchantList,
  userInfo,
  refreshUserList,
}) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const resolveMerchantId = () => {
    if (!user.user_metadata || !user.user_metadata.merchant_id) return process.env.REACT_APP_COMPANY_NAME;
    const match = merchantList?.find((m) => m.merchant_id === user.user_metadata.merchant_id);
    return match?.name || '';
  };

  const onSubmit = async (updatedUser) => {
    setLoading(true);
    await updateFunc(updatedUser);
    await refreshUserList(false);
    setLoading(false);
    setShow(false);
  };

  const onDeleteUser = async () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('Slette bruker?')) return;
    setLoading(true);
    await deleteFunc(user.user_id);
    await refreshUserList(false);
  };

  return (
    <div>
      <S.UserOuterWrapper>
        <S.UserWrapper>
          <S.Column>
            <S.Role>
              { user.user_metadata ? user.user_metadata.role : '' }
            </S.Role>
            <S.Name>
              {user.name}
            </S.Name>
          </S.Column>
          <S.Column>
            { resolveMerchantId() }
          </S.Column>
          <S.EditColumn>
            <IconButton onClick={() => setShow(true)} size="large">
              <EditIcon />
            </IconButton>
          </S.EditColumn>
        </S.UserWrapper>
        <Collapse in={show} timeout="auto" unmountOnExit>
          <ProgressWithBackDrop loading={loading} fitContent>
            <S.UserFormContainer>
              <UserForm
                onSubmit={onSubmit}
                onCancel={() => setShow(false)}
                onDelete={onDeleteUser}
                userInfo={userInfo}
                merchants={merchantList}
                user={user}
              />
            </S.UserFormContainer>
          </ProgressWithBackDrop>
        </Collapse>
      </S.UserOuterWrapper>
    </div>
  );
};

UserListItem.defaultProps = {
  merchantList: [],
  updateFunc: () => {},
  deleteFunc: () => {},
  userInfo: {},
  refreshUserList: () => {},
};

const pricesTypes = PropTypes.shape({
  ballastSystemPerFlatRoofPercentage: PropTypes.number,
  cableCostPerPanelPercentage: PropTypes.number,
  connectorCostPerPanelPercentage: PropTypes.number,
  electricityMountingCostPerPanelPercentage: PropTypes.number,
  electricityMountingStartupCostPercentage: PropTypes.number,
  engineeringCostPerInstallationPercentage: PropTypes.number,
  engineeringCostPerPanelPercentage: PropTypes.number,
  invertersPercentage: PropTypes.number,
  mountingCostPerPanelPercentage: PropTypes.number,
  mountingKitPerPanelBallastPercentage: PropTypes.number,
  mountingKitPerPanelDecraPercentage: PropTypes.number,
  mountingKitPerPanelIntegratedSolarPanelsPercentage: PropTypes.number,
  mountingKitPerPanelRoofFeltPercentage: PropTypes.number,
  mountingKitPerPanelRoofShinglesPercentage: PropTypes.number,
  mountingKitPerPanelRoofSteelPlatesPercentage: PropTypes.number,
  mountingKitPerPanelRoofTilesPercentage: PropTypes.number,
  mountingKitPerPanelRoofTilesSteelPlatesPercentage: PropTypes.number,
  mountingKitPerPanelSlatePercentage: PropTypes.number,
  mountingStartupCostPercentage: PropTypes.number,
  solarPanelsPercentage: PropTypes.number,
  wifiModulePercentage: PropTypes.number,
});

UserListItem.propTypes = {
  merchantList: PropTypes.arrayOf(PropTypes.shape({
    address_line1: PropTypes.string,
    address_line2: PropTypes.string,
    city: PropTypes.string,
    country_code: PropTypes.string,
    county: PropTypes.string,
    created: PropTypes.string,
    email: PropTypes.string,
    merchant_id: PropTypes.string,
    name: PropTypes.string,
    notification_email: PropTypes.string,
    phone: PropTypes.string,
    postal_code: PropTypes.string,
    price_adjustment: pricesTypes,
    purchase_discount: pricesTypes,
  })),
  user: PropTypes.shape({
    email_verified: PropTypes.bool,
    email: PropTypes.string,
    family_name: PropTypes.string,
    given_name: PropTypes.string,
    name: PropTypes.string,
    nickname: PropTypes.string,
    picture: PropTypes.string,
    updated_at: PropTypes.string,
    user_id: PropTypes.string,
    user_metadata: PropTypes.shape({
      role: PropTypes.string,
      merchant_id: PropTypes.string,
    }),
  }).isRequired,
  updateFunc: PropTypes.func,
  deleteFunc: PropTypes.func,
  userInfo: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
    isAdmin: PropTypes.bool,
  }),
  refreshUserList: PropTypes.func,
};

export default UserListItem;
