import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import ProgressWithBackDrop from '../../progressWithBackDrop/ProgressWithBackDrop';
import PriceDifferentiationTableButtons from './PriceDifferentiationTableButtons';
import * as S from './styles';

const PRICE_TYPES = process.env.REACT_APP_SITE === 'TAKBYTTE'
  ? [
    { name: 'percentageEquipment', title: 'Utstyr' },
    { name: 'percentageRoofInstallation', title: 'Montering arbeid' },
  ]
  : [
    { name: 'percentageEquipment', title: 'Utstyr' },
    { name: 'percentageElectricInstallation', title: 'Elektro arbeid' },
    { name: 'percentageRoofInstallation', title: 'Montering arbeid' },
  ];

const updatePrices = async (merchantId, getAccessTokenSilently, priceData) => {
  const validatedData = priceData.map((county) => ({
    ...county,
    percentageEquipment: county.percentageEquipment || 0,
    percentageRoofInstallation: county.percentageRoofInstallation || 0,
    ...(
      process.env.REACT_APP_SITE === 'TAKBYTTE'
        ? {}
        : { percentageElectricInstallation: county.percentageElectricInstallation || 0 }
    ),
  }));

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}/${merchantId}/countyPriceDifferent`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${await getAccessTokenSilently()}`,
      'Content-Type': 'application/json',
    },

    body: JSON.stringify(validatedData),
  });

  return response.json();
};

const PriceDifferentiationTable = ({ merchantId, countyPriceDifferent, canEdit, hideHeader }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [priceData, setPriceData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const savedPrices = useRef([]);
  const isLoaded = useRef(true);

  useEffect(() => {
    if (countyPriceDifferent.length) {
      setPriceData([...countyPriceDifferent]);
      savedPrices.current = [...countyPriceDifferent];
    }
  }, [countyPriceDifferent.length]);

  useEffect(() => () => {
    isLoaded.current = false;
  }, []);

  const onUpdate = async () => {
    setLoading(true);
    setError('');

    const data = await updatePrices(merchantId, getAccessTokenSilently, priceData)
      .catch((e) => {
        console.error(e);
        setError('Oppdatering av pris data feilet');
        setLoading(false);
      });

    if (isLoaded.current) {
      savedPrices.current = data;
      setPriceData(data);
      setLoading(false);
      setEditMode(false);
    }
  };

  const onChange = useCallback(({ target }) => {
    const index = parseInt(target.dataset.index, 10);
    const value = parseInt(`${target.value}`, 10);

    if (Number.isNaN(index)) return;

    setPriceData((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], [target.name]: Number.isNaN(value) ? '' : value };
      return newState;
    });
  }, []);

  const onCancel = useCallback(() => {
    setPriceData([...savedPrices.current]);
    setEditMode(false);
  }, [countyPriceDifferent.length]);

  return (
    <S.Container>
      {
        hideHeader
          ? null
          : <S.Header>Prisdifferensiering på fylke</S.Header>
      }
      <ProgressWithBackDrop loading={loading} fitContent>
        <p>{error}</p>
        <S.Table>
          <thead>
            <tr>
              <th aria-label="Empty area" />
              {PRICE_TYPES.map(({ name = '', title = '' }) => (
                <th key={name} style={{ textAlign: 'center' }}>
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(priceData || []).map((county, index) => (
              <tr key={county.countyNumber}>
                <S.Td
                  style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    borderBottom: '1px solid #f1f1f1',
                  }}
                >
                  {county.name}
                </S.Td>
                {PRICE_TYPES.map(({ name = '' }) => (
                  <S.Td
                    key={`${county.name}-${county.countyNumber}-${name}`}
                    style={{
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      borderBottom: '1px solid #f1f1f1',
                    }}
                  >
                    {
                      editMode
                        ? (
                          <Input
                            type="number"
                            name={name}
                            value={county[name]}
                            inputProps={{
                              style: { textAlign: 'center' },
                              'data-index': index,
                            }}
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            onChange={onChange}
                          />
                        )
                        : `${county[name]} %`
                    }
                  </S.Td>
                ))}
              </tr>
            ))}
          </tbody>
        </S.Table>
      </ProgressWithBackDrop>
      {
        canEdit
          ? (
            <PriceDifferentiationTableButtons
              editMode={editMode}
              onUpdate={onUpdate}
              onCancel={onCancel}
              onEditMode={() => setEditMode(true)}
              loading={loading}
            />
          ) : null
      }
    </S.Container>
  );
};

PriceDifferentiationTable.defaultProps = {
  canEdit: false,
  hideHeader: false,
  countyPriceDifferent: [],
};

PriceDifferentiationTable.propTypes = {
  merchantId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  hideHeader: PropTypes.bool,
  countyPriceDifferent: PropTypes.arrayOf(PropTypes.shape({
    countyNumber: PropTypes.number,
    name: PropTypes.string,
    percentageEquipment: PropTypes.number,
    percentageRoofInstallation: PropTypes.number,
  })),
};

export default PriceDifferentiationTable;
