import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LanguageContext } from '../../../lib/Language';
import FreeTextField from './FreeTextField';
import * as S from './styles';

/**
 * @param amount {number}
 * @param language {string}
 * @returns {string}
 */
const toFormattedAmount = (amount = 0, language = 'no') => (
  `${new Intl.NumberFormat(language, {
    style: 'currency',
    currency: 'NOK',
    maximumFractionDigits: 1,
  }).format(Math.round(amount)).slice(0, -1)}-`
);

export const getTotalPrices = (isBusiness, cost) => {
  // needed to support legacy calculation, not including projectPrice.
  const isLegacyCalc = cost.projectPrice == null;

  return isLegacyCalc
    ? {
      total: (isBusiness ? cost.total : cost.totalIncludingVatAndEnovaFunding) || 0,
      projectPriceIncludingVat: cost.totalIncludingVat,
      projectPrice: cost.total,
      isLegacyCalc,
    }
    : {
      total: cost.total,
      projectPriceIncludingVat: cost.projectPriceIncludingVat,
      projectPrice: cost.projectPrice,
      isLegacyCalc,
    };
};

const TopListRow = ({ title, value, show, bold }) => (
  show
    ? (
      <S.TopListRow bold={bold}>
        <span>{title}</span>
        <span>{value}</span>
      </S.TopListRow>
    ) : (
      <S.TopListRow>&nbsp;</S.TopListRow>
    )
);

TopListRow.defaultProps = {
  title: '',
  value: '',
  show: true,
  bold: false,
};

TopListRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  show: PropTypes.bool,
  bold: PropTypes.bool,
};

const DashboardQuotePageDetailPanel = ({ quote, onUpdateFreeTextValues }) => {
  if (!quote) return null;
  const { calculations, options, business_type: businessType } = quote;
  const { freeTextValues, solarPanel, inverters } = options || {};
  const { cost } = calculations;

  const isBusiness = businessType === 'business';

  const taxStyle = { visibility: isBusiness ? 'collapse' : 'visible' };
  const { total, projectPriceIncludingVat, projectPrice } = getTotalPrices(isBusiness, cost);

  return (
    <S.MiddlePanel>
      <S.MiddlePanelHeader><b>Detaljert prisoversikt</b></S.MiddlePanelHeader>
      <S.MiddlePanelMain>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Utstyr</div>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>Per stk.</S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>Antall</S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>Eks. mva.</S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>Inkl. mva.</S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow style={{ paddingTop: '5px' }}>
          <div style={{ flex: '1' }}>{`Solcellepaneler (${solarPanel.productName})`}</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.solarPanels.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.equipment.solarPanels.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.solarPanels.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.equipment.solarPanels.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        {cost.equipment.inverters.map((_inverter, _index) => (
          <S.CalcDetailTableRow key={inverters[_index].type._key}>
            <div style={{ flex: '1' }}>{`Invertere (${inverters[_index].type.power} kW)`}</div>
            <S.CalcDetailColumn>
              {toFormattedAmount(_inverter.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {_inverter.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(_inverter.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(_inverter.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>
        ))}
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>Kabler</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.cables.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.equipment.cables.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.cables.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.equipment.cables.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>Koblinger</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.connectors.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.equipment.connectors.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.connectors.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.equipment.connectors.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>Monteringssett</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.mountingKits.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.equipment.mountingKits.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.mountingKits.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.equipment.mountingKits.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>Wifi-enhet</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.wifiModule.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.equipment.wifiModule.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.wifiModule.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.equipment.wifiModule.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>Prosjektering fastpris</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.engineeringCostPerInstallation.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.equipment.engineeringCostPerInstallation.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.engineeringCostPerInstallation.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.equipment.engineeringCostPerInstallation.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>Prosjektering per panel</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.engineeringCostPerPanel.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.equipment.engineeringCostPerPanel.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.engineeringCostPerPanel.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.equipment.engineeringCostPerPanel.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>Ballast</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.ballastSystems.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.equipment.ballastSystems.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.ballastSystems.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.equipment.ballastSystems.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>&nbsp;</div>
          <S.CalcDetailColumn />
          <S.CalcDetailColumn />
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
            {toFormattedAmount(cost.equipment.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
            {toFormattedAmount(cost.equipment.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>

        <S.CalcDetailTableRow style={{ marginTop: '41px' }}>
          <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Takmontering</div>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>Oppstart</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.installation.startupCost.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.installation.startupCost.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.installation.startupCost.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.installation.startupCost.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow style={{ paddingTop: '5px' }}>
          <div style={{ flex: '1' }}>Per panel</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.installation.costPerPanel.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.installation.costPerPanel.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.installation.costPerPanel.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.installation.costPerPanel.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>&nbsp;</div>
          <S.CalcDetailColumn />
          <S.CalcDetailColumn />
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
            {toFormattedAmount(cost.installation.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
            {toFormattedAmount(cost.installation.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>

        <S.CalcDetailTableRow style={{ marginTop: '41px' }}>
          <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Elektrisk montering</div>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>Oppstart</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.electrical.startupCost.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.electrical.startupCost.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.electrical.startupCost.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.electrical.startupCost.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow style={{ paddingTop: '5px' }}>
          <div style={{ flex: '1' }}>Per panel</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.electrical.costPerPanel.netPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {cost.electrical.costPerPanel.quantity}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.electrical.costPerPanel.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={taxStyle}>
            {toFormattedAmount(cost.electrical.costPerPanel.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1' }}>&nbsp;</div>
          <S.CalcDetailColumn />
          <S.CalcDetailColumn />
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
            {toFormattedAmount(cost.electrical.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
            {toFormattedAmount(cost.electrical.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>

        <S.CalcDetailTableRow style={{ marginTop: '41px' }}>
          <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Tillegg</div>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <FreeTextField index={0} freeTextValues={freeTextValues} onSubmit={onUpdateFreeTextValues} isBusiness={isBusiness} />
        <FreeTextField index={1} freeTextValues={freeTextValues} onSubmit={onUpdateFreeTextValues} isBusiness={isBusiness} />
        <FreeTextField index={2} freeTextValues={freeTextValues} onSubmit={onUpdateFreeTextValues} isBusiness={isBusiness} />
        <S.CalcDetailTableRow style={{ justifyContent: 'end' }}>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
            {toFormattedAmount(cost.sumAddition)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
            {toFormattedAmount(cost.sumAdditionIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>

        <S.CalcDetailTableRow style={{ marginTop: '41px' }}>
          <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Prosjektpris</div>
          <S.CalcDetailColumn />
          <S.CalcDetailColumn />
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
            {toFormattedAmount(projectPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
            {toFormattedAmount(projectPriceIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>

        <S.CalcDetailTableRow style={{ marginTop: '41px' }}>
          <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Fratrekk</div>
        </S.CalcDetailTableRow>
        <FreeTextField index={3} freeTextValues={freeTextValues} onSubmit={onUpdateFreeTextValues} isBusiness={isBusiness} />
        <S.CalcDetailTableRow style={{ justifyContent: 'end' }}>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
            {`- ${toFormattedAmount(cost.sumDeduction)}`}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
            {`- ${toFormattedAmount(cost.sumDeductionIncludingVat)}`}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>

        <S.CalcDetailTableRow style={{ marginTop: '41px', visibility: isBusiness ? 'collapse' : 'visible' }}>
          <div style={{ flex: '1' }}>Enova-støtte</div>
          <S.CalcDetailColumn>&nbsp;</S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {`- ${toFormattedAmount(cost.enovaFunding)}`}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
        <S.CalcDetailTableRow style={{ borderBottom: '0' }}>
          <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Total pris</div>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
            {toFormattedAmount(total)}
          </S.CalcDetailColumn>
          {isBusiness ? <S.CalcDetailColumn /> : null}
        </S.CalcDetailTableRow>
      </S.MiddlePanelMain>
    </S.MiddlePanel>
  );
};

DashboardQuotePageDetailPanel.defaultProps = {
  quote: undefined,
  onUpdateFreeTextValues: () => { },
};

const prices = {
  netPrice: PropTypes.number,
  quantity: PropTypes.number,
  total: PropTypes.number,
  totalIncludingVat: PropTypes.number,
  totalMerchantPurchasePrice: PropTypes.number,
};

DashboardQuotePageDetailPanel.propTypes = {
  onUpdateFreeTextValues: PropTypes.func,
  quote: PropTypes.shape({
    county_number: PropTypes.number,
    roofs: PropTypes.arrayOf(PropTypes.shape({})),
    merchant_id: PropTypes.string,
    business_type: PropTypes.string,
    uuid: PropTypes.string,
    options: PropTypes.shape({
      inverters: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.shape({
          power: PropTypes.number,
          _key: PropTypes.string,
        }),
      })),
      freeTextValues: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.number,
        isDiscount: PropTypes.bool,
      })),
      solarPanel: PropTypes.shape({
        productName: PropTypes.string,
      }),
      roofing: PropTypes.string,
      isQuoteOrdered: PropTypes.bool,
      paymentMethod: PropTypes.string,
    }),
    calculations: PropTypes.shape({
      production: PropTypes.shape({
        panelCount: PropTypes.number,
        roofCount: PropTypes.number,
        roofArea: PropTypes.number,
        peakPower: PropTypes.number,
      }),
      cost: PropTypes.shape({
        sumAddition: PropTypes.number,
        sumAdditionIncludingVat: PropTypes.number,
        sumDeduction: PropTypes.number,
        sumDeductionIncludingVat: PropTypes.number,
        totalMerchantPurchasePrice: PropTypes.number,
        totalIncludingVatAndEnovaFunding: PropTypes.number,
        totalIncludingVat: PropTypes.number,
        enovaFunding: PropTypes.number,
        total: PropTypes.number,
        electrical: PropTypes.shape({
          total: PropTypes.number,
          totalIncludingVat: PropTypes.number,
          totalMerchantPurchasePrice: PropTypes.number,
          costPerPanel: PropTypes.shape(prices),
          startupCost: PropTypes.shape(prices),
        }),
        installation: PropTypes.shape({
          total: PropTypes.number,
          totalIncludingVat: PropTypes.number,
          totalMerchantPurchasePrice: PropTypes.number,
          costPerPanel: PropTypes.shape(prices),
          startupCost: PropTypes.shape(prices),
        }),
        equipment: PropTypes.shape({
          total: PropTypes.number,
          totalIncludingVat: PropTypes.number,
          totalMerchantPurchasePrice: PropTypes.number,
          ballastSystems: PropTypes.shape(prices),
          engineeringCostPerPanel: PropTypes.shape(prices),
          engineeringCostPerInstallation: PropTypes.shape(prices),
          wifiModule: PropTypes.shape(prices),
          mountingKits: PropTypes.shape(prices),
          connectors: PropTypes.shape(prices),
          cables: PropTypes.shape(prices),
          solarPanels: PropTypes.shape(prices),
          inverters: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
  }),
};

export default DashboardQuotePageDetailPanel;
