import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const languageOptions = {
  en: 'English',
  no: 'Norsk',
};

export const LanguageContext = createContext({
  language: 'no',
});

export const LanguageProvider = ({ children }) => {
  const defaultLanguage = window.localStorage.getItem('solcellekraft-language');
  const [language, setLanguage] = useState(defaultLanguage || 'no');

  const provider = useMemo(() => ({
    language,
    languageChangeHandler: (selected) => {
      const newLanguage = languageOptions[selected] ? selected : 'no';
      setLanguage(newLanguage);
      window.localStorage.setItem('solcellekraft-language', newLanguage);
    },
  }), [language, setLanguage]);

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
