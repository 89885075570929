import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SettingsContext } from '../../lib/Settings';
import { isValidSaleStatus, saleStatusEnum, saleStatuses } from '../../lib/saleStatusMapping';
import * as S from './styles';

const CustomerListItem = ({
  customer,
  isCustomerSelectedArray,
  setIsCustomerSelectedArray,
  index,
  merchantList,
  isAdmin,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [saleStatus, setSaleStatus] = useState(customer.sale_status || saleStatusEnum.NOT_ORDERED);
  const [disableSaleStatus, setDisableSaleStatus] = useState(false);
  const settings = useContext(SettingsContext);

  const onChangeSaleStatus = useCallback(async ({ target }) => {
    const { value } = target || {};
    if (!isValidSaleStatus(value)) return;
    let saleStatusState = '';
    setSaleStatus((prevState) => {
      saleStatusState = prevState;
      return value;
    });
    setDisableSaleStatus(true);

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_CUSTOMERS_ENDPOINT}/changesalestatus`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      method: 'PATCH',
      body: JSON.stringify([{ uuid: customer.uuid, saleStatus: value }]),
    });

    if (response.status > 199 && response.status < 299) {
      const resArray = (await response.json()) || [];
      const { sale_status: status, operationStatus } = resArray
        .find(({ uuid }) => uuid === customer.uuid) || {};

      if (operationStatus === 'fulfilled' && isValidSaleStatus(status)) {
        saleStatusState = status;
      }
    }

    setSaleStatus(saleStatusState);
    setDisableSaleStatus(false);
  }, [customer.uuid, getAccessTokenSilently]);

  return (
    <div>
      <S.RowWrapper style={{ gridTemplateColumns: isAdmin ? '3rem 1fr 1fr 0.5fr 8rem' : '1fr 1fr 8rem' }}>
        { isAdmin
          && (
            <S.Column>
              <S.SelectCheckbox
                checked={isCustomerSelectedArray[index]}
                onClick={(event) => {
                  event.preventDefault();
                  const copy = [...isCustomerSelectedArray];
                  copy[index] = !copy[index];
                  setIsCustomerSelectedArray(copy);
                }}
              />
            </S.Column>
          )}
        <S.Column>
          <S.Name>
            {customer.name}
          </S.Name>
          <S.Email>
            {customer.email}
          </S.Email>
          <S.Email>
            {customer.telephone}
          </S.Email>
        </S.Column>
        <S.Column>
          <S.Address>
            {customer.address}
          </S.Address>
          <S.Address>
            {`${customer.postal_code} ${customer.city}`}
          </S.Address>
        </S.Column>
        {merchantList && (
          <S.Column>
            <S.MerchantName>
              {`${customer.merchant_id ? merchantList.find((merchant) => merchant.merchant_id === customer.merchant_id)?.name : process.env.REACT_APP_COMPANY_NAME}`}
            </S.MerchantName>
          </S.Column>
        )}
        <S.Column>
          <S.MerchantName>
            <Select
              onChange={onChangeSaleStatus}
              style={{ width: '100%' }}
              value={saleStatus}
              disabled={disableSaleStatus}
              variant="standard"
            >
              {
                saleStatuses.map((value) => (
                  <MenuItem key={value} value={value}>{settings.saleStatus[value]}</MenuItem>
                ))
              }
            </Select>
          </S.MerchantName>
        </S.Column>
      </S.RowWrapper>
    </div>
  );
};

CustomerListItem.defaultProps = {
  merchantList: undefined,
};

CustomerListItem.propTypes = {
  customer: PropTypes.objectOf(PropTypes.string).isRequired,
  isCustomerSelectedArray: PropTypes.arrayOf(PropTypes.bool).isRequired,
  index: PropTypes.number.isRequired,
  setIsCustomerSelectedArray: PropTypes.func.isRequired,
  merchantList: PropTypes.arrayOf(PropTypes.shape({
    merchant_id: PropTypes.string,
    name: PropTypes.string,
  })),
  isAdmin: PropTypes.bool.isRequired,
};

export default CustomerListItem;
