import React, { useState } from 'react';

import * as S from './styles';

const TabButtons = ({
  activeTab,
  setActiveTab,
}) => (
  <S.TabWrapper>
    <S.TabButton
      disabled={activeTab === 'solarSystem'}
      variant="contained"
      onClick={() => {
        setActiveTab('solarSystem');
      }}
    >
      Anlegget
    </S.TabButton>
    <S.TabButton
      disabled={activeTab === 'prices'}
      variant="contained"
      onClick={() => {
        setActiveTab('prices');
      }}
    >
      Priser
    </S.TabButton>
  </S.TabWrapper>
);

export default TabButtons;
