import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import CachedIcon from '@mui/icons-material/Cached';
import DoneIcon from '@mui/icons-material/Done';
import useDebounce from '../../../hooks/useDebounce';
import * as S from './styles';

const VAT_PERCENTAGE = 25;

export const FREE_TEXT_INITAL_ARRAY = [
  { key: '', value: 0, isDiscount: false, id: 'freeTextValue-1' },
  { key: '', value: 0, isDiscount: false, id: 'freeTextValue-2' },
  { key: '', value: 0, isDiscount: false, id: 'freeTextValue-3' },
  { key: '', value: 0, isDiscount: true, id: 'freeTextValue-4' },
];

const currencyName = new Intl.NumberFormat('no', {
  style: 'currency',
  currency: 'NOK',
  maximumFractionDigits: 1,
}).format(0).split(' ')[0];

const parseFloat = (value = '', noString = false) => {
  if (!noString) {
    if (value.at(-1) === '.' && value.split('.').length < 3) return value;
    if (value.at(-1) === ',' && value.split('.').length < 2) return `${value.slice(0, -1)}.`;
  }

  const number = Number.parseFloat(`${value}`.replace('-', ''));
  return Number.isNaN(number) ? undefined : number;
};

const calculateVat = (value = 0) => Math.round(parseFloat(value, true) * (1 + VAT_PERCENTAGE / 100) || 0);

const FreeTextField = ({ onSubmit, index, freeTextValues, isBusiness }) => {
  const [loading, setLoading] = useState({ value: false, key: false });
  const [data, setData] = useState({ ...FREE_TEXT_INITAL_ARRAY[index], ...freeTextValues[index] });
  const [showIcons, setShowIcons] = useState({ value: false, key: false });
  const hasKeyAndValue = !!data.key || !!data.value;

  const changeLoadingState = useCallback((name = '', state = false) => {
    setShowIcons((prev) => ({ ...prev, [name]: true }));
    setLoading((prev) => ({ ...prev, [name]: state }));
    if (!state) {
      setTimeout(() => setShowIcons((prev) => ({ ...prev, [name]: false })), 3000);
    }
  }, []);

  const debouncedSubmit = useDebounce(useCallback((newData, updateIndex, name) => {
    onSubmit(newData.value === '' ? { ...newData, value: 0 } : newData, updateIndex).then(() => {
      changeLoadingState(name, false);
    });
  }, [onSubmit]));

  const onChange = useCallback(({ target }) => {
    const { name, value } = target;
    if (!name) return;

    changeLoadingState(name, true);
    setData((prev) => {
      const newData = { ...prev, [name]: (name === 'value' ? (parseFloat(value, true) ?? 0) : (value || '')) };

      debouncedSubmit(newData, index, name);
      return { ...prev, [name]: (name === 'value' ? (parseFloat(value) ?? '') : (value || '')) };
    });
  }, [debouncedSubmit]);

  const valueWithVat = calculateVat(data?.value);

  return (
    <S.FreeTextFieldContainer hasKeyAndValue={hasKeyAndValue}>
      <Input
        placeholder={data.isDiscount ? 'Fritekst fratrekk' : 'Fritekst Tillegg'}
        onChange={onChange}
        name="key"
        value={data.key ?? ''}
        inputProps={{ maxLength: 50 }}
        endAdornment={showIcons.key
          ? (
            <InputAdornment position="end">
              { loading.key ? <CachedIcon className="rotate" /> : <DoneIcon /> }
            </InputAdornment>
          ) : null}
      />
      <div>
        <span style={{ width: data.isDiscount ? '2.5rem' : '2rem' }}>{data.isDiscount ? `- ${currencyName}` : currencyName}</span>
        <Input
          placeholder={data.isDiscount ? 'Fratrekk verdi' : 'Fritekst verdi'}
          onChange={onChange}
          name="value"
          value={data.value ?? ''}
          inputProps={{ maxLength: 9 }}
          endAdornment={showIcons.value
            ? (
              <InputAdornment position="end">
                { loading.value ? <CachedIcon className="rotate" /> : <DoneIcon /> }
              </InputAdornment>
            ) : null}
        />
        { isBusiness ? <div /> : <div>{`${currencyName} ${valueWithVat}`}</div> }
      </div>
    </S.FreeTextFieldContainer>
  );
};

FreeTextField.defaultProps = {
  onSubmit: () => {},
  freeTextValues: [],
  isBusiness: false,
};

FreeTextField.propTypes = {
  onSubmit: PropTypes.func,
  index: PropTypes.number.isRequired,
  isBusiness: PropTypes.bool,
  freeTextValues: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    key: PropTypes.string,
    isDiscount: PropTypes.bool,
  })),
};

export default FreeTextField;
