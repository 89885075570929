import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as pages from './pages';
import Theme from './lib/Theme';
import { LanguageProvider } from './lib/Language';
import { SettingsProvider } from './lib/Settings';
import { SanityProvider } from './lib/sanityClient';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import ProtectedRoute from './auth/ProtectedRoute';

const App = () => (
  <HelmetProvider>
    <LanguageProvider>
      <SettingsProvider>
        <SanityProvider>
          <Theme>
            <BrowserRouter>
              <Auth0ProviderWithHistory>
                <Switch>
                  <Route path="/forbidden" component={pages.ForbiddenPage} />
                  <ProtectedRoute path="/" exact component={pages.DashboardPage} />
                  <ProtectedRoute path="/quotes" component={pages.DashboardQuotesPage} />
                  <ProtectedRoute path="/quote/:uuid" component={pages.DashboardQuotePage} />
                  <ProtectedRoute path="/merchants" component={pages.MerchantPage} />
                  <ProtectedRoute path="/users" component={pages.UserPage} />
                  <ProtectedRoute path="/customers" component={pages.CustomerPage} />
                  <Route component={pages.NotFoundPage} />
                </Switch>
              </Auth0ProviderWithHistory>
            </BrowserRouter>
          </Theme>
        </SanityProvider>
      </SettingsProvider>
    </LanguageProvider>
  </HelmetProvider>
);

export default App;
