import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import Body from './Body';
import Footer from './footer/Footer';
import NavBar from './navbar/NavBar';
import { LanguageContext } from '../lib/Language';
import { SettingsContext } from '../lib/Settings';

const Page = ({ children, title, navLinkExpandedFontColor }) => {
  const { language } = useContext(LanguageContext);
  const settings = useContext(SettingsContext);
  if (!settings) return null;
  return (
    <>
      <Helmet>
        <title>{`${title} | ${settings.general.title[language]}`}</title>
      </Helmet>
      <Body>
        {children}
      </Body>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  navLinkExpandedFontColor: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Page.defaultProps = {
  navLinkExpandedFontColor: undefined,
};

export default Page;
