import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { ButtonContainer, StandardButton, WarningButton } from './styles';

const DELETE_NOT_ALLOWED_TEXT = 'Det er umulig å slette forhandler som har beregninger tilknyttet. Overfør kundene til en annen forhandler';

const MerchantFormButtons = ({
  editMode,
  hasAssignCustomer,
  isNew,
  onSave,
  onCancel,
  onDelete,
  setEditMode,
  isSolcellekraft,
}) => (
  editMode
    ? (
      <ButtonContainer>
        <StandardButton variant="outlined" onClick={onSave}>{isNew ? 'Lag ny' : 'Lagre'}</StandardButton>
        <StandardButton variant="outlined" onClick={onCancel}>Avbryt</StandardButton>
        {
          !isNew && onDelete && !isSolcellekraft && (
            <Tooltip title={hasAssignCustomer ? DELETE_NOT_ALLOWED_TEXT : ''}>
              <span>
                <WarningButton variant="outlined" disabled={hasAssignCustomer} onClick={onDelete}>Slett</WarningButton>
              </span>
            </Tooltip>
          )
        }
      </ButtonContainer>
    )
    : (
      <ButtonContainer>
        <StandardButton variant="outlined" onClick={() => setEditMode(true)}>Rediger</StandardButton>
      </ButtonContainer>
    ));

MerchantFormButtons.defaultProps = {
  editMode: false,
  hasAssignCustomer: true,
  isNew: true,
  onSave: () => {},
  onCancel: () => {},
  onDelete: undefined,
  setEditMode: () => {},
  isSolcellekraft: false,
};

MerchantFormButtons.propTypes = {
  editMode: PropTypes.bool,
  hasAssignCustomer: PropTypes.bool,
  isNew: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  setEditMode: PropTypes.func,
  isSolcellekraft: PropTypes.bool,
};

export default MerchantFormButtons;
