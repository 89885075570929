import PropTypes from 'prop-types';

export const PricesShape = PropTypes.shape({
  netPrice: PropTypes.number,
  quantity: PropTypes.number,
  total: PropTypes.number,
  totalIncludingVat: PropTypes.number,
  totalMerchantPurchasePrice: PropTypes.number,
});

export const QuoteShape = PropTypes.shape({
  county_number: PropTypes.number,
  roofs: PropTypes.arrayOf(PropTypes.shape({})),
  merchant_id: PropTypes.string,
  business_type: PropTypes.string,
  uuid: PropTypes.string,
  options: PropTypes.shape({
    inverters: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.shape({
        power: PropTypes.number,
        _key: PropTypes.string,
      }),
    })),
    freeTextValues: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.number,
      isDiscount: PropTypes.bool,
    })),
    solarPanel: PropTypes.shape({
      productName: PropTypes.string,
    }),
    roofing: PropTypes.string,
    isQuoteOrdered: PropTypes.bool,
    paymentMethod: PropTypes.string,
  }),
  calculations: PropTypes.shape({
    production: PropTypes.shape({
      panelCount: PropTypes.number,
      roofCount: PropTypes.number,
      roofArea: PropTypes.number,
      peakPower: PropTypes.number,
    }),
    cost: PropTypes.shape({
      sumAddition: PropTypes.number,
      sumAdditionIncludingVat: PropTypes.number,
      sumDeduction: PropTypes.number,
      sumDeductionIncludingVat: PropTypes.number,
      totalMerchantPurchasePrice: PropTypes.number,
      totalIncludingVatAndEnovaFunding: PropTypes.number,
      totalIncludingVat: PropTypes.number,
      enovaFunding: PropTypes.number,
      total: PropTypes.number,
      electrical: PropTypes.shape({
        total: PropTypes.number,
        totalIncludingVat: PropTypes.number,
        totalMerchantPurchasePrice: PropTypes.number,
        costPerPanel: PricesShape,
        startupCost: PricesShape,
      }),
      installation: PropTypes.shape({
        total: PropTypes.number,
        totalIncludingVat: PropTypes.number,
        totalMerchantPurchasePrice: PropTypes.number,
        costPerPanel: PricesShape,
        startupCost: PricesShape,
      }),
      equipment: PropTypes.shape({
        total: PropTypes.number,
        totalIncludingVat: PropTypes.number,
        totalMerchantPurchasePrice: PropTypes.number,
        ballastSystems: PricesShape,
        engineeringCostPerPanel: PricesShape,
        engineeringCostPerInstallation: PricesShape,
        wifiModule: PricesShape,
        mountingKits: PricesShape,
        connectors: PricesShape,
        cables: PricesShape,
        solarPanels: PricesShape,
        inverters: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
});
