import React, { useState } from 'react';
import * as S from './styles';
import { QuoteShape } from '../proptypes/quote';
import RoofTableDefault from './RoofTableDefault';
import RoofTableEditable from './RoofTableEditable';
import RoofMapInteractive from './RoofMapInteractive';

const DashboardQuotePageLowerPanel = ({
  quote,
  roofingTypes,
  updateQuoteRoofs,
}) => {
  const [selectedRoofId, setSelectedRoofId] = useState(null);
  const [editRoofs, setEditRoofs] = useState(false);

  if (!quote) return null;

  return (
    <S.LowerMapContainer>
      <S.LowerMapPanel style={{ marginBottom: '20px' }}>
        <RoofMapInteractive
          quote={quote}
          setSelectedRoofId={setSelectedRoofId}
        />
      </S.LowerMapPanel>
      <S.LowerTable>
        {editRoofs ? (
          <RoofTableEditable
            quote={quote}
            editRoofs={editRoofs}
            roofingTypes={roofingTypes}
            setEditRoofs={setEditRoofs}
            selectedRoofId={selectedRoofId}
            setSelectedRoofId={setSelectedRoofId}
            updateQuoteRoofs={updateQuoteRoofs}
          />
        ) : (
          <RoofTableDefault
            quote={quote}
            setEditRoofs={setEditRoofs}
            selectedRoofId={selectedRoofId}
            setSelectedRoofId={setSelectedRoofId}
          />
        )}
      </S.LowerTable>
    </S.LowerMapContainer>
  );
};

export default DashboardQuotePageLowerPanel;

DashboardQuotePageLowerPanel.defaultProps = {
  quote: undefined,
};

DashboardQuotePageLowerPanel.propTypes = {
  quote: QuoteShape,
};
