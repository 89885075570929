import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Collapse from '@mui/material/Collapse';

const TabsComponent = ({ children }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const childrenLocal = (Array.isArray(children) ? children : [children]).filter((element) => !!element);
  if (!childrenLocal || !childrenLocal.length) return null;

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={(_, newValue) => setTabIndex(newValue)}
          TabIndicatorProps={{ style: { backgroundColor: '#f7a618' } }}
          style={{ marginLeft: '20px', border: 'none' }}
          indicatorColor="primary"
          textColor="primary"
        >
          {
            childrenLocal.map(({ props } = {}, i) => (
              // eslint-disable-next-line react/no-array-index-key, react/prop-types
              <Tab key={`${props.label}-${i}`} label={props.label || 'NO LABEL'} style={{ color: tabIndex === i ? 'black' : 'gray' }} />
            ))
          }
        </Tabs>
      </Box>
      {
        childrenLocal.map((child, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Collapse in={i === tabIndex} timeout="auto" unmountOnExit key={`${child.props.label}-${i}`}>
            { child }
          </Collapse>
        ))
      }
    </Box>
  );
};

TabsComponent.defaultProps = {
  children: [],
};

TabsComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

export default TabsComponent;
