import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TablePaginationBase from '@mui/material/TablePagination';
import Select from '@mui/material/Select';
import DatePickerBase from 'react-datepicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  background: ${({ theme }) => theme.color.beige};
  color: ${({ theme }) => theme.color.primaryDark};
`;

export const Body = styled.div`
  width: 100%;
  padding: 1.5rem;
  /* margin: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column; */
`;

export const Heading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const SearchParameterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const PeriodSelect = styled(Select)`
  background: ${({ theme }) => theme.color.primaryLight};
  width: 13rem;
  height: 4rem;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.primaryLight};
  height: 4rem;
  width: 12rem;
`;

export const DatePickerLabel = styled.div`
  font-size: 0.7rem;
  color: gray;
  margin-left: 0.8rem;
  margin-top: 0.4rem;
`;

export const DatePicker = styled(DatePickerBase)`
  width: 8rem;
  border: 0;
  font-size: 1rem;
  font-family: inherit;
  padding-left: 0.8rem;
  margin-top: 0.4rem;
`;

export const SearchQuery = styled(TextField)`
  background: ${({ theme }) => theme.color.primaryLight};
  width: calc(100% - 40rem);
  height: 4rem;

  .MuiInputBase-input {
    margin-left: 0.8rem;
    margin-top: 0.5rem;
    margin-bottom: -0.5rem;
  }

  .MuiInputLabel-root {
    margin-left: 0.8rem;
    margin-top: 0.5rem;
  }

  .MuiInput-underline:before,  .MuiInput-underline:after {
    margin-bottom: -1rem;
  }
`;

export const TablePagination = styled(TablePaginationBase)`
  height: auto;
  min-height: 4rem;
  margin-top: 1rem;
  .MuiTablePagination-spacer {
    display: none;
  }
  .MuiToolbar-root {
    padding-left: 12px;
  }
`;

export const QuoteList = styled.div`
  /* display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  overflow-y: auto;
  flex-grow: 1; */
  min-height: 5rem;
`;

export const StyledLink = styled(Link)`
  display: block;
  color: rgba(0,0,0,0.7);
  background-color: rgba(255,255,255,0);
  transition: color 0.2s linear, background-color 0.2s linear;
  &:hover {
    color: rgba(0,0,0,0.95);
    background-color: rgba(255,255,255,0.8);
  }
`;

export const QuoteWrapper = styled.div`
  display: flex;
  // grid-template-columns: auto 2fr 1.5fr 0.75fr 0.75fr 1fr;
  // padding: 1rem;
  // background: ${({ theme }) => theme.color.primaryLight};
  // border-radius: 0.5rem;
  // color: ${({ theme }) => theme.color.primaryDark};
  align-items: flex-start;
  border-bottom: 1px solid #999;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
`;

export const QuoteDownloadWrapper = styled.div`
  // padding-left: 1rem;
  margin-bottom: 1rem;
`;

export const DownloadButton = styled(Button)`
  width: 8rem;
  background-color: ${({ theme }) => theme.color.lightOrange};
  color: ${({ theme }) => theme.color.white};
  margin-left: 0.5rem;
`;

export const SelectCheckbox = styled(Checkbox)`
  
  margin-right: 0;
  color: ${({ theme }) => theme.color.black};
  
  &.Mui-checked {
    color: ${({ theme }) => theme.color.orange};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
`;

export const FlexColumnSmall = styled.div`
  flex: 0 0 30px;
  width: 30px;
  max-width: 30px;
  font-size: 1rem;
`;

export const FlexColumnLarge = styled.div`
  flex-grow: 1;
  font-size: 1rem;
  padding: 0 1rem;
`;

export const FlexColumn = styled.div`
  flex: 0 0 15%;
  width: 15%;
  max-width: 15%;
  font-size: 1rem;
  padding: 0 1rem;
`;

export const QuoteMetadata = styled.div`
  color: gray;
  font-size: 1rem;
`;

export const Name = styled.div`
  // font-size: 1.2rem;
  font-weight: 700;
`;

export const Address = styled.div`
  font-size: 1rem;
`;

export const Email = styled.div`
  font-size: 1rem;
`;

export const Telephone = styled.div`
  font-size: 1rem;
`;
