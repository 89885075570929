import React, { useState } from 'react';

import { MenuItem } from '@mui/material';
import * as S from './styles';

const updateRoofMapping = (roofs, solarPanel) => {
  const minPanelCount = 8;

  return roofs.map((roof) => {
    const panelCountMax = Math.max(
      Math.floor(roof.availableSpace / (solarPanel.height * solarPanel.width)),
      minPanelCount,
    );

    return {
      ...roof,
      panelCountMin: minPanelCount,
      panelCountMax,
      panelCount: roof.panelCount < panelCountMax ? roof.panelCount : panelCountMax,
    };
  });
};

const SolarPanelSelector = ({
  quote,
  availableSolarPanels,
  updateSolarPanelType,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <>
      {/* <label htmlFor="solarPanel">Valgt panel</label> */}
      <S.SelectDropdown
        labelId="demo-simple-select-label"
        name="solarPanel"
        value={quote.options.solarPanel._key}
        disabled={quote.locked || isUpdating}
        onChange={(event) => {
          setIsUpdating(true);
          const newPanel = availableSolarPanels.find((panel) => panel._key === event.target.value);
          const newRoofs = updateRoofMapping([...quote.roofs], newPanel);
          updateSolarPanelType(newPanel, newRoofs)
            .then(() => {
              setIsUpdating(false);
            });
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {availableSolarPanels.map((panel) => (
          <MenuItem key={panel._key} value={panel._key}>{panel.productName}</MenuItem>
        ))}
      </S.SelectDropdown>
    </>
  );
};

export default SolarPanelSelector;
