import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const PriceDifferentiationTableButtons = ({ editMode, onUpdate, onEditMode, onCancel, loading }) => (
  <S.ButtonWrapper>
    {
      editMode
        ? (
          <>
            <S.StandardButton onClick={onUpdate} disabled={loading}>
              Lagre
            </S.StandardButton>
            <S.StandardButton onClick={onCancel} disabled={loading}>
              Avbryt
            </S.StandardButton>
          </>
        ) : (
          <S.StandardButton onClick={onEditMode} disabled={loading}>
            Rediger
          </S.StandardButton>
        )
    }
  </S.ButtonWrapper>
);

PriceDifferentiationTableButtons.defaultProps = {
  editMode: false,
  loading: false,
};

PriceDifferentiationTableButtons.propTypes = {
  editMode: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onEditMode: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default PriceDifferentiationTableButtons;
