import styled from 'styled-components';
import Button from '@mui/material/Button';

export const UserOuterWrapper = styled.div`
  background: ${({ theme }) => theme.color.primaryLight};
  color: ${({ theme }) => theme.color.primaryDark};
  width: 100%;
  margin-bottom: .5rem;
  border-bottom: ${({ theme }) => theme.color.beige} 1px solid;
`;

export const UserWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 5rem;
  padding: 1rem;
`;

export const EditColumn = styled.div`
  float: right;
  display: flex;
  flex-direction: row-reverse;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
`;

export const Role = styled.div`
  color: gray;
  font-size: 1rem;
`;

export const UserFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem;
`;

export const StandardButton = styled(Button)`
  width: 8rem;
  background-color: #f7a618;
  color: white;
`;
