import styled from 'styled-components';
import Button from '@mui/material/Button';
import FormHelperTextBase from '@mui/material/FormHelperText';

export const UserFormContainer = styled.div`
  background: ${({ theme, isNew }) => (isNew ? theme.color.beige : theme.color.primaryLight)};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: .5rem;
`;

export const UserFormInputs = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  > * {
    width: 32%;
    min-width: 15rem;
    height: 3.5rem;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: right;
  gap: 1rem;
`;

export const StandardButton = styled(Button)`
  width: 8rem;
  background-color: ${({ theme }) => `${theme.color.lightOrange}`};
  color: white;

  &:hover {
    background: ${({ theme }) => `${theme.color.primaryHover}60`};
  }
`;

export const WarningButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.red};
  width: 8rem;
  color: white;
`;

export const FormHelperErrorText = styled(FormHelperTextBase)`
  color: red;
`;
