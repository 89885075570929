import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import {
  ProgressContainer,
  ChildrenContainer,
  ProgramsBackDrop,
  FixedProgramsBackDrop,
} from './styles';

const ProgressWithBackDrop = ({
  children,
  loading,
  fitContent,
  style,
}) => (
  fitContent
    ? (
      <ProgressContainer>
        <ChildrenContainer>
          {children}
        </ChildrenContainer>
        {
          loading && (
            <ProgramsBackDrop style={style}>
              <CircularProgress color="inherit" />
            </ProgramsBackDrop>
          )
        }
      </ProgressContainer>
    )
    : (
      <>
        {children}
        {
          loading && (
            <FixedProgramsBackDrop style={style}>
              <CircularProgress color="inherit" />
            </FixedProgramsBackDrop>
          )
        }
      </>
    )
);

export default ProgressWithBackDrop;

ProgressWithBackDrop.defaultProps = {
  children: null,
  loading: false,
  fitContent: false,
  style: {},
};

ProgressWithBackDrop.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  fitContent: PropTypes.bool,
  style: PropTypes.shape({}),
};
