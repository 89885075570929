import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import useUserInfo from '../../../hooks/useUserInfo';
import { ReactComponent as SolcellekraftLogo } from '../../../assets/SolcellekraftLogo.svg';
import { ReactComponent as TakbytteLogoLogo } from '../../../assets/TakbytteLogo.svg';
import * as S from './styles';

const MENU_LINKS = [
  { label: 'Beregninger', link: '/quotes' },
  { label: 'Forhandlere', link: '/merchants', availableFor: ['superuser', 'admin'] },
  { label: 'Brukere', link: '/users', availableFor: ['superuser', 'admin', 'merchant_admin'] },
];

const DashboardContainerWithMenu = ({ children }) => {
  const { userInfo: { role } = {} } = useUserInfo();
  const { logout } = useAuth0();

  return (
    <S.PageContainer>
      <S.PageContentContainer>
        { children }
      </S.PageContentContainer>
      <S.ManuContainer>
        <S.NavButtons>
          <S.TitleButton to="/">
            { process.env.REACT_APP_SITE === 'TAKBYTTE' ? <TakbytteLogoLogo style={{ marginTop: '8px' }} /> : <SolcellekraftLogo /> }
          </S.TitleButton>
          {
            MENU_LINKS
              .filter(({ availableFor = [] }) => !availableFor.length || availableFor.includes(role))
              .map(({ label = '', link = '/' }) => (
                <S.MenuButton to={link} key={`${label}-${link}`}>
                  {label}
                </S.MenuButton>
              ))
          }
        </S.NavButtons>
        <S.LogoutButton onClick={() => logout({
          returnTo: window.location.origin,
        })}
        >
          Logg ut
        </S.LogoutButton>
      </S.ManuContainer>
    </S.PageContainer>
  );
};

DashboardContainerWithMenu.defaultProps = {
  children: null,
};

DashboardContainerWithMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.node,
    ])),
  ]),
};

export default DashboardContainerWithMenu;
