import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

export const ChangeMerchantWrapper = styled.div`
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const SelectCheckbox = styled(Checkbox)`
  margin-right: 1rem;
  color: ${({ theme }) => theme.color.black};
  
  &.Mui-checked {
    color: ${({ theme }) => theme.color.orange};
  }
`;

export const OpenDialogButton = styled(Button)`
  width: 12rem;
  background-color: ${({ theme }) => theme.color.lightOrange};
  color: ${({ theme }) => theme.color.white};
  margin-right: 1rem;

  &:hover {
    background: ${({ theme }) => `${theme.color.primaryHover}60`};
  }
`;

export const ExportButton = styled(Button)`
  width: 12rem;
  background-color: ${({ theme }) => theme.color.lightOrange};
  color: ${({ theme }) => theme.color.white};
  margin-right: 1rem;

  &:hover {
    background: ${({ theme }) => `${theme.color.primaryHover}60`};
  }
`;
