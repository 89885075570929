import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';

export const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 13rem auto;
  grid-template-rows: calc(100vh - 1px);
  @media print {
    grid-template-columns: 0 auto;
  }
`;

export const PageContentContainer = styled.div`
  grid-column: 2/3;
  grid-row: 1/2;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.beige};
  color: ${({ theme }) => theme.color.primaryDark};
  min-width: 50rem;
  max-width: 100rem;
  width: 100%;
  height: fit-content;
  
  @media print {
    background-color: white;
    margin-left: -5px;
  }
`;

export const ManuContainer = styled.div`
  height: 100vh;
  width: 13rem;
  grid-column: 1/2;
  grid-row: 1/2;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.primaryDark};
  position: fixed;
  z-index: 9999;

  @media print {
    display: none;
  }
`;

export const NavButtons = styled.div`
  /* display: flex;
  flex-direction: column; */
  font-size: 1.15rem;
`;

export const TitleButton = styled(Link)`
  display: block;
  width: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.color.primaryHover};
  color: ${({ theme }) => theme.color.primaryLight};
  cursor: pointer;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const MenuButton = styled(NavLink)`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  background: transparent;
  color: ${({ theme }) => theme.color.primaryLight};
  cursor: pointer;
  border-bottom: 1px gray solid;
  
  &.active {
    background: ${({ theme }) => `${theme.color.primaryHover}30`};
  }

  &:hover {
    background: ${({ theme }) => `${theme.color.primaryHover}60`};
  }
`;

export const LogoutButton = styled.button`
  font-size: ${({ theme }) => theme.fontSize.large.sm};
  height: 3rem;
  margin: 1rem;
  cursor: pointer;
`;
