import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { productRowsSolcellekraft, productRowsTakbytte } from '../../../lib/productTableRows';
import { SettingsContext } from '../../../lib/Settings';
import ProgressWithBackDrop from '../../progressWithBackDrop/ProgressWithBackDrop';
import useMerchantEventHandler from './useMerchantEventHandler';
import MerchantFormButtons from './MerchantFormButtons';
import MerchantPurchaseDiscountTableRow from './MerchantPurchaseDiscountTableRow';
import * as S from './styles';

const usePrices = () => {
  const settings = useContext(SettingsContext);

  return useMemo(() => {
    const prices = {};
    (process.env.REACT_APP_SITE === 'TAKBYTTE' ? productRowsTakbytte : productRowsSolcellekraft).forEach(({ price }) => {
      const productPrice = Number.parseFloat(settings.prices[price]);
      if (!Number.isNaN(productPrice)) prices[price] = productPrice;
      else if (price === 'solarPanels') prices[price] = 1000;
      else if (price === 'roofingType') prices[price] = 1000;
      else if (price === 'inverters') prices[price] = 30000;
      else prices[price] = -1;
    });

    return prices;
  }, [settings?.prices?.length]);
};

const MerchantPurchaseDiscountFrom = ({
  setVisibility,
  merchant,
  refreshMerchantList,
  editMode: initialEditMode,
  isNew,
}) => {
  const {
    price_adjustment: priceAdjustment = {},
    purchase_discount: purchaseDiscount = {},
  } = merchant || {};

  const [editMode, setEditMode] = useState(initialEditMode);
  const [purchaseDiscounts, setPurchaseDiscounts] = useState(purchaseDiscount);
  const prices = usePrices();

  const {
    setFormValues,
    loading,
    onSave,
    onCancel,
  } = useMerchantEventHandler(refreshMerchantList, setVisibility, setEditMode, merchant, isNew);

  const onCancelLocal = () => {
    onCancel();
    setPurchaseDiscounts(purchaseDiscount);
  };

  const onChange = useCallback(({ target }) => {
    const { name = '', value = '' } = target;
    if (!name) return;

    setPurchaseDiscounts((prevState) => ({ ...prevState, [name]: value.replaceAll(',', '.') }));
  }, []);

  const onBlur = useCallback(({ target }) => {
    const { name = '', value = '' } = target;
    const number = parseFloat(value.replaceAll(',', '.'));
    const price = !Number.isNaN(number)
      ? Math.min(Math.max(number, -1000), 1000)
      : 0;

    setPurchaseDiscounts((prevState) => {
      const newPurchaseDiscount = { ...prevState, [name]: price };
      setFormValues((p) => ({ ...p, purchase_discount: newPurchaseDiscount }));
      return newPurchaseDiscount;
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      setPurchaseDiscounts(purchaseDiscount);
    }
  }, [loading]);

  return (
    <S.Container>
      <ProgressWithBackDrop loading={loading} fitContent>
        <table style={{
          fontSize: '1.0rem',
          padding: '20px',
          width: '100%',
          minWidth: '950px',
        }}
        >
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th style={{ textAlign: 'left', width: '30%' }}>Vare</th>
              <th style={{ width: '12%' }}>Veil. pris</th>
              <th style={{ fontStyle: 'italic', width: '12%' }}>Rabatt (-)</th>
              <th style={{ width: '12%' }}>Forhandler innkjøpspris</th>
              <th style={{ width: '12%' }}>Forhandler rabatt (-) / påslag (+)</th>
              <th style={{ width: '12%' }}>Forhandler pris eks. mva.</th>
              <th style={{ width: '12%' }}>Forhandler pris inkl. mva.</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'center' }}>
            {
              (process.env.REACT_APP_SITE === 'TAKBYTTE' ? productRowsTakbytte : productRowsSolcellekraft).map((product) => (
                <MerchantPurchaseDiscountTableRow
                  key={`${product.name}-${product.purchaseDiscountPercentage}`}
                  heading={product.heading}
                  discountsPercentage={purchaseDiscounts[product.purchaseDiscountPercentage]}
                  AdjustmentPercentage={priceAdjustment[product.priceAdjustmentPercentage]}
                  productPrice={prices[product.price]}
                  name={product.purchaseDiscountPercentage}
                  label={product.name}
                  onChange={onChange}
                  onBlur={onBlur}
                  editMode={editMode}
                />
              ))
            }
          </tbody>
        </table>
        <S.BottomRow>
          (*) Eksempelpris - avhengig av kundes valg
        </S.BottomRow>
        <MerchantFormButtons
          isNew={isNew}
          editMode={editMode}
          hasAssignCustomer={merchant.hasAssignCustomer}
          onCancel={onCancelLocal}
          onSave={onSave}
          setEditMode={setEditMode}
        />
      </ProgressWithBackDrop>
    </S.Container>
  );
};

MerchantPurchaseDiscountFrom.defaultProps = {
  setVisibility: () => {},
  merchant: {},
  refreshMerchantList: () => {},
  editMode: false,
  isNew: false,
};

MerchantPurchaseDiscountFrom.propTypes = {
  setVisibility: PropTypes.func,
  merchant: PropTypes.shape({
    name: PropTypes.string,
    hasAssignCustomer: PropTypes.bool,
  }),
  refreshMerchantList: PropTypes.func,
  editMode: PropTypes.bool,
  isNew: PropTypes.bool,
};

export default MerchantPurchaseDiscountFrom;
