import styled from 'styled-components';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  align-items: flex-end;
  position: relative;
`;

export const TableHeading = styled.h5`
  width: calc(100% - 2rem);
  padding-bottom: 0.5rem;
  border-bottom: 1px solid;
  margin: 1rem;
`;

export const TableGroupHeading = styled.h6`
  text-align: left;
  margin-top: 0.5rem;
`;

export const InputTextField = styled(TextField)`
  max-width: 5rem;
`;

export const BottomRow = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 1rem 1rem 1rem;
  font-size: 1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  grid-gap: 0.7rem;
`;

export const StandardButton = styled(Button)`
  width: 12rem;
  background-color: ${({ theme }) => `${theme.color.lightOrange}`};
  color: white;

  &:hover {
    background: ${({ theme }) => `${theme.color.primaryHover}60`};
  }
`;

export const CircularProgressPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.8);
`;
