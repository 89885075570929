import styled from 'styled-components';
import Button from '@mui/material/Button';
import { MapContainer as MapContainerBase } from 'react-leaflet';
import DialogBase from '@mui/material/Dialog';
import DialogActionsBase from '@mui/material/DialogActions';
import SelectBase from '@mui/material/Select';
import DatePickerBase from 'react-datepicker';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.color.primaryDark};
`;

export const Header = styled.div`
  font-size: 1rem;
  border-bottom: 1px solid #e8e2dc;
  padding: 0 0 18px 0;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  padding: 1.5rem 2rem;
  width: 100%;
  max-width: 100%;
  @media print {
    padding-top: 3rem;
  }
`;

export const UpperPanel = styled.div`
  display: flex;
  padding: 0;
`;

export const UpperLeftPanel = styled.div`
  width: 66.667%;
  font-size: 1rem;
`;

export const UpperRightPanel = styled.div`
  font-size: 1rem;
  text-align: right;
  width: 33.333%;
  padding-top: 0.5rem;
`;

export const BasicLink = styled.a`
  color: inherit;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export const LinkStyleButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  border: none;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 2rem;
  
  .MuiButtonBase-root,
  .MuiInputBase-root {
    margin: 0 12px 12px 0;
  }
  
  @media print {
    display: none;
  }
`;

export const MiddlePanel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  font-size: 1rem;
  background-color: white;
  box-shadow: 1px 2px 5px rgba(0,0,0,0.12);
  border-radius: 6px;
`;

export const MiddlePanelHeader = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid #f0ece8;

  @media print {
    border: none;
    font-weight: 400;
    font-size: 1.4rem;
    padding: 0;

    // controls distance from upper map to first list in first page.
    margin: 7rem 0 1rem;
    counter-increment: page;
  }
`;

export const MiddlePanelMain = styled.div`
  padding: 15px 20px 20px;

  @media print {
    // controls distance from "total pris" middle to the map in last page.
    margin-bottom: 20rem;
    padding: 0 2rem 0 0;
    counter-increment: page;
  }
`;

export const TopList = styled.div`
  display: flex;
  justify-content: space-between;
  // border-bottom: 2px solid #f0ece8;
  margin-bottom: 0;
  
  @media print {
    border: none;
    flex-direction: column-reverse;
    
    // Controls distance between first and second list. distance between first and second page.
    margin-bottom: 10rem;
  }
`;

export const TopListSide = styled.div`
  width: 46%;

  @media print {
    margin-bottom: 2rem;
    width: 100%;
  }
`;

export const TopListRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: .4rem;

  &:not(:last-child) {
    border-bottom: 1px solid #f0ece8;
  }
  
  &:last-child {
    @media print {
      border-bottom: 2px solid #f0ece8;
    }
  }

  ${({ bold }) => (bold ? `
    font-weight: bold;
  ` : '')}
`;

export const LowerMapPanelHeader = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
  
  @media print {
    display: none;
  }
`;

export const LowerMapContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 30px;
`;

export const LowerMapPanel = styled.div`
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  font-size: 1rem;
  padding-right: 15px;
  @media print {
    margin-top: 3rem;
  }
`;

export const LowerTable = styled.div`
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  font-size: 1rem;
  padding-left: 15px;
`;

export const StandardButton = styled(Button)`

  font-size: 0.9rem;
  line-height: 1;
  text-transform: none;
  padding: 10px 14px 10px 18px;
  background-color: rgba(255,255,255,0.2);
  border: 1px solid rgba(0,0,0,0.25);
  margin: 0;
  color: inherit;
  
  text-decoration: none;

  &:hover {
    background-color: rgba(255,255,255,0.8);
    border: 1px solid rgba(0,0,0,0.4);
  }
  
  svg {
    width: 14px;
    height: auto;
    margin-left: 10px;
  }

  @media print {
    display: none;
  }
`;

export const DatePicker = styled(DatePickerBase)`
  width: 180px;
  border: 0;
  font-size: 0.9rem;
  line-height: 1;
  font-family: inherit;
  padding: 8px 18px;
  text-align: right;
  margin-top: 6px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  
  &:focus {
    outline: none;
    border: 1px solid #999;
  }
  
  &:disabled {
    padding: 0;
    background-color: transparent;
    border: none;
    font-weight: bold;
  }
  
`;

export const Input = styled.input`
  border: 0;
  font-size: 0.9rem;
  line-height: 1;
  font-family: inherit;
  padding: 8px 18px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin: 0 0 15px;
  
  &:focus {
    outline: none;
    border: 1px solid #999;
  }
  
  &:disabled {
    padding: 8px 18px;
    background-color: transparent;
    border: 1px solid #999;
  }
  
`;

export const TableInput = styled.input`
  width: 100px;
  border: 0;
  font-size: 0.9rem;
  line-height: 1;
  font-family: inherit;
  padding: 6px 11px;
  border-radius: 6px;
  border: 1px solid #ccc;
  
  &:focus {
    outline: none;
    border: 1px solid #999;
  }
  
  &:disabled {
    padding: 8px 18px;
    background-color: transparent;
    border: 1px solid #999;
  }
`;

export const RoofSpaceThresholdContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 25px;
  margin-bottom: 25px;
`;

export const RoofSpaceThresholdLabel = styled.div`
  width: 250px;
  flex: 0 0 250px;
  margin-right: 30px;
`;

export const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 1px 2px 5px rgba(0,0,0,0.12);
`;

export const TabButton = styled(Button)`

  font-size: 1rem;
  line-height: 1;
  text-transform: none;
  font-weight: 700;
  padding: 12px 22px 14px;
  background-color: rgba(0,0,0,0.04);
  border: 1px solid rgba(0,0,0,0.08);
  margin: 0 15px 0 0;
  color: inherit;
  
  text-decoration: none;
  
  &:disabled {
    background-color: #FFF1DE;
    color: #232323;
    border: 1px solid #ffe1c0;
  }

  &:hover {
    background-color: rgba(255,255,255,0.8);
    border: 1px solid rgba(0,0,0,0.1);
  }
`;

export const DropDownWrapper = styled.div`
  display: flex;
`;

export const DropDownContainer = styled.div`
  margin: 0 20px 15px 0;
  > span {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
`;

export const SelectDropdown = styled(SelectBase)`
  text-transform: none;
  padding: 0;
  background-color: rgba(255,255,255,0.2);
  font-size: 0.9rem;
  
  &:hover {
    background-color: rgba(255,255,255,0.8);
  }
  
  .MuiSelect-select {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 18px;
  }
  
`;

export const MapContainer = styled(MapContainerBase)`
  height: ${({ height = '45rem' }) => height};
  width: auto;
  text-align: left;

  @media print {
    .leaflet-bottom, .leaflet-control {
      display: none;
    }
  }
`;

export const CalcDetailTableRow = styled.div`
  display: flex; 
  border-bottom: 1px solid #f0ece8;
  padding-top: .5rem;
  font-size: 1rem;
`;

export const CalcDetailColumn = styled.div`
  flex: 0 0 180px;
  text-align: right;
  font-variant-numeric: tabular-nums;
`;

export const Thead = styled.thead`
  background-color: #3d444e;
  color: #fefefe;
  th {
    text-align: right;
    &:first-child {
      text-align: left;
    }
  }
`;

export const LowerMapTableRow = styled.tr`
  will-change: background-color;
  background-color: transparent;
  transition: background-color 2s;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
`;

export const LowerMapTableColumn = styled.td`
  padding: 10px 10px 6px;
`;

export const FreeTextFieldContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid #f0ece8;
  *::before { border: none; }
  
  >:nth-child(1) {
    width: calc(100% - 25rem);
    max-width: 25rem;
  }
  
  >:nth-child(2) {
    display: inline-flex;
    font-size: 1rem;
    width: 18rem;
    
    > div {
      width: 8rem;
      &:last-child {
        padding-top: 3px;
        width: 50%;
        margin-left: 1rem;
        text-align: end;
        font-variant-numeric: tabular-nums;
      }
    }
    
    > span {
      padding-top: 3px;
      padding-right: 5px;
    }
  }
  
  svg {
   fill: green;
  }
    
  .rotate {
    fill: black;
    animation: ckw infinite 1s linear;
    @keyframes ckw {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
    }
  }
  
  ${({ hasKeyAndValue }) => (hasKeyAndValue ? '' : '@media print { display: none; }')}  
  
  input {
    font-family: 'BrandonText',sans-serif;
    font-size: 1rem;
    color: ${({ theme }) => theme.color.black};
  }
`;

export const Dialog = styled(DialogBase)`
  .MuiDialog-paper {
    background-color: ${({ theme }) => theme.color.lighterGray};
  }
`;

export const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  gap: 1rem;
`;

export const EmailForm = styled.div`
  display: inline-flex;
  width: 100%;
  padding-left: 1rem;
  gap: 1rem;

  > * {
    width: calc(33% - 1rem);
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 16rem);
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
`;

export const ErrorText = styled.div`
  margin: 1rem;
  padding: 1rem 0;
  border-bottom: black solid 2px;
  
  *:first-child {
    color: ${({ theme }) => theme.color.red};
  }
`;

export const DialogActions = styled(DialogActionsBase)`
  svg {
    fill: green;
  }

  .rotate {
    fill: black;
    animation: ckw infinite 1s linear;
    @keyframes ckw {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const Select = styled(SelectBase)`
  font-family: 'BrandonText', serif;
  height: 31px;
  margin-top: 0.6rem;
  padding-left: 0.8rem;
`;
