import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

export const SearchTextField = styled(TextField)`
  margin-bottom: -20px;

  div {
    background-color: white;
  }
  
  .MuiFormHelperText-root {
    height: 20px;
    margin-top: 0;
  }
  
  .MuiInputAdornment-positionEnd svg {
    color: #f50057;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  padding-bottom: 20px;

  span {
    background: rgb(255,255,255);
    background: linear-gradient(0deg,
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,0) 45%,
      rgba(255,255,255,1) 45%,
      rgba(255,255,255,1) 55%,
      rgba(255,255,255,0) 55%,
      rgba(255,255,255,0) 100%
    );
    position: absolute;
    transform: translate(-13px, -15px) scale(0.75);
    color: rgba(0, 0, 0, .58);
    width: fit-content;
    padding: 0 10px;
  };
`;

export const SelectFilter = styled(Select)`
  background-color: white;
  width: 100%;
`;
