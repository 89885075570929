import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.color.beige};
  width: 100%;
  height: 100%;
  min-height: 15rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  padding: 2rem 4rem;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    flex-direction: column;
  }
`;

export const A = styled.a`
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: underline;
  }
`;
