import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LanguageContext } from '../../../lib/Language';
import roofTypesMap from './roofTypeMapping.json';
import FreeTextField from './FreeTextField';
import * as S from './styles';

import { QuoteShape } from '../proptypes/quote';

/**
 * @param amount {number}
 * @param language {string}
 * @returns {string}
 */
const toFormattedAmount = (amount = 0, language = 'no') => (
  `${new Intl.NumberFormat(language, {
    style: 'currency',
    currency: 'NOK',
    maximumFractionDigits: 1,
  }).format(Math.round(amount)).slice(0, -1)}-`
);

export const getTotalPrices = (isBusiness, cost) => {
  // needed to support legacy calculation, not including projectPrice.
  const isLegacyCalc = cost.projectPrice == null;

  return isLegacyCalc
    ? {
      total: (isBusiness ? cost.total : cost.totalIncludingVatAndEnovaFunding) || 0,
      projectPriceIncludingVat: cost.totalIncludingVat,
      projectPrice: cost.total,
      isLegacyCalc,
    }
    : {
      total: cost.total,
      projectPriceIncludingVat: cost.projectPriceIncludingVat,
      projectPrice: cost.projectPrice,
      isLegacyCalc,
    };
};

const TopListRow = ({ title, value, show, bold }) => (
  show
    ? (
      <S.TopListRow bold={bold}>
        <span>{title}</span>
        <span>{value}</span>
      </S.TopListRow>
    ) : (
      <S.TopListRow>&nbsp;</S.TopListRow>
    )
);

TopListRow.defaultProps = {
  title: '',
  value: '',
  show: true,
  bold: false,
};

TopListRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  show: PropTypes.bool,
  bold: PropTypes.bool,
};

const DashboardQuotePageOverviewPanel = ({ quote }) => {
  const { language } = useContext(LanguageContext);

  if (!quote) return null;
  const { calculations, options, business_type: businessType } = quote;
  const { paymentMethod, roofing } = options || {};
  const { cost, production } = calculations;

  const isBusiness = businessType === 'business';
  const { total, projectPriceIncludingVat, projectPrice, isLegacyCalc } = getTotalPrices(isBusiness, cost);

  return (
    <S.MiddlePanel>
      <S.MiddlePanelHeader>
        Viser beregning for
        <b>{` ${production.roofCount} tak `}</b>
        på totalt
        <b>{` ${production.panelCount} paneler `}</b>
        over
        <b>{` ${new Intl.NumberFormat(language).format(production.roofArea)} m²`}</b>
      </S.MiddlePanelHeader>
      <S.MiddlePanelMain>
        <S.TopList>
          <S.TopListSide>
            <TopListRow title="Utstyr" value={toFormattedAmount(isBusiness ? cost.equipment.total : cost.equipment.totalIncludingVat)} />
            <TopListRow title="Takmontering" value={toFormattedAmount(isBusiness ? cost.installation.total : cost.installation.totalIncludingVat)} />
            <TopListRow
              title="Elektrisk montering"
              value={toFormattedAmount(isBusiness ? cost.electrical.total : cost.electrical.totalIncludingVat)}
            />
            <TopListRow
              title="Tillegg"
              show={!isLegacyCalc}
              value={toFormattedAmount(isBusiness ? cost.sumAddition : cost.sumAdditionIncludingVat || 0)}
            />
            <TopListRow title="Prosjekt pris" bold value={toFormattedAmount(isBusiness ? projectPrice : projectPriceIncludingVat)} />
            <TopListRow title="Enova-støtte" show={!isBusiness} value={toFormattedAmount(cost.enovaFunding)} />
            <TopListRow
              title="Fratrekk"
              show={!isLegacyCalc}
              value={toFormattedAmount(isBusiness ? cost.sumDeduction : cost.sumDeductionIncludingVat || 0)}
            />
            <TopListRow title="Total pris" bold value={toFormattedAmount(total)} />
          </S.TopListSide>
          <S.TopListSide>
            <TopListRow title="Effekt:" value={`${new Intl.NumberFormat(language).format(production.peakPower)} kWp`} />
            <TopListRow title="Taktype:" value={`${roofTypesMap[roofing]}`} />
            <TopListRow title="Antall tak:" value={`${production.roofCount}`} />
            <TopListRow title="Antall paneler:" value={`${production.panelCount}`} />
            <TopListRow title="Størrelse:" value={`${new Intl.NumberFormat(language).format(production.roofArea)} m²`} />
            <TopListRow title="Ønsker finansiering:" value={paymentMethod === 'loan' ? 'Ja' : 'Nei'} />
            {paymentMethod === 'loan' && quote.options.selectedFinancingOption && (
              <p>
                <b>{quote.options.selectedFinancingOption.title}</b>
                &nbsp;-&nbsp;
                {quote.options.selectedFinancingOption.description}
                <br />
                {`Rente: ${quote.options.selectedFinancingOption.interestRate}%`}
                <br />
                {`Nedbetalingstid: ${quote.options.selectedFinancingOption.termLength} år`}
                <br />
                {`Estimert pris per mnd: ${toFormattedAmount(quote.options.selectedFinancingOption.monthlyCost)}`}
              </p>
            )}
          </S.TopListSide>
        </S.TopList>
      </S.MiddlePanelMain>
    </S.MiddlePanel>
  );
};

DashboardQuotePageOverviewPanel.defaultProps = {
  quote: undefined,
};

DashboardQuotePageOverviewPanel.propTypes = {
  quote: QuoteShape,
};

export default DashboardQuotePageOverviewPanel;
