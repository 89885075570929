import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const LANGUAGE = 'no';
const TAX_POINT = 1.25;

const onFocus = (e) => e.target.select();
const getFormattedNumber = (num) => new Intl.NumberFormat(LANGUAGE, { style: 'currency', currency: 'NOK' }).format(num);

const MerchantPurchaseDiscountTableRow = ({
  heading,
  discountsPercentage = 0,
  AdjustmentPercentage = 0,
  productPrice,
  name,
  label,
  onChange,
  onBlur,
  editMode,
}) => {
  const value = (discountsPercentage).toString().replaceAll('.', ',');

  const productPurchasePrice = productPrice * (1 + (discountsPercentage / 100));
  const productAdjustedPrice = productPrice * (1 + (AdjustmentPercentage / 100));
  const productAdjustedPriceWithTax = productAdjustedPrice * TAX_POINT;

  return (
    <>
      {
        heading
          ? (
            <tr>
              <td colSpan="5">
                <S.TableGroupHeading>{heading}</S.TableGroupHeading>
              </td>
            </tr>
          )
          : null
      }
      <tr>
        <td style={{ textAlign: 'left' }}>{label}</td>
        <td>{getFormattedNumber(productPrice)}</td>
        <td>
          {
            editMode
              ? (
                <>
                  <S.InputTextField
                    value={value}
                    inputProps={{
                      style: { textAlign: 'center' },
                    }}
                    name={name}
                    onFocus={onFocus}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="standard"
                  />
                  {' %'}
                </>
              )
              : `${getFormattedNumber(value)} %`
          }
        </td>
        <td>{getFormattedNumber(productPurchasePrice)}</td>
        <td>{`${getFormattedNumber(AdjustmentPercentage)} %`}</td>
        <td>{getFormattedNumber(productAdjustedPrice)}</td>
        <td>{getFormattedNumber(productAdjustedPriceWithTax)}</td>
      </tr>
    </>
  );
};

MerchantPurchaseDiscountTableRow.defaultProps = {
  heading: '',
  discountsPercentage: 0,
  AdjustmentPercentage: 0,
  productPrice: 0,
  name: '',
  label: '',
  onChange: () => {},
  onBlur: () => {},
  editMode: false,
};

MerchantPurchaseDiscountTableRow.propTypes = {
  heading: PropTypes.string,
  discountsPercentage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  AdjustmentPercentage: PropTypes.number,
  productPrice: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  editMode: PropTypes.bool,
};

export default MerchantPurchaseDiscountTableRow;
