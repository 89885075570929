import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';
import { format } from 'date-fns';

const exportCustomerListXlsxFile = async (customers, merchantList) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet('Kunder');

  ws.getCell('A1').value = 'Epost';
  ws.getCell('B1').value = 'Navn';
  ws.getCell('C1').value = 'Adresse';
  ws.getCell('D1').value = 'Postnummer';
  ws.getCell('E1').value = 'Poststed';
  ws.getCell('F1').value = 'Forhandler';

  ws.getCell('A1').font = { bold: true };
  ws.getCell('B1').font = { bold: true };
  ws.getCell('C1').font = { bold: true };
  ws.getCell('D1').font = { bold: true };
  ws.getCell('E1').font = { bold: true };
  ws.getCell('F1').font = { bold: true };

  ws.columns[0].width = 40;
  ws.columns[1].width = 40;
  ws.columns[2].width = 40;
  ws.columns[3].width = 20;
  ws.columns[4].width = 20;
  ws.columns[5].width = 40;

  customers.forEach((customer, index) => {
    ws.insertRow(2 + index, [
      `${customer.email}`,
      `${customer.name}`,
      `${customer.address}`,
      `${customer.postal_code}`,
      `${customer.city}`,
      `${customer.merchant_id ? merchantList.filter((m) => m.merchant_id === customer.merchant_id)[0].name : process.env.REACT_APP_COMPANY_NAME}`,
    ]);
  });

  const buffer = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${format(new Date(), 'yyyy-MM-dd HH.mm.ss')} - kundeliste.xlsx`);
};

export default exportCustomerListXlsxFile;
