import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import isEmail from 'validator/lib/isEmail';
import { productRowsSolcellekraft, productRowsTakbytte } from '../../../lib/productTableRows';

const getDefaultPrice = (prices = {}, fieldName = 'priceAdjustmentPercentage') => {
  const result = {};
  (process.env.REACT_APP_SITE === 'TAKBYTTE' ? productRowsTakbytte : productRowsSolcellekraft).forEach((product) => {
    result[product[fieldName]] = prices[product[fieldName]] ?? 0;
  });

  return result;
};

const isValidLink = (link) => {
  try {
    if (!link.includes('.')) return false;
    // eslint-disable-next-line no-new
    new URL(link);
    return true;
  } catch (_) {
    return false;
  }
};

const correctLink = (link) => {
  if (!link) return '';
  return link.endsWith('/')
    ? correctLink(link.slice(0, -1))
    : link;
};

const getMerchantBody = (newMerchantData) => ({
  name: newMerchantData.name ?? '',
  addressLine1: newMerchantData.address_line1 ?? '',
  addressLine2: newMerchantData.address_line2 ?? '',
  phone: newMerchantData.phone ?? '',
  postalCode: newMerchantData.postal_code ?? '',
  email: newMerchantData.email ?? '',
  notification_email: newMerchantData.notification_email ?? '',
  city: newMerchantData.city ?? '',
  county: newMerchantData.county ?? '',
  countryCode: newMerchantData.countryCode ?? 'NO',
  priceAdjustment: { ...getDefaultPrice(newMerchantData?.price_adjustment, 'priceAdjustmentPercentage') },
  purchaseDiscount: { ...getDefaultPrice(newMerchantData?.purchase_discount, 'purchaseDiscountPercentage') },
  merchant_domain: correctLink(newMerchantData.merchant_domain),
  organization_number: newMerchantData.organization_number ?? '',
});

const createMerchant = async (newMerchantData, getAccessTokenSilently) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessTokenSilently()}`,
    },
    body: JSON.stringify(getMerchantBody(newMerchantData)),
  };

  const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}`;
  const result = await fetch(url, requestOptions);
  if (result.status < 199 || result.status > 299) {
    throw new Error(`${result.status}: ${result.statusText}`);
  }
};

const updateMerchant = async (newMerchantData, getAccessTokenSilently) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessTokenSilently()}`,
    },
    body: JSON.stringify(getMerchantBody(newMerchantData)),
  };

  const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}/${newMerchantData.merchant_id}`;
  const result = await fetch(url, requestOptions);
  if (result.status < 200 || result.status > 299) {
    throw new Error(`${result.status}: ${result.statusText}`);
  }
};

const validateMerchantData = (values) => {
  const errors = {};
  if (!values.name?.trim()) errors.name = 'Ugyldig navn';
  if (!values.address_line1?.trim()) errors.address_line1 = 'Ugyldig adresse';
  if (!values.phone?.trim()) errors.phone = 'Ugyldig telefonnummer';
  if (!values.county) errors.county = 'Fylke ikke valgt';
  if (values.email && !isEmail(values.email)) errors.email = 'Ugyldig epost';
  if (values.notification_email && !isEmail(values.notification_email)) errors.notification_email = 'Ugyldig epost';
  if (values.merchant_domain && !isValidLink(values.merchant_domain)) errors.merchant_domain = 'Ugyldig lenke';

  return {
    valid: !Object.keys(errors).length,
    errors,
  };
};

const useMerchantEventHandler = (refreshMerchantList, setVisibility, setEditMode, merchant = {}, isNew = false) => {
  const { getAccessTokenSilently } = useAuth0();

  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const onChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
    setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
  }, []);

  useEffect(() => {
    setFormValues(merchant);
  }, [merchant]);

  const onSave = async () => {
    const { valid, errors } = validateMerchantData(formValues);

    if (valid) {
      setLoading(true);
      try {
        if (isNew) {
          await createMerchant(formValues, getAccessTokenSilently);
        } else {
          await updateMerchant(formValues, getAccessTokenSilently);
        }
        await refreshMerchantList(false);
        if (isNew) setVisibility(false);
        else setFormValues(merchant);
        setEditMode(false);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    } else {
      setFormErrors(errors);
    }
  };

  const onDelete = async () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('Vil du slette forhandler?')) return;
    setLoading(true);
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    };
    const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}/${merchant.merchant_id}`;
    await fetch(url, requestOptions);
    await refreshMerchantList(false);
  };

  const onCancel = () => {
    if (isNew) setVisibility(false);
    setEditMode(false);
    setFormValues(merchant);
  };

  return {
    formValues,
    formErrors,
    loading,
    onSave,
    onChange,
    onCancel,
    onDelete,
    setFormValues,
  };
};

export default useMerchantEventHandler;
