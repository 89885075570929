import React, { useState } from 'react';

import { MenuItem } from '@mui/material';
import * as S from './styles';

const EditCustomerInfo = ({
  quote,
  handleUpdateCustomer,
  updatingCustomerInfo,
}) => {
  const [businessType, setBusinessType] = useState(quote.business_type);
  const [name, setName] = useState(quote.name);
  const [email, setEmail] = useState(quote.email);
  const [telephone, setTelephone] = useState(quote.telephone);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleUpdateCustomer({
          businessType,
          name,
          email,
          telephone,
        });
      }}
    >

      <p>
        Endrer kundeinfo for beregning på
        &nbsp;
        <strong>{`${quote.address}, ${quote.postal_code} ${quote.city}`}</strong>
      </p>
      <S.Input
        required
        placeholder="Navn"
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={(event) => setName(event.target.value)}
        disabled={updatingCustomerInfo}
      />
      <br />
      <S.SelectDropdown
        style={{ marginBottom: '15px' }}
        name="businessType"
        value={businessType}
        onChange={(event) => {
          setBusinessType(event.target.value);
        }}
      >
        <MenuItem value="personal">Privat</MenuItem>
        <MenuItem value="business">Bedrift</MenuItem>
      </S.SelectDropdown>
      <br />
      <S.Input
        required
        placeholder="E-post"
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        disabled={updatingCustomerInfo}
      />
      <br />
      <S.Input
        required
        placeholder="Telefon"
        type="text"
        id="telephone"
        name="telephone"
        value={telephone}
        onChange={(event) => setTelephone(event.target.value)}
        disabled={updatingCustomerInfo}
      />
      <br />
      <S.StandardButton type="submit">{updatingCustomerInfo ? 'Behandler' : 'Lagre'}</S.StandardButton>
    </form>
  );
};

export default EditCustomerInfo;
