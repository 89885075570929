import React, { useMemo } from 'react';
import styled from 'styled-components';
import ProgressWithBackDrop from '../../../components/progressWithBackDrop/ProgressWithBackDrop';
import { DashboardContainerWithMenu, UserList } from '../../../components/dashboard';
import useUserInfo from '../../../hooks/useUserInfo';
import SearchField from '../../../components/dashboard/searchfield/SearchField';
import useFetchData from '../../../hooks/useFetchData';
import UserPageHeader from '../../../components/dashboard/userpageheader/UserPageHeader';
import MerchantFilterSelect from '../../../components/dashboard/searchfield/MerchantFilterSelect';
import { SOLCELLEKRAFT_ID } from '../../../constants/merchant';
import * as SLocal from './styles';

const MERCHANT_LIST_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}`;
const USER_LIST_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_USERS_ENDPOINT}`;

const SearchContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: -20px;
`;

const UserPage = () => {
  const {
    userInfo,
    loading: userInfoLoading,
  } = useUserInfo({ includeAvailableRoles: true });

  const {
    loading: MerchantListLoading,
    data: merchantList,
  } = useFetchData(MERCHANT_LIST_URL, {
    enabled: (!userInfoLoading && !userInfo.merchantId),
    redirect: false,
  });

  const {
    loading: userListLoading,
    data: userList,
    refreshData: refreshUserList,
    changeUrlQuery,
    urlQueries,
  } = useFetchData(USER_LIST_URL, {
    enabled: !userInfoLoading,
    initialQueries: userInfo.merchantId ? { merchantId: userInfo.merchantId } : {},
  });

  const merchantListWithoutSol = useMemo(() => (
    merchantList.filter(({ merchant_id: merchantId }) => merchantId !== SOLCELLEKRAFT_ID)
  ), [merchantList.length]);

  const merchantFilterOptions = useMemo(() => ([
    { title: 'Alle', value: '' },
    { title: process.env.REACT_APP_COMPANY_NAME, value: 'solcellekraft' },
    // eslint-disable-next-line max-len
    ...merchantListWithoutSol.map((merchant) => ({ title: merchant.name, value: merchant.merchant_id })),
  ]), [merchantListWithoutSol.length]);

  return (
    <DashboardContainerWithMenu>
      <ProgressWithBackDrop loading={userInfoLoading || MerchantListLoading}>
        <SLocal.UserPageBody>
          <UserPageHeader
            userInfo={userInfo}
            merchantList={merchantListWithoutSol}
            refreshUserList={refreshUserList}
          />
          {
            userInfoLoading || merchantListWithoutSol.length <= 1
              ? <SearchField changeUrlQuery={changeUrlQuery} queryName="userSearchText" showHelpText />
              : (
                <SearchContainer>
                  <SearchField changeUrlQuery={changeUrlQuery} queryName="userSearchText" showHelpText />
                  <MerchantFilterSelect
                    changeUrlQuery={changeUrlQuery}
                    urlQueries={urlQueries}
                    queryName="merchantId"
                    options={merchantFilterOptions}
                  />
                </SearchContainer>
              )
          }
          <ProgressWithBackDrop loading={userListLoading} fitContent>
            {
              !userListLoading || userList.length
                ? (
                  <UserList
                    userList={userList}
                    refreshUserList={refreshUserList}
                    userInfo={userInfo}
                    merchantList={merchantListWithoutSol}
                  />
                ) : null
            }
          </ProgressWithBackDrop>
        </SLocal.UserPageBody>
      </ProgressWithBackDrop>
    </DashboardContainerWithMenu>
  );
};

export default UserPage;
