import React from 'react';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import useFetchData from '../../../hooks/useFetchData';
import useUserInfo from '../../../hooks/useUserInfo';
import ProgressWithBackDrop from '../../../components/progressWithBackDrop/ProgressWithBackDrop';
import SearchField from '../../../components/dashboard/searchfield/SearchField';
import { DashboardContainerWithMenu, MerchantPageHeader, MerchantListItem } from '../../../components/dashboard';
import { SOLCELLEKRAFT_ID } from '../../../constants/merchant';
import { MerchantPageBody } from './styles';

const MERCHANT_LIST_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}`;
const USER_LIST_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_USERS_ENDPOINT}`;
const MERCHANT_FETCH_OPTIONS = {
  initialQueries: { include: 'hasAssignCustomers' },
};

const MerchantPage = () => {
  const { userInfo, loading: userLoading } = useUserInfo({ includeAvailableRoles: true });

  const {
    loading: MerchantListLoading,
    data: merchantList,
    refreshData: refreshMerchantList,
    changeUrlQuery,
  } = useFetchData(MERCHANT_LIST_URL, MERCHANT_FETCH_OPTIONS);

  const {
    loading: userListLoading,
    data: userList,
    refreshData: refreshUserList,
  } = useFetchData(USER_LIST_URL);

  return (
    <DashboardContainerWithMenu>
      <ProgressWithBackDrop loading={userLoading || userListLoading}>
        <MerchantPageBody>
          <MerchantPageHeader
            name={userInfo.name}
            role={userInfo.role}
            refreshMerchantList={refreshMerchantList}
          />
          <SearchField changeUrlQuery={changeUrlQuery} queryName="merchantSearchText" />
          <ProgressWithBackDrop
            style={{ minHeight: '10rem' }}
            loading={MerchantListLoading && !(userLoading || userListLoading)}
            fitContent
          >
            {
              !merchantList.length && !MerchantListLoading
                ? <div>Ingen Forhandlere funnet</div>
                : (
                  <Table>
                    <TableBody>
                      {
                        merchantList.map((merchant) => (
                          <MerchantListItem
                            key={merchant.merchant_id}
                            merchant={merchant}
                            users={userList.filter(({ user_metadata: userMetadata = {} }) => (
                              userMetadata.merchant_id === merchant.merchant_id
                              || (SOLCELLEKRAFT_ID === merchant.merchant_id && !userMetadata.merchant_id && userMetadata.role !== 'superuser')
                            ))}
                            merchantList={merchantList}
                            refreshUserList={refreshUserList}
                            refreshMerchantList={refreshMerchantList}
                            userInfo={userInfo}
                            isSolcellekraft={SOLCELLEKRAFT_ID === merchant.merchant_id}
                          />
                        ))
                      }
                    </TableBody>
                  </Table>
                )
            }
          </ProgressWithBackDrop>
        </MerchantPageBody>
      </ProgressWithBackDrop>
    </DashboardContainerWithMenu>
  );
};

export default MerchantPage;
