import React from 'react';
import { Link } from 'react-router-dom';
import Page from '../../components/Page';

const ForbiddenPage = () => (
  <Page title="403 - Forbidden">
    <h1>
      <br />
      403 - Forbidden
    </h1>
    <Link to="/">
      <h3>Go Home</h3>
    </Link>
  </Page>
);

export default ForbiddenPage;
