import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as SLocal from './styles';

const getInitUser = (user = {}, merchantId = '') => ({
  id: user.user_id || user.id || null,
  email: user.email || '',
  firstName: user.given_name || user.firstName || '',
  lastName: user.family_name || user.lastName || '',
  password: '',
  role: user.user_metadata?.role || user.role || (merchantId ? 'merchant_user' : 'user'),
  merchantId: user.user_metadata?.merchant_id || user.merchantId || merchantId || '',
});

const getFormField = (isNew, isSolAdmin) => [
  { name: 'email', title: 'Epost', disabled: !isNew },
  { name: 'firstName', title: 'Fornavn' },
  { name: 'lastName', title: 'Etternavn' },
  ...(!isNew ? [{ name: 'password', title: 'Nytt passord' }] : []),
  ...(isSolAdmin
    ? [
      { name: 'role', title: 'Rolle', type: 'select' },
      { name: 'merchantId', title: 'Forhandler', type: 'select' },
    ]
    : []
  ),
];

const validate = (userData) => {
  const validationErrors = {};

  if (userData.merchantId && (userData.role === 'superuser' || userData.role === 'admin' || userData.role === 'user')) {
    validationErrors.role = 'Ugyldig rolle';
  }

  if (!userData.merchantId && (userData.role === 'merchant_admin' || userData.role === 'merchant_user')) {
    validationErrors.role = 'Ugyldig rolle';
  }

  if (!userData.email || !isEmail(userData.email)) {
    validationErrors.email = 'Ugyldig epost';
  }

  if (!userData.firstName || userData.firstName.length === 0) {
    validationErrors.firstName = 'Ugyldig fornavn';
  }

  if (!userData.lastName || userData.lastName.length === 0) {
    validationErrors.lastName = 'Ugyldig etternavn';
  }

  return validationErrors;
};

const UserForm = ({
  onSubmit,
  onCancel,
  onDelete,
  user,
  userInfo,
  isNew,
  merchants,
}) => {
  const [userToBeAdded, setUserToBeAdded] = useState(getInitUser(user, userInfo.merchantId));
  const [errors, setErrors] = useState({});
  const [showErrorFor, setShowErrorFor] = useState({});
  const formField = useMemo(() => getFormField(isNew, userInfo.isAdmin), [isNew, userInfo.isAdmin]);

  const options = useMemo(() => ({
    merchantId: [
      { value: '', title: process.env.REACT_APP_COMPANY_NAME },
      ...merchants.map((m) => ({ value: m.merchant_id, title: m.name })),
    ],
    role: userInfo.roles.map((r) => ({ value: r, title: r })),
  }), [userInfo.roles.length, merchants.length]);

  const onSubmitLocal = () => {
    const validationErrors = validate(userToBeAdded);
    setErrors(validationErrors);
    setShowErrorFor({ email: true, firstName: true, lastName: true });
    const hasError = Object.keys(validationErrors).some((key) => validationErrors[key]);
    if (hasError) return;

    onSubmit(userToBeAdded);
  };

  const onChange = useCallback((event) => {
    const { name, value = '' } = event.target;
    if (!name) return;

    setUserToBeAdded((prevState) => {
      const newState = { ...prevState, [name]: value };
      setErrors(validate(newState));
      return newState;
    });
  }, []); // RT$KjnrYa8&0

  const onBlur = useCallback((event) => {
    const { name } = event.target;
    if (!name) return;
    setShowErrorFor((prevState) => ({ ...prevState, [name]: true }));
    setErrors(validate(userToBeAdded));
  }, [userToBeAdded]);

  return (
    <SLocal.UserFormContainer isNew={isNew}>
      <SLocal.UserFormInputs>
        {
          formField.map(({ name, title, type = 'text', disabled = false }) => (type === 'select'
            ? (
              <FormControl key={name}>
                <div style={{ opacity: '0.7', fontSize: '0.8rem', marginBottom: '-3px' }}>
                  {title}
                </div>
                <Select
                  onChange={onChange}
                  name={name}
                  value={userToBeAdded[name] || ''}
                  helpertext={(errors[name]) || ''}
                  error={!!errors[name]}
                  displayEmpty
                  variant="standard"
                >
                  { (options[name] || []).map((option) => (
                    <MenuItem value={option.value || ''} key={option.value || option.title}>{option.title}</MenuItem>
                  ))}
                </Select>
                {!!errors.role && (
                  <SLocal.FormHelperErrorText>{errors.role}</SLocal.FormHelperErrorText>
                )}
              </FormControl>
            ) : (
              <TextField
                disabled={disabled}
                label={title}
                key={name}
                placeholder={title}
                onChange={onChange}
                name={name}
                value={userToBeAdded[name] || ''}
                helperText={(showErrorFor[name] && errors[name]) || ''}
                error={!!(showErrorFor[name] && !!errors[name])}
                onBlur={onBlur}
                variant="standard"
              />
            )
          ))
        }
      </SLocal.UserFormInputs>
      <SLocal.ButtonWrapper>
        <SLocal.StandardButton onClick={onSubmitLocal}>{ isNew ? 'Lag ny' : 'Lagre' }</SLocal.StandardButton>
        <SLocal.StandardButton onClick={onCancel}>Avbryt</SLocal.StandardButton>
        {
          isNew
            ? null
            : <SLocal.WarningButton onClick={onDelete}>Slett</SLocal.WarningButton>
        }
      </SLocal.ButtonWrapper>
    </SLocal.UserFormContainer>
  );
};

UserForm.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
  onDelete: () => {},
  user: {},
  userInfo: {
    roles: [],
    isAdmin: false,
    merchantId: '',
  },
  isNew: false,
  merchants: [],
};

UserForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  user: PropTypes.shape({}),
  userInfo: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
    isAdmin: PropTypes.bool,
    merchantId: PropTypes.string,
  }),
  isNew: PropTypes.bool,
  merchants: PropTypes.arrayOf(PropTypes.shape({})),
};

export default UserForm;
