import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@mui/material';
import { DashboardContainerWithMenu } from '../../../components/dashboard';
import { SettingsContext } from '../../../lib/Settings';
import * as SLocal from './styles';
import CustomerListItem from '../../../components/customers/CustomerListItem';
import CustomerMerchantChangeDialog from '../../../components/customers/CustomerMerchantChangeDialog';
import exportToExcel from '../../../lib/xlsxFile/exportCustomerListXlsxFile';

const CustomerPage = () => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const [busy, setBusy] = useState(false);
  const [customerList, setCustomerList] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [merchantList, setMerchantList] = useState();
  const [isCustomerSelectedArray, setIsCustomerSelectedArray] = useState([]);
  const [selectedMerchantId, setSelectedMerchantId] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const settings = useContext(SettingsContext);

  const listCustomers = async (userInfo) => {
    const merchantQuery = userInfo.user_metadata.merchant_id ? `?merchantId=${userInfo.user_metadata.merchant_id}` : '';
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_CUSTOMERS_ENDPOINT}${merchantQuery}`, {
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    });
    if (response.status === 200) {
      let customers = await response.json();
      if (!isAdmin && userInfo.user_metadata.role === 'user') customers = customers.filter((c) => !c.merchant_id);
      setIsCustomerSelectedArray([...new Array(customers.length)].map(() => false));
      setCustomerList(customers);
    }
  };

  useEffect(async () => {
    setBusy(true);
    const currentUser = await user;
    const accessToken = await getAccessTokenSilently();
    const userInfoResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_USERS_ENDPOINT}/${currentUser.sub}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const userInfo = await userInfoResponse.json();
    setLoggedInUser(userInfo);
    setIsAdmin(userInfo.user_metadata && (userInfo.user_metadata.role === 'superuser' || userInfo.user_metadata.role === 'admin'));

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}`, {
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    });
    if (response.status === 200) {
      const merchants = await response.json();
      const initObj = [{
        merchant_id: '',
        name: process.env.REACT_APP_COMPANY_NAME,
        key: '',
      }];
      const completeList = initObj.concat(merchants);
      setMerchantList(completeList);
    }

    await listCustomers(userInfo);
    setBusy(false);
  }, []);

  const changeMerchant = async () => {
    setBusy(true);
    const selectedCustomers = customerList.filter((_, index) => (
      isCustomerSelectedArray[index] === true
    ));
    const body = {
      ...selectedMerchantId.length > 0 && { merchantId: selectedMerchantId },
      customerUuids: selectedCustomers.map((c) => c.uuid),
      settings: JSON.stringify(settings),
    };

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_CUSTOMERS_ENDPOINT}/changemerchant`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      method: 'PATCH',
      body: JSON.stringify(body),
    });

    if (response.status > 199 && response.status < 300) {
      await listCustomers(loggedInUser);
    }
    setBusy(false);
  };

  return (
    <DashboardContainerWithMenu>
      <div>
        { busy && <CircularProgress />}
        <div>
          <div>
            <div>
              <b>Innlogget: </b>
              {loggedInUser && loggedInUser.name}
            </div>
            <div>
              <b>Rolle: </b>
              {loggedInUser && loggedInUser.user_metadata.role}
            </div>
          </div>
          <hr />
        </div>
        <div>
          <div>
            { isAdmin && (
            <div>
              <SLocal.ChangeMerchantWrapper>
                <SLocal.SelectCheckbox
                  checked={isCustomerSelectedArray.every((e) => e === true)}
                  onClick={() => {
                    const copy = isCustomerSelectedArray.map(() => (
                      !isCustomerSelectedArray.every((e) => e === true)
                    ));
                    setIsCustomerSelectedArray(copy);
                  }}
                />
                <SLocal.OpenDialogButton
                  disabled={isCustomerSelectedArray.every((e) => e === false)}
                  onClick={() => setOpenDialog(true)}
                >
                  Endre forhandler
                </SLocal.OpenDialogButton>
                { customerList && merchantList && (
                <SLocal.ExportButton
                  disabled={isCustomerSelectedArray.every((e) => e === false)}
                  onClick={async () => {
                    const selectedCustomers = customerList.filter((_, index) => (
                      isCustomerSelectedArray[index] === true
                    ));
                    await exportToExcel(selectedCustomers, merchantList);
                  }}
                >
                  Last ned
                </SLocal.ExportButton>
                )}
              </SLocal.ChangeMerchantWrapper>
              { merchantList && (
              <CustomerMerchantChangeDialog
                open={openDialog}
                setSelectedMerchantId={setSelectedMerchantId}
                selectedMerchantId={selectedMerchantId}
                merchantList={merchantList}
                handleClose={() => setOpenDialog(false)}
                changeMerchant={changeMerchant}
              />
              )}
            </div>
            )}
            {customerList && (
              <table>
                <tbody>
                  { customerList.map((c, index) => (
                    <tr key={c.uuid}>
                      <td>
                        <CustomerListItem
                          customer={c}
                          index={index}
                          isCustomerSelectedArray={isCustomerSelectedArray}
                          setIsCustomerSelectedArray={setIsCustomerSelectedArray}
                          merchantList={merchantList}
                          isAdmin={isAdmin}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          { customerList && customerList === 0
          && (<div>Ingen kunder i listen</div>)}
        </div>
      </div>
    </DashboardContainerWithMenu>
  );
};

export default CustomerPage;
