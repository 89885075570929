import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useUserInfo from '../../../hooks/useUserInfo';
import { SettingsContext } from '../../../lib/Settings';
import { DashboardContainerWithMenu } from '../../../components/dashboard';
import ProgressWithBackDrop from '../../../components/progressWithBackDrop/ProgressWithBackDrop';
import DashboardQuotePageHeader from './DashboardQuotePageHeader';
import DashboardQuotePageUpperPanel from './DashboardQuotePageUpperPanel';
import { FREE_TEXT_INITAL_ARRAY } from './FreeTextField';
import * as S from './styles';
import DashboardQuotePageOverviewPanel from './DashboardQuotePageOverviewPanel';
import TabButtons from './TabButtons';
import TabSelector from './TabSelector';

const getQuote = async (uuid, accessToken) => {
  const quoteResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${uuid}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return quoteResponse.json();
};

const getMerchantList = async (merchantId, accessToken) => {
  if (merchantId) {
    const MerchantResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}/${merchantId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return [await MerchantResponse.json()];
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch(console.error);

  return response?.json().catch(() => []);
};

// Used to update quote locked status
// Does not trigger change in update date
const changeLockedStatus = async (newLockedStatus, uuid, accessToken) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      locked: newLockedStatus,
    }),
  };
  const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${uuid}/changelockedstatus`;
  const result = await fetch(url, requestOptions).catch(console.error);
  const { locked } = await result.json();
  return locked;
};

// Used to update quote
const patchQuote = async (quote, settings, accessToken) => {
  const body = {
    roofs: JSON.stringify(quote.roofs),
    options: JSON.stringify(quote.options),
    settings: JSON.stringify({
      ...settings,
      financingOptions: quote.options.financingOptions ? quote.options.financingOptions : [],
    }),
    countyNumber: quote.county_number,
    merchantId: quote.merchant_id,
    businessType: quote.business_type,
    name: quote.name,
    email: quote.email,
    telephone: quote.telephone,
    saleStatus: quote.sale_status,
    locked: quote.locked,
    expiresAt: quote.expires_at,
  };

  const options = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  };
  const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${quote.uuid}`;
  const res = await fetch(url, options);
  return res.ok ? res.json() : undefined;
};

const DashboardQuotePage = () => {
  const { uuid } = useParams();
  const [quote, setQuote] = useState(undefined);
  const [activeTab, setActiveTab] = useState('solarSystem');
  const [merchantList, setMerchantList] = useState([]);
  const [loading, setLoading] = useState(true);
  const settings = useContext(SettingsContext);

  const { userInfo, loading: userInfoLoading, getAccessTokenSilently } = useUserInfo();
  const isMerchantUser = ['merchant_user', 'merchant_admin'].includes(userInfo.role);
  const isMerchantQuote = userInfo.isAdmin && quote?.merchant_id !== null;

  useEffect(async () => {
    if (!uuid || userInfoLoading) return;

    const accessToken = await getAccessTokenSilently();
    const [quoteData, merchantListData] = await Promise.all([
      getQuote(uuid, accessToken),
      getMerchantList(userInfo.merchantId, accessToken),
    ]).catch(console.error) || [];

    setQuote(quoteData || undefined);
    setMerchantList(merchantListData || []);
    setLoading(false);
  }, [userInfoLoading, uuid, getAccessTokenSilently]);

  // Handle changes in freetext values
  // Uses patchChanges to update quote
  const onUpdateFreeTextValues = async (data, index) => {
    const freeTextValuesCopy = [...(quote.options.freeTextValues || FREE_TEXT_INITAL_ARRAY)];
    freeTextValuesCopy[index] = data;

    const accessToken = await getAccessTokenSilently();

    // get fresh quote data to minimize the risk of overwriting data
    const freshQuote = await getQuote(quote.uuid, accessToken);
    const quoteData = await patchQuote({
      ...freshQuote,
      options: {
        ...freshQuote.options,
        freeTextValues: freeTextValuesCopy,
      },
    }, settings, accessToken);

    if (quoteData) {
      setQuote(quoteData);
    }
    return quoteData.options.freeTextValues[index];
  };

  // Handle changes in quote locked status
  const updateQuoteLockedStatus = async (wantedLockedStatus) => {
    const accessToken = await getAccessTokenSilently();

    // get fresh quote data to minimize the risk of overwriting data
    const freshQuote = await getQuote(quote.uuid, accessToken);

    // Prevent unwanted lock changes
    if (wantedLockedStatus === freshQuote.locked) {
      setQuote(freshQuote);
      return { success: true };
    }
    return changeLockedStatus(wantedLockedStatus, freshQuote.uuid, accessToken)
      .then((newLockedStatus) => {
        setQuote({
          ...freshQuote,
          locked: newLockedStatus,
        });
        return { success: true };
      })
      .catch(() => ({ success: false }));
  };

  // Handle quote status change in child component
  // Uses patchChanges to update quote
  const updateQuoteStatus = async (newStatus) => {
    const accessToken = await getAccessTokenSilently();

    // get fresh quote data to minimize the risk of overwriting data
    const freshQuote = await getQuote(quote.uuid, accessToken);
    const updatedQuote = await patchQuote({ ...freshQuote, sale_status: newStatus }, settings, accessToken);
    if (updatedQuote) {
      setQuote(updatedQuote);
      return { success: true };
    }
    return { success: false };
  };

  // Handle changes in expiration date
  // Uses patchChanges to update quote
  const updateExpirationDate = async (newExpirationDate) => {
    const accessToken = await getAccessTokenSilently();

    // get fresh quote data to minimize the risk of overwriting data
    const freshQuote = await getQuote(quote.uuid, accessToken);
    const updatedQuote = await patchQuote({ ...freshQuote, expires_at: newExpirationDate }, settings, accessToken);
    if (updatedQuote) {
      setQuote(updatedQuote);
      return { success: true };
    }
    return { success: false };
  };

  // Handle solar panel changes
  // Uses patchChanges to update quote
  const updateSolarPanelType = async (newPanelType, updatedRoofs) => {
    const accessToken = await getAccessTokenSilently();

    // get fresh quote data to minimize the risk of overwriting data
    const freshQuote = await getQuote(quote.uuid, accessToken);
    const updatedQuote = await patchQuote({
      ...freshQuote,
      roofs: updatedRoofs,
      options: {
        ...freshQuote.options,
        solarPanel: newPanelType,
      },
    }, settings, accessToken);
    if (updatedQuote) {
      setQuote(updatedQuote);
      return { success: true };
    }
    return { success: false };
  };

  // Handle roof type changes
  // Uses patchChanges to update quote
  const updateRoofingType = async (newRoofingType) => {
    const accessToken = await getAccessTokenSilently();

    // get fresh quote data to minimize the risk of overwriting data
    const freshQuote = await getQuote(quote.uuid, accessToken);
    const updatedQuote = await patchQuote({
      ...freshQuote,
      options: {
        ...freshQuote.options,
        roofing: newRoofingType,
      },
    }, settings, accessToken);
    if (updatedQuote) {
      setQuote(updatedQuote);
      return { success: true };
    }
    return { success: false };
  };

  // Handle customer info changes
  // Uses patchChanges to update quote
  const updateCustomerInfo = async (customerInfo) => {
    const accessToken = await getAccessTokenSilently();

    // get fresh quote data to minimize the risk of overwriting data
    const freshQuote = await getQuote(quote.uuid, accessToken);
    const updatedQuote = await patchQuote({
      ...freshQuote,
      business_type: customerInfo.businessType,
      name: customerInfo.name,
      email: customerInfo.email,
      telephone: customerInfo.telephone,
    }, settings, accessToken);
    if (updatedQuote) {
      setQuote(updatedQuote);
      return { success: true };
    }
    return { success: false };
  };

  // Handle customer info changes
  // Uses patchChanges to update quote
  const updateQuoteRoofs = async (roofs, roofSpaceThreshold) => {
    const accessToken = await getAccessTokenSilently();

    // get fresh quote data to minimize the risk of overwriting data
    const freshQuote = await getQuote(quote.uuid, accessToken);
    const updatedQuote = await patchQuote({
      ...freshQuote,
      roofs,
      options: {
        ...freshQuote.options,
        roofSpaceThreshold,
      },
    }, settings, accessToken);
    if (updatedQuote) {
      setQuote(updatedQuote);
      return { success: true };
    }
    return { success: false };
  };

  // Handle quote deletion
  const deleteQuote = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    };

    const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${quote.uuid}`;
    const res = await fetch(url, requestOptions).catch(console.error);

    if (res.status === 200) {
      window.location.href = '/quotes';
    }
  };

  if (!quote || !settings) {
    return null;
  }

  return (
    <DashboardContainerWithMenu>
      <ProgressWithBackDrop loading={loading}>
        <S.Wrapper>
          <DashboardQuotePageHeader merchantList={merchantList} merchantId={quote?.merchant_id} id={quote?.id} />
          <DashboardQuotePageUpperPanel
            quote={quote}
            merchantList={merchantList}
            updateQuoteStatus={updateQuoteStatus}
            updateExpirationDate={updateExpirationDate}
            updateQuoteLockedStatus={updateQuoteLockedStatus}
            updateCustomerInfo={updateCustomerInfo}
            deleteQuote={deleteQuote}
          />
          <DashboardQuotePageOverviewPanel
            quote={quote}
          />
          <TabButtons activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabSelector
            quote={quote}
            settings={settings}
            isMerchantQuote={isMerchantQuote}
            isMerchantUser={isMerchantUser}
            activeTab={activeTab}
            updateSolarPanelType={updateSolarPanelType}
            updateRoofingType={updateRoofingType}
            updateQuoteRoofs={updateQuoteRoofs}
            onUpdateFreeTextValues={onUpdateFreeTextValues}
          />

        </S.Wrapper>
      </ProgressWithBackDrop>
    </DashboardContainerWithMenu>
  );
};

export default DashboardQuotePage;
