export default (maxCalculations) => ({
  barData: {
    type: 'bar',
    data: {
      labels: [],
      datasets: [{
        label: 'Beregninger',
        data: [],
        backgroundColor: 'rgba(254,239,216,0.5)',
        yAxisID: 'calculations',
      }, {
        label: 'Adresser',
        data: [],
        type: 'line',
        color: '#D46261',
        pointStyle: 'circle',
        tension: 0.1,
        borderDash: [5],
        borderColor: 'rgba(247,191,139,1)',
        backgroundColor: 'rgba(247,191,139,1)',
        yAxisID: 'calculations',
      }, {
        label: 'kW',
        data: [],
        type: 'line',
        pointStyle: 'circle',
        tension: 0.3,
        borderDash: [5],
        backgroundColor: 'rgba(208,213,217,1)',
        yAxisID: 'power',
      }],
    },
    options: {
      legend: {
        position: 'bottom',
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        calculations: {
          type: 'linear',
          position: 'left',
          ticks: {
            stepSize: 1,
          },
          max: maxCalculations + 1,
          title: {
            display: true,
            text: 'Antall',
          },
        },
        power: {
          type: 'linear',
          position: 'right',
          title: {
            display: true,
            text: 'kW',
          },
          grid: {
            display: false,
          },
        },
      },
    },
  },
});
