// https://stackoverflow.com/a/54014428/6839733

const hsl2rgb = (h, s, l) => {
  const a = s * Math.min(l, 1 - l);
  const f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  return [f(0), f(8), f(4)];
};

const rgb2hex = (rgb) => rgb.map((x) => Math.round(x * 255).toString(16).padStart(2, 0)).join('');

const getDistinguishableColors = (quantity) => {
  const goldenAngle = 137.507764; // 180 * (3 - Math.sqrt(5))
  const s = 1; // 100%
  const l = 0.95; // 95%
  const colors = [];
  for (let i = 1; i <= quantity; i += 1) {
    const h = i * goldenAngle + 60;
    const rgb = hsl2rgb(h, s, l);
    const hex = rgb2hex(rgb).toUpperCase();
    colors.push(hex);
  }
  return colors;
};

export default getDistinguishableColors;
