import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { SelectFilter, SelectContainer } from './styles';

const MerchantFilterSelect = ({
  changeUrlQuery,
  urlQueries,
  queryName,
  options,
}) => {
  const onChangeUrlQuery = useCallback(({ target }) => {
    const { value = '' } = target;
    changeUrlQuery({ [queryName]: value }, { debounce: false });
  }, [changeUrlQuery, queryName]);

  return (
    <SelectContainer>
      <SelectFilter
        label="Forhandler filter"
        variant="outlined"
        onChange={onChangeUrlQuery}
        value={urlQueries[queryName] || ''}
        displayEmpty
      >
        { (options || []).map((option) => (
          <MenuItem value={option.value || ''} key={option.value || option.title}>{option.title}</MenuItem>
        ))}
      </SelectFilter>
      <span>Forhandler filter</span>
    </SelectContainer>
  );
};

MerchantFilterSelect.defaultProps = {
  changeUrlQuery: () => {},
  urlQueries: {},
  options: [],
};

MerchantFilterSelect.propTypes = {
  changeUrlQuery: PropTypes.func,
  urlQueries: PropTypes.shape({}),
  queryName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    title: PropTypes.string,
  })),
};

export default MerchantFilterSelect;
