import React from 'react';
import PropTypes from 'prop-types';
import FreeTextField from './FreeTextField';
import * as S from './styles';

/**
 * @param amount {number}
 * @param language {string}
 * @returns {string}
 */
const toFormattedAmount = (amount = 0, language = 'no') => (
  `${new Intl.NumberFormat(language, {
    style: 'currency',
    currency: 'NOK',
    maximumFractionDigits: 1,
  }).format(Math.round(amount)).slice(0, -1)}-`
);

export const getTotalPrices = (isBusiness, cost) => {
  // needed to support legacy calculation, not including projectPrice.
  const isLegacyCalc = cost.projectPrice == null;

  return isLegacyCalc
    ? {
      total: (isBusiness ? cost.total : cost.totalIncludingVatAndEnovaFunding) || 0,
      projectPriceIncludingVat: cost.totalIncludingVat,
      projectPrice: cost.total,
      isLegacyCalc,
    }
    : {
      total: cost.total,
      projectPriceIncludingVat: cost.projectPriceIncludingVat,
      projectPrice: cost.projectPrice,
      isLegacyCalc,
    };
};

const TopListRow = ({ title, value, show, bold }) => (
  show
    ? (
      <S.TopListRow bold={bold}>
        <span>{title}</span>
        <span>{value}</span>
      </S.TopListRow>
    ) : (
      <S.TopListRow>&nbsp;</S.TopListRow>
    )
);

TopListRow.defaultProps = {
  title: '',
  value: '',
  show: true,
  bold: false,
};

TopListRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  show: PropTypes.bool,
  bold: PropTypes.bool,
};

const DashboardQuotePageAdminViewOfMerchantQuote = ({ quote }) => {
  if (!quote) {
    return null;
  }

  const { calculations, business_type: businessType } = quote;
  const { cost } = calculations;

  const isBusiness = businessType === 'business';

  const { projectPriceIncludingVat, projectPrice } = getTotalPrices(isBusiness, cost);

  // TODO - check how additions are displayed for the merchants. Currently not visible in overview, but added to total price.

  return (
    <S.MiddlePanel>
      <S.MiddlePanelHeader><b>Forhandlerpriser ( eks. mva )</b></S.MiddlePanelHeader>
      <S.MiddlePanelMain>
        <S.CalcDetailTableRow>
          <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }} />
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>Forhandlerpris</S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>Listepris eks. mva</S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>Kundepris</S.CalcDetailColumn>
        </S.CalcDetailTableRow>

        <S.CalcDetailTableRow style={{ paddingTop: '5px' }}>
          <div style={{ flex: '1' }}>Utstyr</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.totalMerchantPurchasePrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.equipment.total - cost.equipment.totalMerchantPurchasePrice)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>

        <S.CalcDetailTableRow style={{ paddingTop: '5px' }}>
          <div style={{ flex: '1' }}>Takmontering</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.installation.totalMerchantPurchasePrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.installation.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.installation.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>

        <S.CalcDetailTableRow style={{ paddingTop: '5px' }}>
          <div style={{ flex: '1' }}>Elektrisk montering</div>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.electrical.totalMerchantPurchasePrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.electrical.total)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn>
            {toFormattedAmount(cost.electrical.totalIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>

        <S.CalcDetailTableRow style={{ paddingTop: '5px' }}>
          <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Komplett anlegg</div>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
            {toFormattedAmount(cost.totalMerchantPurchasePrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
            {toFormattedAmount(projectPrice)}
          </S.CalcDetailColumn>
          <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
            {toFormattedAmount(projectPriceIncludingVat)}
          </S.CalcDetailColumn>
        </S.CalcDetailTableRow>
      </S.MiddlePanelMain>
    </S.MiddlePanel>
  );
};

DashboardQuotePageAdminViewOfMerchantQuote.defaultProps = {
  quote: undefined,
};

const prices = {
  netPrice: PropTypes.number,
  quantity: PropTypes.number,
  total: PropTypes.number,
  totalIncludingVat: PropTypes.number,
  totalMerchantPurchasePrice: PropTypes.number,
};

DashboardQuotePageAdminViewOfMerchantQuote.propTypes = {
  quote: PropTypes.shape({
    county_number: PropTypes.number,
    roofs: PropTypes.arrayOf(PropTypes.shape({})),
    merchant_id: PropTypes.string,
    business_type: PropTypes.string,
    uuid: PropTypes.string,
    options: PropTypes.shape({
      inverters: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.shape({
          power: PropTypes.number,
          _key: PropTypes.string,
        }),
      })),
      freeTextValues: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.number,
        isDiscount: PropTypes.bool,
      })),
      solarPanel: PropTypes.shape({
        productName: PropTypes.string,
      }),
      roofing: PropTypes.string,
      isQuoteOrdered: PropTypes.bool,
      paymentMethod: PropTypes.string,
    }),
    calculations: PropTypes.shape({
      production: PropTypes.shape({
        panelCount: PropTypes.number,
        roofCount: PropTypes.number,
        roofArea: PropTypes.number,
        peakPower: PropTypes.number,
      }),
      cost: PropTypes.shape({
        sumAddition: PropTypes.number,
        sumAdditionIncludingVat: PropTypes.number,
        sumDeduction: PropTypes.number,
        sumDeductionIncludingVat: PropTypes.number,
        totalMerchantPurchasePrice: PropTypes.number,
        totalIncludingVatAndEnovaFunding: PropTypes.number,
        totalIncludingVat: PropTypes.number,
        enovaFunding: PropTypes.number,
        total: PropTypes.number,
        electrical: PropTypes.shape({
          total: PropTypes.number,
          totalIncludingVat: PropTypes.number,
          totalMerchantPurchasePrice: PropTypes.number,
          costPerPanel: PropTypes.shape(prices),
          startupCost: PropTypes.shape(prices),
        }),
        installation: PropTypes.shape({
          total: PropTypes.number,
          totalIncludingVat: PropTypes.number,
          totalMerchantPurchasePrice: PropTypes.number,
          costPerPanel: PropTypes.shape(prices),
          startupCost: PropTypes.shape(prices),
        }),
        equipment: PropTypes.shape({
          total: PropTypes.number,
          totalIncludingVat: PropTypes.number,
          totalMerchantPurchasePrice: PropTypes.number,
          ballastSystems: PropTypes.shape(prices),
          engineeringCostPerPanel: PropTypes.shape(prices),
          engineeringCostPerInstallation: PropTypes.shape(prices),
          wifiModule: PropTypes.shape(prices),
          mountingKits: PropTypes.shape(prices),
          connectors: PropTypes.shape(prices),
          cables: PropTypes.shape(prices),
          solarPanels: PropTypes.shape(prices),
          inverters: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
  }),
};

export default DashboardQuotePageAdminViewOfMerchantQuote;
