import React from 'react';
import { Link } from 'react-router-dom';
import Page from '../../components/Page';

const NotFoundPage = () => (
  <Page title="404 - Not found">
    <h1>
      <br />
      404 - Not Found!
    </h1>
    <Link to="/">
      <h3>Go Home</h3>
    </Link>
  </Page>
);

export default NotFoundPage;
