import React from 'react';
import { createGlobalStyle, ThemeProvider, css } from 'styled-components';
import { ThemeProvider as ThemeProviderMUI, createTheme, StyledEngineProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';

import BrandonTextBold from '../fonts/BrandonText-Bold.otf';
import BrandonTextLight from '../fonts/BrandonText-Light.otf';
import BrandonTextRegular from '../fonts/BrandonText-Regular.otf';
import BrandonTextRegularItalic from '../fonts/BrandonText-RegularItalic.otf';
import BrandonTextThin from '../fonts/BrandonText-Thin.otf';

const basePageStyle = css`
  width: 100%;
  max-width: 80rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const theme = {
  color: {
    primaryDark: '#3d444e',
    primaryLight: '#fefefe',
    primaryHover: '#f7a618',
    primaryBorder: '#f7a618',

    black: '#3d444e',
    darkOrange: '#a85810',
    orange: '#f07f17',
    lightOrange: '#f7a618',
    lighterOrange: '#f8b746',
    beige: '#f0ece8',
    darkBeige: '#d8d4d0',
    lighterGray: '#f0ece8',
    lightGray: '#e3e0de',
    gray: '#d8d4d0',
    red: '#f73718',
    white: '#fefefe',
  },
  screen: {
    sm: '640px',
    md: '1024px',
  },
  fontSize: {
    small: {
      xxxs: '.6rem',
      xxs: '.65rem',
      xs: '.8rem',
      sm: '1rem',
      md: '1.0625rem',
      lg: '1.125rem',
      xl: '1.25rem',
      xxl: '1.625rem',
      xxxl: '2.125rem',
    },
    medium: {
      xxxs: '.6rem',
      xxs: '.65rem',
      xs: '.8rem',
      sm: '1rem',
      md: '1.125rem',
      lg: '1.45rem',
      xl: '1.575rem',
      xxl: '1.75rem',
      xxxl: '2.25rem',
    },
    large: {
      xxxs: '.6rem',
      xxs: '.65rem',
      xs: '.8rem',
      sm: '1rem',
      md: '1.25rem',
      lg: '1.675rem',
      xl: '2.125rem',
      xxl: '2.625rem',
      xxxl: '3.625rem',
    },
  },
  style: {
    basePageStyle: `${basePageStyle}`,
  },
};

const muiTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'BrandonText, sans-serif',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: theme.fontSize.small.xs,
        color: theme.color.primaryLight,
        backgroundColor: theme.color.primaryDark,
      },
    },
  },
  palette: {
    primary: {
      main: theme.color.primaryHover,
    },
  },
});

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'BrandonText';
    src: local('BrandonTextBold'), url(${BrandonTextBold}) format('opentype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'BrandonText';
    src: local('BrandonTextLight'), url('${BrandonTextLight}') format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'BrandonText';
    src: local('BrandonTextRegular'), url('${BrandonTextRegular}') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'BrandonText';
    src: local('BrandonTextRegularItalic'), url('${BrandonTextRegularItalic}') format('opentype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'BrandonText';
    src: local('BrandonTextThin'), url('${BrandonTextThin}') format('opentype');
    font-weight: 100;
    font-style: normal;
  }

  body {
    font-family: 'BrandonText', sans-serif;
    font-size: ${theme.fontSize.large.md};
    font-weight: 400;
    color: ${theme.color.primaryDark};

    @media print {
      [role=tooltip]{
        display: none;
      }

      @page {
        margin: 2.5rem;
        size: A4;
      }
    }
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  h1 { 
    font-size: ${theme.fontSize.large.xxxl};
    margin: 0;
  }

  h2 {
    font-size: ${theme.fontSize.large.xxl};
    margin: 0;
  }

  h3 { 
    font-size: ${theme.fontSize.large.xl};
    margin: 0;
  }

  h4 { 
    font-size: ${theme.fontSize.large.lg};
    margin: 0;
  }

  h5 { 
    font-size: ${theme.fontSize.large.md};
    margin: 0;
  }

  h6 { 
    font-size: ${theme.fontSize.large.sm};
    margin: 0;
  }

  @media (max-width: ${theme.screen.md}) {
    body { font-size: ${theme.fontSize.medium.md}; }
    h1 { font-size: ${theme.fontSize.medium.xxxl}; }
    h2 { font-size: ${theme.fontSize.medium.xxl}; }
    h3 { font-size: ${theme.fontSize.medium.xl}; }
    h4 { font-size: ${theme.fontSize.medium.lg}; }
    h5 { font-size: ${theme.fontSize.medium.md}; }
    h6 { font-size: ${theme.fontSize.medium.sm}; }
  }

  @media (max-width: ${theme.screen.sm}) {    
    body { font-size: ${theme.fontSize.small.md}; }
    h1 { font-size: ${theme.fontSize.small.xxxl}; }
    h2 { font-size: ${theme.fontSize.small.xxl}; }
    h3 { font-size: ${theme.fontSize.small.xl}; }
    h4 { font-size: ${theme.fontSize.small.lg}; }
    h5 { font-size: ${theme.fontSize.small.md}; }
    h6 { font-size: ${theme.fontSize.small.sm}; }
  }

`;

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <ThemeProviderMUI theme={muiTheme}>
      <StyledEngineProvider injectFirst>
        {children}
      </StyledEngineProvider>
    </ThemeProviderMUI>
  </ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Theme;
