import React, { useState, useEffect } from 'react';
import { QuoteShape } from '../proptypes/quote';

import * as S from './styles';

const updateRoofMapping = (roofs, solarPanel, newThreshold) => {
  const minPanelCount = 8;

  return roofs.map((roof) => {
    const availableRoofArea = roof.area3d * (newThreshold / 100);
    const panelCountMax = Math.max(
      Math.floor(availableRoofArea / (solarPanel.height * solarPanel.width)),
      minPanelCount,
    );

    return {
      ...roof,
      panelCountMax,
      panelCount: roof.panelCount > panelCountMax ? panelCountMax : roof.panelCount,
      availableSpace: availableRoofArea,
    };
  });
};

const RoofTableEditable = ({
  quote,
  editRoofs,
  setEditRoofs,
  selectedRoofId,
  setSelectedRoofId,
  updateQuoteRoofs,
}) => {
  if (!quote) return null;

  const [isLoading, setIsLoading] = useState(false);
  const [threshold, setThreshold] = useState(75);
  const [workingRoofs, setWorkingRoofs] = useState(null);

  // Update workingRoofs and thresshold if anything changes
  useEffect(() => {
    setWorkingRoofs([...quote.roofs].map((roof) => ({
      ...roof,
      panelCount: roof.isSelected ? roof.panelCount : 0,
      // roofingType: roof.roofingType ? roof.roofingType : quote.options.roofing,
    })));
    setThreshold(quote.options.roofSpaceThreshold ? quote.options.roofSpaceThreshold : 75);
  }, [quote]);

  if (!workingRoofs) return null;

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={(event) => {
        event.preventDefault();
        setIsLoading(true);
        const updatedRoofs = [...workingRoofs].map((roof) => ({
          ...roof,
          isSelected: roof.panelCount > 0,
        }));
        updateQuoteRoofs(updatedRoofs, threshold)
          .then(() => {
            setIsLoading(false);
            setEditRoofs(false);
          });
      }}
    >
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <S.Thead>
          <S.LowerMapTableRow>
            <th style={{ padding: '8px 12px 10px' }}>Tak</th>
            <th style={{ padding: '8px 12px 10px' }}>kWp</th>
            {/* <th style={{ padding: '8px 12px 10px' }}>Taktype</th> */}
            <th style={{ padding: '8px 12px 10px' }}>Valgt antall panel</th>
            <th style={{ padding: '8px 12px 10px' }}>Maks</th>
          </S.LowerMapTableRow>
        </S.Thead>
        <tbody style={{ textAlign: 'right' }}>
          {
            workingRoofs.map((r, index) => (
              <tr key={r.id} style={{ backgroundColor: selectedRoofId && selectedRoofId === r.id ? '#fedfb2' : 'transparent' }}>
                <S.LowerMapTableColumn style={{ textAlign: 'left' }}><strong>{`Tak ${index + 1}`}</strong></S.LowerMapTableColumn>
                <S.LowerMapTableColumn>{`${new Intl.NumberFormat('nb').format(Math.round(r.pvgisAnnualProductionPerKwp))} kWp`}</S.LowerMapTableColumn>
                {/**
                <S.LowerMapTableColumn>
                  <S.SelectDropdown
                    name={`rooftype-${index}`}
                    value={r.roofingType}
                    disabled={isLoading}
                    onChange={(event) => {
                      const tempRoofs = [...workingRoofs];
                      tempRoofs[index].roofingType = event.target.value;
                      setWorkingRoofs(tempRoofs);
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                  >
                    {roofingTypes.map((roofing) => (
                      <MenuItem key={roofing.value} value={roofing.value}>{roofing.label}</MenuItem>
                    ))}
                  </S.SelectDropdown>
                </S.LowerMapTableColumn>
                */}
                <S.LowerMapTableColumn>
                  <S.TableInput
                    name={`panelCount-${index}`}
                    type="number"
                    min="0"
                    max={r.panelCountMax}
                    value={r.panelCount}
                    disabled={isLoading}
                    onChange={(event) => {
                      const tempRoofs = [...workingRoofs];
                      tempRoofs[index].panelCount = Math.floor(event.target.value);
                      setWorkingRoofs(tempRoofs);
                    }}
                  />
                </S.LowerMapTableColumn>
                <S.LowerMapTableColumn>{r.panelCountMax}</S.LowerMapTableColumn>
              </tr>
            ))
          }
        </tbody>
      </table>
      <S.RoofSpaceThresholdContainer>
        <S.RoofSpaceThresholdLabel>
          Utnyttelsesgrad per tak i prosent
        </S.RoofSpaceThresholdLabel>
        <div>
          <S.TableInput
            type="number"
            value={threshold}
            min={0}
            max={100}
            step={1}
            disabled={isLoading}
            onChange={(event) => {
              setThreshold(event.target.value > 100 ? 100 : event.target.value < 0 ? 0 : event.target.value);
              const newRoofs = updateRoofMapping(
                [...workingRoofs],
                quote.options.solarPanel,
                event.target.value,
              );
              setWorkingRoofs(newRoofs);
            }}
          />
        </div>
      </S.RoofSpaceThresholdContainer>
      <S.StandardButton
        type="submit"
        style={{
          marginRight: '20px',
        }}
        disabled={isLoading}
      >
        {isLoading ? 'Behandler' : 'Lagre'}
      </S.StandardButton>
      <S.LinkStyleButton
        type="button"
        disabled={isLoading}
        onClick={() => {
          setEditRoofs(!editRoofs);
          setSelectedRoofId(null);
        }}
      >
        Avbryt
      </S.LinkStyleButton>
    </form>
  );
};

export default RoofTableEditable;

RoofTableEditable.defaultProps = {
  quote: undefined,
};

RoofTableEditable.propTypes = {
  quote: QuoteShape,
};
