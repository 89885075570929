import React, { useState } from 'react';

import format from 'date-fns/format';
import nb from 'date-fns/locale/nb';

import * as S from './styles';

const ExpirationDate = ({
  quote,
  updateExpirationDate,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [newExpirationDate, setNewExpirationDate] = useState(quote.expires_at ? new Date(quote.expires_at) : new Date(+new Date() + 12096e5));

  return (
    <>
      {showDatePicker ? (
        <>
          <S.DatePicker
            calendarStartDay={1}
            dateFormat="dd.MM.yyyy"
            selected={newExpirationDate}
            locale={nb}
            disabled={quote.locked}
            onChange={(date) => {
              setNewExpirationDate(date);
            }}
          />
          <br />
          <S.StandardButton
            onClick={() => {
              setIsUpdating(true);
              updateExpirationDate(newExpirationDate.toISOString())
                .then(() => {
                  setIsUpdating(false);
                  setShowDatePicker(false);
                });
            }}
            disabled={!newExpirationDate}
          >
            {isUpdating ? 'Behandler' : 'Lagre'}
          </S.StandardButton>
        </>
      ) : (
        <b>{quote.expires_at ? format(new Date(quote.expires_at), 'dd MMMM yyyy', { locale: nb }) : 'Ikke satt'}</b>
      )}
      <br />
      <S.LinkStyleButton
        onClick={() => {
          // Reset datepicker selection if open
          if (showDatePicker) {
            setNewExpirationDate(quote.expires_at ? new Date(quote.expires_at) : new Date(+new Date() + 12096e5));
          }

          // Close date picker
          setShowDatePicker(!showDatePicker);
        }}
      >
        {showDatePicker ? 'Avbryt' : quote.expires_at ? 'Endre' : 'Sett utløpsdato'}
      </S.LinkStyleButton>
    </>
  );
};

export default ExpirationDate;
