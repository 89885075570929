import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import sanityClient from '../../lib/sanityClient';
import { ReactComponent as SolcellekraftLogo } from '../../assets/SolcellekraftLogo.svg';
import { ReactComponent as TakbytteLogo } from '../../assets/TakbytteLogo.svg';

import * as S from './styles';

import LanguageSelector from '../languageselector/LanguageSelector';
import { LanguageContext } from '../../lib/Language';

const NavBar = ({ navLinkExpandedFontColor }) => {
  const [navBarData, setNavBarData] = useState(null);
  const { language } = useContext(LanguageContext);
  const getBlock = (id) => navBarData.blocks.find(({ slug }) => slug.current === id);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useContext(ThemeContext);
  const smallScreenSize = theme.screen.sm.substring(0, theme.screen.sm.length - 2);
  const loaded = useRef(true);

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 75,
    target: window || undefined,
  });

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "component" && slug.current == "navigation-bar"]{
        blocks
      }`)
      .then((data) => {
        if (loaded.current) setNavBarData((data.length === 0) ? {} : data[0]);
      })
      .catch(console.error);

    return () => {
      loaded.current = false;
    };
  }, []);

  useEffect(() => {
    const getWidth = () => window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    const resizeListener = () => {
      if (getWidth() > smallScreenSize) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [smallScreenSize]);

  if (!navBarData) return <div>Loading...</div>;
  if (Object.keys(navBarData).length === 0) return <div>Navigation bar data not found...</div>;

  return (
    // Passing 'scrollTrigger' to the styled component as a transient prop
    // https://styled-components.com/docs/api#transient-props
    <S.StyledAppBar $menuOpen={isMenuOpen} $scrollTrigger={scrollTrigger}>
      <S.StyledToolBar $menuOpen={isMenuOpen}>
        <S.LogoWrapper>
          <S.StyledLink
            $menuOpen={isMenuOpen}
            $scrollTrigger={scrollTrigger}
            $navLinkExpandedFontColor={navLinkExpandedFontColor}
            to="/"
            onClick={() => {
              if (window.location.pathname === '/') {
                window.location.reload();
              }
            }}
          >
            {
              process.env.REACT_APP_SITE === 'TAKBYTTE'
                ? <TakbytteLogo style={{ maxHeight: '40px', minHeight: '15px', marginTop: '5px' }} />
                : <SolcellekraftLogo style={{ maxHeight: '32px', minHeight: '10px' }} />
            }
          </S.StyledLink>
        </S.LogoWrapper>
        <S.NavLinksWrapper $menuOpen={isMenuOpen}>
          <S.StyledLink
            $scrollTrigger={scrollTrigger}
            $navLinkExpandedFontColor={navLinkExpandedFontColor}
            to={process.env.REACT_APP_SITE === 'TAKBYTTE' ? '/takbytte' : '/solar-power'}
          >
            {getBlock('solar-power-link').string[language]}
          </S.StyledLink>
          <S.StyledLink
            $scrollTrigger={scrollTrigger}
            $navLinkExpandedFontColor={navLinkExpandedFontColor}
            to="/customer-service"
          >
            {getBlock('customer-service-link').string[language]}
          </S.StyledLink>
          <S.StyledLink
            $scrollTrigger={scrollTrigger}
            $navLinkExpandedFontColor={navLinkExpandedFontColor}
            to="/product"
          >
            {getBlock('product-link').string[language]}
          </S.StyledLink>

        </S.NavLinksWrapper>
        <LanguageSelector
          menuOpen={isMenuOpen}
          scrollTrigger={scrollTrigger}
          navLinkExpandedFontColor={navLinkExpandedFontColor}
        />
        <S.BurgerIconButton
          $menuOpen={isMenuOpen}
          $scrollTrigger={scrollTrigger}
          $navLinkExpandedFontColor={navLinkExpandedFontColor}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div />
          <div />
          <div />
        </S.BurgerIconButton>
      </S.StyledToolBar>
      <S.SmallScreenNavLinksWrapper $menuOpen={isMenuOpen}>
        <S.StyledLink
          $menuOpen={isMenuOpen}
          $scrollTrigger={scrollTrigger}
          $navLinkExpandedFontColor={navLinkExpandedFontColor}
          to={process.env.REACT_APP_SITE === 'TAKBYTTE' ? '/takbytte' : '/solar-power'}
        >
          {getBlock('solar-power-link').string[language]}
        </S.StyledLink>
        <S.StyledLink
          $menuOpen={isMenuOpen}
          $scrollTrigger={scrollTrigger}
          $navLinkExpandedFontColor={navLinkExpandedFontColor}
          to="/customer-service"
        >
          {getBlock('customer-service-link').string[language]}
        </S.StyledLink>
        <S.StyledLink
          $menuOpen={isMenuOpen}
          $scrollTrigger={scrollTrigger}
          $navLinkExpandedFontColor={navLinkExpandedFontColor}
          to="/product"
        >
          {getBlock('product-link').string[language]}
        </S.StyledLink>
      </S.SmallScreenNavLinksWrapper>
    </S.StyledAppBar>
  );
};

NavBar.propTypes = {
  navLinkExpandedFontColor: PropTypes.string,
};

NavBar.defaultProps = {
  navLinkExpandedFontColor: undefined,
};

export default NavBar;
