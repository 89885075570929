import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { SettingsContext } from '../../../lib/Settings';
import ProgressWithBackDrop from '../../progressWithBackDrop/ProgressWithBackDrop';
import useMerchantEventHandler from './useMerchantEventHandler';
import MerchantFormButtons from './MerchantFormButtons';
import * as S from './styles';

const getMerchantFormConfig = (counties = []) => {
  if (!counties.length) return [];

  const countyOptions = counties.map((county) => ({
    key: county._key,
    value: county.slug.current,
    label: county.name,
  }));

  return [
    { label: 'Navn', name: 'name' },
    { label: 'Adresse 1', name: 'address_line1' },
    { label: 'Adresse 2', name: 'address_line2' },
    {
      label: 'Postnummer',
      name: 'postal_code',
      type: 'number',
      maxLength: 10,
    },
    { label: 'Poststed', name: 'city' },
    {
      label: 'Fylke',
      name: 'county',
      type: 'select',
      options: countyOptions,
    },
    {
      label: 'Telefon',
      name: 'phone',
      type: 'number',
      maxLength: 15,
    },
    { label: 'Epost', name: 'email' },
    { label: 'Varsel Epostadresse', name: 'notification_email' },
    { label: 'Forhandler domene', name: 'merchant_domain' },
    { label: 'Organisation number', name: 'organization_number' },
  ];
};

const MerchantContactForm = ({ setVisibility, merchant, refreshMerchantList, editMode: initialEditMode, isNew, isSolcellekraft }) => {
  const settings = useContext(SettingsContext);
  const [editMode, setEditMode] = useState(initialEditMode);

  const {
    formValues,
    formErrors,
    loading,
    onSave,
    onChange,
    onCancel,
    onDelete,
  } = useMerchantEventHandler(refreshMerchantList, setVisibility, setEditMode, merchant, isNew);

  const merchantFormConfig = useMemo(() => (
    getMerchantFormConfig(settings?.priceDifferentiation?.counties)
  ), [settings?.priceDifferentiation?.counties?.lenght]);

  return (
    <ProgressWithBackDrop loading={loading} fitContent>
      <S.MerchantForm>
        <S.FormControl>
          {
            merchantFormConfig.map(({ label, name, type = 'text', options = [], maxLength = 100 }) => (
              type === 'select' && editMode
                ? (
                  <FormControl variant="standard" key={`Select-${name}`}>
                    <S.InputLabel style={{ padding: 0 }}>{label}</S.InputLabel>
                    <S.StandardSelect
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      error={!!formErrors[name]}
                      label={label || ''}
                      placeholder={label}
                      value={formValues[name] || ''}
                      onChange={onChange}
                      name={name}
                    >
                      { options.map(({ key, value, label: labelText }) => <MenuItem key={key} value={value}>{labelText}</MenuItem>) }
                    </S.StandardSelect>
                  </FormControl>
                )
                : (
                  <S.StandardTextField
                    key={`TextField-${name}`}
                    error={!!formErrors[name]}
                    label={label}
                    placeholder={label}
                    helperText={formErrors[name] || ''}
                    value={formValues[name] || (editMode ? '' : ' ')}
                    type={type}
                    InputProps={{ inputProps: { maxLength } }}
                    onChange={onChange}
                    name={name}
                    disabled={!editMode || (isSolcellekraft && name === 'name')}
                    variant="standard"
                  />
                )
            ))
          }
        </S.FormControl>
        <MerchantFormButtons
          isNew={isNew}
          editMode={editMode}
          hasAssignCustomer={merchant.hasAssignCustomer}
          onCancel={onCancel}
          onSave={onSave}
          onDelete={onDelete}
          setEditMode={setEditMode}
          isSolcellekraft={isSolcellekraft}
        />
      </S.MerchantForm>
    </ProgressWithBackDrop>
  );
};

MerchantContactForm.defaultProps = {
  setVisibility: () => {},
  editMode: false,
  isNew: false,
  merchant: {},
  refreshMerchantList: () => {},
  isSolcellekraft: false,
};

MerchantContactForm.propTypes = {
  setVisibility: PropTypes.func,
  editMode: PropTypes.bool,
  isNew: PropTypes.bool,
  merchant: PropTypes.shape({
    name: PropTypes.string,
    hasAssignCustomer: PropTypes.bool,
  }),
  refreshMerchantList: PropTypes.func,
  isSolcellekraft: PropTypes.bool,
};

export default MerchantContactForm;
