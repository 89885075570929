import styled from 'styled-components';

export const ProgressContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;

export const ProgramsBackDrop = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  backdrop-filter: brightness(80%) blur(.5px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FixedProgramsBackDrop = styled.div`
  position: fixed;
  left: 13rem;
  top: 0;
  height: 100vh;
  width: calc(100vw - 13rem);
  display: flex;
  backdrop-filter: brightness(80%) blur(.5px);
  justify-content: center;
  align-items: center;
`;

export const ChildrenContainer = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
`;
