import React, { useState, useReducer, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import sanityClientBase from '@sanity/client';
import { LanguageContext } from './Language';

export const PROJECT_ID = process.env.REACT_APP_SANITY_PROJECT_ID;
export const DATASET = process.env.REACT_APP_SANITY_DATASET;

const sanityClient = sanityClientBase({
  projectId: PROJECT_ID,
  dataset: DATASET,
  apiVersion: '2021-04-20',
  useCdn: true,
});

const reducer = (data, newData) => ({ ...data, ...newData });
const initialSanityData = {};
const SanityDataContext = React.createContext();

export const SanityProvider = ({ children }) => {
  const [sanityData, setSanityData] = useReducer(reducer, initialSanityData);
  const sanityDataObj = useMemo(() => ({ sanityData, setSanityData }), [sanityData, setSanityData]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <SanityDataContext.Provider value={sanityDataObj}>
      {children}
    </SanityDataContext.Provider>
  );
};

SanityProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const getCleanQuery = (query) => query.replace(/(\r\n|\n|\r| )/gm, '');
const getQueryHashCode = (query) => {
  // eslint-disable-next-line no-bitwise
  const num = getCleanQuery(query).split('').reduce((a, b) => (a << 5) - a + b.charCodeAt(0), 0);
  return Math.abs(num).toString(36);
};

const getSanityQuery = (query, preLoad = []) => `{
  ${[query, ...preLoad].map((q) => `"${getQueryHashCode(q)}": ${getCleanQuery(q)}`).join(',\n')}
}`;

export const useSanity = (query, preLoad = []) => {
  const fallbackData = {};
  const { language } = useContext(LanguageContext);
  const { sanityData, setSanityData } = useContext(SanityDataContext);
  const queryHash = getQueryHashCode(query);
  const data = sanityData[queryHash];
  const [loading, setLoading] = useState(!data);

  const getBlock = useCallback((id) => data?.blocks?.find(({ slug }) => slug.current === id), [data]);
  const getStringWithLanguage = useCallback((id) => getBlock(id)?.string?.[language] ?? '', [language, getBlock]);

  if (!data) {
    sanityClient.fetch(getSanityQuery(query, preLoad))
      .then((res) => {
        if (res) setSanityData(res);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }

  return { data: data ?? fallbackData, loading, getBlock, getStringWithLanguage };
};

export default sanityClient;
