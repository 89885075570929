import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { languageOptions, LanguageContext } from '../../lib/Language';

import * as S from './styles';

const LanguageSelector = ({ menuOpen, scrollTrigger, navLinkExpandedFontColor }) => {
  const { language, languageChangeHandler } = useContext(LanguageContext);

  return (
    <S.Select
      MenuProps={{
        disableScrollLock: true,
      }}
      variant="standard"
      value={language}
      onChange={(event) => languageChangeHandler(event.target.value)}
      $menuOpen={menuOpen}
      $scrollTrigger={scrollTrigger}
      $navLinkExpandedFontColor={navLinkExpandedFontColor}
    >
      {Object.entries(languageOptions).map(([id, name]) => (
        <MenuItem key={id} value={id}>{name}</MenuItem>
      ))}
    </S.Select>
  );
};

LanguageSelector.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  scrollTrigger: PropTypes.bool.isRequired,
  navLinkExpandedFontColor: PropTypes.string,
};

LanguageSelector.defaultProps = {
  navLinkExpandedFontColor: undefined,
};

export default LanguageSelector;
