/* eslint-disable no-param-reassign */
import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';
import { format, parseISO } from 'date-fns';
import getDistinguishableColors from '../getDistinguishableColors';
import { BUSINESS_TYPE_MAPPING, getDeviation, getDeviationHeaders, getRoofColumns } from './common';

const getTotalPrices = (isBusiness, cost) => {
  // needed to support legacy calculation, not including projectPrice.
  const isLegacyCalc = cost.projectPrice == null;

  return isLegacyCalc
    ? {
      total: (isBusiness ? cost.total : cost.totalIncludingVatAndEnovaFunding) || 0,
      projectPrice: (isBusiness ? cost.total : cost.totalIncludingVat),
    }
    : {
      total: cost.total,
      projectPrice: (isBusiness ? cost.projectPrice : cost.projectPriceIncludingVat),
    };
};

function getPrice(isBusiness, value) {
  if (isBusiness) {
    return value.total;
  }
  return value.totalIncludingVat;
}

const solcellekraftExportQuotesXlsxFile = async (quotes, roofTypesMap, settings, merchantList = []) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet('Beregninger');

  const RoofGroupColumnWidth = 5;
  const ColumnIndexOfFirstRoofGroup = 47;

  // Prepare columns with header and width
  const { roofColumns, maxSelectedRoofCount } = getRoofColumns(quotes, [
    { header: 'lengde', key: 'Length' },
    { header: 'bredde', key: 'Width' },
    { header: 'areal', key: 'Area' },
    { header: 'helning', key: 'Slope' },
    { header: 'retning', key: 'Azimuth' },
  ]);

  ws.columns = [
    { header: '#', key: 'id', width: 8 },
    { header: 'Dato', key: 'date', width: 12 },
    { header: 'Tid', key: 'time', width: 12 },
    { header: 'Forhandler', key: 'merchant', width: 20 },
    { header: 'Forhandler ID', key: 'merchantId', width: 20 },
    { header: 'Navn', key: 'name', width: 30 },
    { header: 'First Name', key: 'first_name', width: 30 },
    { header: 'Middle Name', key: 'middle_name', width: 30 },
    { header: 'Last Name', key: 'last_name', width: 30 },
    { header: 'Adresse', key: 'address', width: 30 },
    { header: 'Postnr.', key: 'postalCode', width: 8 },
    { header: 'Poststed', key: 'city', width: 20 },
    { header: 'Fylke', key: 'county', width: 20 },
    { header: 'Kart', key: 'mapLink', width: 20 },
    { header: 'Telefon', key: 'telephone', width: 12 },
    { header: 'Epost', key: 'email', width: 35 },
    { header: 'Privat / bedrift', key: 'businessType', width: 18 },

    { header: 'Dashboard-lenke', key: 'dashboardLink', width: 20 },
    { header: 'Kunde-lenke', key: 'customerLink', width: 20 },

    { header: 'Status', key: 'status', width: 14 },

    { header: 'Taktype', key: 'roofingType', width: 12 },
    { header: 'Paneltype', key: 'panelName', width: 18 },
    { header: 'Paneler', key: 'panelCount', width: 12 },
    { header: 'Takareal', key: 'panelRoofArea', width: 12 },
    { header: 'Effekt', key: 'peakPower', width: 12 },
    { header: 'Produksjon', key: 'estimatedAnnualProduction', width: 12 },

    { header: 'Pris - Paneler', key: 'solarPanels', width: 15 },
    { header: 'Invertere', key: 'inverters', width: 15 },
    { header: 'Kabler', key: 'cables', width: 15 },
    { header: 'Koblinger', key: 'connectors', width: 15 },
    { header: 'Monteringssett', key: 'mountingKits', width: 15 },
    // Removing ballast systems may be temporarily!
    // { header: 'Ballastsystemer', key: 'ballastSystems', width: 15 },
    { header: 'Wifi-modul', key: 'wifiModule', width: 15 },
    { header: 'Prosj. fastpris', key: 'engineeringCostPerInstallation', width: 15 },
    { header: 'Prosj. per panel', key: 'engineeringCostPerPanel', width: 15 },
    { header: 'Utstyr total', key: 'equipmentCostTotal', width: 15 },

    { header: 'Takmont. oppstart', key: 'installationStartupCost', width: 15 },
    { header: 'Takmont. per panel', key: 'installationPerPanel', width: 15 },
    { header: 'Takmont. total', key: 'installationCostTotal', width: 15 },

    { header: 'El.mont. oppstart', key: 'electricalStartupCost', width: 15 },
    { header: 'El.mont. per panel', key: 'electricalPerPanel', width: 15 },
    { header: 'El.mont. total', key: 'electricalCostTotal', width: 15 },

    ...getDeviationHeaders(false),
    { header: 'Prosjekt pris', key: 'projectPrice', width: 15 },
    ...getDeviationHeaders(true),
    { header: 'Enova-støtte', key: 'enovaFunding', width: 15 },
    { header: 'Total pris eks mva', key: 'totalWithoutVat', width: 20 },
    { header: 'Total pris inkl mva', key: 'totalWithVat', width: 20 },
    { header: 'Månedlig sum', key: 'monthlyPayment', width: 15 },
    ...roofColumns,
  ];

  // Bold font on 1st row
  ws.getRow(1).eachCell((cell) => {
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'center' };
  });

  quotes.forEach((quote) => {
    const selectedRoofs = quote.roofs.filter((roof) => roof.isSelected);
    const isCompany = quote.business_type === 'business';
    const { total, projectPrice } = getTotalPrices(isCompany, quote.calculations.cost);

    const merchant = merchantList.find((m) => m.merchant_id === quote.merchant_id);
    const merchantName = merchant ? merchant.name : '';
    const merchantId = merchant ? merchant.organization_number : '';
    const dashboardLink = `${window.location.origin}/quote/${quote.uuid}`;
    const customerLink = merchant && merchant.merchant_domain ? `${merchant.merchant_domain}/quote/${quote.uuid}` : `${process.env.REACT_APP_PAGE_URL}/quote/${quote.uuid}`;
    const mapLink = `https://www.kommunekart.com/?funksjon=VisPunkt&x=${quote.map_position.y}&y=${quote.map_position.x}&markering=1`;

    const county = settings.priceDifferentiation.counties.find((c) => c.countyNumber === quote.county_number);
    const countyName = county ? county.name : '';

    const row = {
      id: quote.id,
      date: format(parseISO(quote.timestamp), 'dd.MM.yyyy'),
      time: format(parseISO(quote.timestamp), 'HH:mm:ss'),
      merchant: merchantName,
      merchantId,
      name: quote.name,
      firstName: '',
      middleName: '',
      lastName: '',
      address: quote.address,
      postalCode: quote.postal_code,
      city: quote.city,
      county: countyName,
      mapLink: {
        text: mapLink,
        hyperlink: mapLink,
      },
      telephone: quote.telephone,
      email: quote.email,
      businessType: BUSINESS_TYPE_MAPPING[quote.business_type],

      dashboardLink: {
        text: dashboardLink,
        hyperlink: dashboardLink,
      },
      customerLink: {
        text: customerLink,
        hyperlink: customerLink,
      },

      status: settings.saleStatus[quote.sale_status],

      roofingType: roofTypesMap[quote.options.roofing] || quote.options.roofing,
      panelName: quote.options.solarPanel.productName,
      panelCount: quote.calculations.production.panelCount,
      panelRoofArea: quote.calculations.production.roofArea,
      peakPower: quote.calculations.production.peakPower,
      estimatedAnnualProduction: quote.calculations.production.estimatedAnnualProduction,

      solarPanels: getPrice(isCompany, quote.calculations.cost.equipment.solarPanels),
      inverters: quote.business_type === 'business'
        ? quote.calculations.cost.equipment.inverters
          .map((_inverter) => _inverter.total).reduce((a, b) => a + b, 0)
        : quote.calculations.cost.equipment.inverters
          .map((_inverter) => _inverter.totalIncludingVat).reduce((a, b) => a + b, 0),
      mountingKits: getPrice(isCompany, quote.calculations.cost.equipment.mountingKits),
      cables: getPrice(isCompany, quote.calculations.cost.equipment.cables),
      connectors: getPrice(isCompany, quote.calculations.cost.equipment.connectors),
      wifiModule: getPrice(isCompany, quote.calculations.cost.equipment.wifiModule),
      // Removing ballast systems may be temporarily!
      /*
      ballastSystems: quote.business_type === 'business'
        ? quote.calculations.cost.equipment.ballastSystems.total
        : quote.calculations.cost.equipment.ballastSystems.totalIncludingVat,
       */
      engineeringCostPerInstallation: getPrice(isCompany, quote.calculations.cost.equipment.engineeringCostPerInstallation),
      engineeringCostPerPanel: getPrice(isCompany, quote.calculations.cost.equipment.engineeringCostPerPanel),
      equipmentCostTotal: getPrice(isCompany, quote.calculations.cost.equipment.total),
      installationStartupCost: getPrice(isCompany, quote.calculations.cost.installation.startupCost),
      installationPerPanel: getPrice(isCompany, quote.calculations.cost.installation.costPerPanel),
      installationCostTotal: getPrice(isCompany, quote.calculations.cost.installation.total),
      electricalStartupCost: getPrice(isCompany, quote.calculations.cost.electrical.startupCost),
      electricalPerPanel: getPrice(isCompany, quote.calculations.cost.electrical.costPerPanel),
      electricalCostTotal: getPrice(isCompany, quote.calculations.cost.electrical.total),

      projectPrice,
      ...getDeviation(quote.options.freeTextValues, quote.business_type === 'business'),
      enovaFunding: quote.business_type === 'business'
        ? 0
        : quote.calculations.cost.enovaFunding,
      totalWithoutVat: quote.business_type === 'business' ? Math.round(total) : Math.round(total / 1.25),
      totalWithVat: quote.business_type === 'business' ? Math.round(total * 1.25) : Math.round(total),
      monthlyPayment: quote.calculations.cost.monthlyPayment,
    };

    selectedRoofs.forEach((roof, index) => {
      row[`roof${index + 1}Length`] = Math.round(roof.length * 10) / 10;
      row[`roof${index + 1}Width`] = Math.round(roof.width * 10) / 10;
      row[`roof${index + 1}Area`] = Math.round(roof.area3d * 10) / 10;
      row[`roof${index + 1}Slope`] = Math.round(roof.slope * 10) / 10;
      row[`roof${index + 1}Azimuth`] = Math.round(roof.azimuth * 10) / 10;
    });
    ws.addRow(row);
  });

  // Add borders
  const columnCount = ws.getRow(1).cellCount;
  ws.eachRow((row, rowNumber) => {
    // Add thick left border to the leftmost cell
    row.getCell(1).border = { left: { style: 'medium' } };
    for (let i = 1; i < columnCount + 1; i += 1) {
      // Add thick right border to all cells, and add thick bottom border if cells are on 1st row
      row.getCell(i).border = rowNumber !== 1 ? { right: { style: 'medium' } } : { right: { style: 'medium' }, bottom: { style: 'medium' } };
    }
  });

  // Add background colors
  const colors = getDistinguishableColors(maxSelectedRoofCount + 11);

  ws.eachRow((row) => {
    for (let colNum = 1; colNum <= ColumnIndexOfFirstRoofGroup; colNum += 1) {
      let colorIndex = -1;
      switch (true) {
        // Index and timestamp columns
        case (colNum <= 4): colorIndex = 0; break;
        // Personalia columns
        case (colNum <= 15): colorIndex = 1; break;
        // status
        case (colNum <= 16): colorIndex = 2; break;
        // Details columns
        case (colNum <= 22): colorIndex = 3; break;
        // Equipment cost columns
        case (colNum <= 31): colorIndex = 4; break;
        // Installation cost columns
        case (colNum <= 34): colorIndex = 5; break;
        // Electrical cost columns
        case (colNum <= 37): colorIndex = 6; break;
        // Deviation additions columns
        case (colNum <= 40): colorIndex = 7; break;
        // Project Price
        case (colNum <= 41): colorIndex = 8; break;
        // Deviation discount column and Enova
        case (colNum <= 43): colorIndex = 9; break;
        // Total and loan
        case (colNum <= 47): colorIndex = 10; break;
        // will not happen
        default: colorIndex = 0;
      }

      row.getCell(colNum).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: `FF${colors[colorIndex]}` },
      };
    }

    for (let i = 0; i < maxSelectedRoofCount; i += 1) {
      for (
        let colNum = ColumnIndexOfFirstRoofGroup + (i * RoofGroupColumnWidth);
        colNum < ColumnIndexOfFirstRoofGroup + RoofGroupColumnWidth + (i * RoofGroupColumnWidth);
        colNum += 1) {
        row.getCell(colNum).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: `FF${colors[i + 7]}` },
        };
      }
    }
  });

  // Write to file
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `${format(new Date(), 'yyyy-MM-dd HH.mm.ss')} - Solcellekraft-beregninger.xlsx`);
};

export default solcellekraftExportQuotesXlsxFile;
