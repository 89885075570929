export const VAT_PERCENTAGE = 25;
export const DEVIATION_HEADERS = [
  { header: 'Tillegg 1', key: 'freeTextValue-1', width: 15, isDiscount: false },
  { header: 'Tillegg 2', key: 'freeTextValue-2', width: 15, isDiscount: false },
  { header: 'Tillegg 3', key: 'freeTextValue-3', width: 15, isDiscount: false },
  { header: 'Fratrekk', key: 'freeTextValue-4', width: 15, isDiscount: true },
];

export const BUSINESS_TYPE_MAPPING = {
  business: 'Bedrift',
  personal: 'Privat',
};

export const getDeviation = (freeTextValues = [], isBusiness = false) => {
  const obj = {};

  DEVIATION_HEADERS.forEach(({ key }) => {
    const { value = 0 } = freeTextValues.find(({ id }) => id === key) || {};
    const valueIncludingVAT = Math.round(value * (1 + VAT_PERCENTAGE / 100));
    obj[key] = isBusiness ? value : valueIncludingVAT;
  });

  return obj;
};

export const getDeviationHeaders = (discount = false) => DEVIATION_HEADERS
  .filter(({ isDiscount }) => isDiscount === discount);

export const getRoofColumns = (quotes, columns = []) => {
  const maxSelectedRoofCount = Math.max(...quotes.map((quote = {}) => quote.roofs?.filter((roof = {}) => roof.isSelected)?.length ?? 1));

  return {
    roofColumns: new Array(maxSelectedRoofCount).fill(undefined).flatMap((_, index) => columns.map(({ header, key }) => (
      { header: `Tak${index + 1} ${header}`, key: `roof${index + 1}${key}`, width: 14 }
    ))),
    maxSelectedRoofCount,
  };
};
