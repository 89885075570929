import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { SOLCELLEKRAFT_ID } from '../constants/merchant';

const getRoles = async (accessToken, role) => {
  const query = role ? `?callerRole=${role}` : '';
  const rolesResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_USERS_ENDPOINT}/roles${query}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return rolesResponse.json();
};

const getInitialUserValues = () => ({
  name: '',
  role: '',
  merchantId: '',
  roles: [],
  isAdmin: false,
  isSolUser: false,
  isMerchantUser: false,
});

/**
 * get user data
 * @param getAccessTokenSilently
 * @param includeAvailableRoles
 * @param useSolMerchantId
 * @return {Promise<{role: string, merchantId: string, permissions: *, roles: string[], name: string, isAdmin: boolean, id, isSolUser: boolean, isMerchantUser: boolean}>}
 */
const getUserData = async (getAccessTokenSilently, includeAvailableRoles, useSolMerchantId) => {
  const accessToken = await getAccessTokenSilently();
  const decodedJWT = jwtDecode(accessToken);

  const userData = decodedJWT['https://rainfall/userData'] || {};

  const merchantId = (!useSolMerchantId && userData.merchantId === SOLCELLEKRAFT_ID)
    ? ''
    : userData.merchantId;

  const roles = includeAvailableRoles ? await getRoles(accessToken, userData.role) : [];

  return {
    name: userData.name || '',
    role: userData.role || '',
    merchantId,
    roles,
    isAdmin: (userData.role === 'superuser' || userData.role === 'admin'),
    permissions: decodedJWT.permissions,
    id: decodedJWT.sub,
    isSolUser: userData.merchantId === SOLCELLEKRAFT_ID,
    isMerchantUser: userData.merchantId !== SOLCELLEKRAFT_ID,
  };
};

/**
 * @param {{ includeAvailableRoles: boolean?, useSolMerchantId: boolean? }} options
 * @returns {{
 *    userInfo: {role: string, merchantId: string, roles: [], name: string, isAdmin: boolean, isSolUser: boolean, isMerchantUser: boolean},
 *    getAccessTokenSilently: function,
 *    loading: boolean
 * }}
 */
const useUserInfo = ({ includeAvailableRoles = false, useSolMerchantId = false } = {}) => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [userInfo, setUserInfo] = useState(getInitialUserValues);
  const [loading, setLoading] = useState(!userInfo.id);

  useEffect(() => {
    getUserData(getAccessTokenSilently, includeAvailableRoles, useSolMerchantId)
      .then((data) => {
        setUserInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        history.push('/forbidden');
      });
  }, []);

  return {
    userInfo,
    getAccessTokenSilently,
    loading,
  };
};

export default useUserInfo;
