import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Add from '@mui/icons-material/Add';
import MerchantContactForm from '../merchantforms/MerchantContactForm';
import { UserInfo, StandardButton } from './styles';

const MerchantPageHeader = ({ name, role, refreshMerchantList }) => {
  const [ShowNewMerchant, setShowNewMerchant] = useState(false);

  return (
    <div>
      <Collapse in={ShowNewMerchant} timeout="auto" unmountOnExit>
        <b>Legg til forhandler:</b>
        <MerchantContactForm
          setVisibility={setShowNewMerchant}
          refreshMerchantList={refreshMerchantList}
          editMode
          isNew
        />
      </Collapse>
      <Collapse in={!ShowNewMerchant} timeout="auto" unmountOnExit>
        <UserInfo>
          <div>
            <b>Innlogget: </b>
            {name}
            <b>Rolle: </b>
            {role}
          </div>
          <StandardButton
            style={{ width: '250px' }}
            variant="outlined"
            onClick={() => setShowNewMerchant(true)}
            startIcon={<Add />}
          >
            Legg til forhandler
          </StandardButton>
        </UserInfo>
      </Collapse>
    </div>
  );
};

export default MerchantPageHeader;

MerchantPageHeader.defaultProps = {
  name: '',
  role: '',
  refreshMerchantList: () => {},
};

MerchantPageHeader.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  refreshMerchantList: PropTypes.func,
};
