import styled from 'styled-components';
import Button from '@mui/material/Button';

export const Container = styled.div`
  background: white;
  padding: 1rem;
`;

export const Header = styled.h5`
  padding-bottom: 0.5rem;
  border-bottom: 1px solid;
`;

export const Table = styled.table`
  width: 100%;
  font-size: 1rem;
  table-layout: fixed;
`;

export const Td = styled.td`
  text-align: center;
  height: 2.2rem;
  
  > * {
    margin: 0 1rem;

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  grid-gap: 0.7rem;
  justify-content: flex-end
`;

export const StandardButton = styled(Button)`
  width: 12rem;
  background-color: ${({ theme }) => `${theme.color.lightOrange}`};
  color: white;

  &:hover {
    background: ${({ theme }) => `${theme.color.primaryHover}90`};
  }
`;
