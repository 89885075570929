import React, { useState } from 'react';

import { MenuItem } from '@mui/material';
import * as S from './styles';

const RoofTypeSelector = ({
  quote,
  roofingTypes,
  updateRoofingType,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <>
      {/* <label htmlFor="solarPanel">Valgt taktype</label> */}
      <S.SelectDropdown
        name="roofingType"
        value={quote.options.roofing}
        disabled={quote.locked || isUpdating}
        onChange={(event) => {
          setIsUpdating(true);
          const newRoofingType = roofingTypes.find((roofing) => roofing.value === event.target.value);
          updateRoofingType(newRoofingType.value)
            .then(() => {
              setIsUpdating(false);
            });
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {roofingTypes.map((roofing) => (
          <MenuItem key={roofing.value} value={roofing.value}>{roofing.label}</MenuItem>
        ))}
      </S.SelectDropdown>
    </>
  );
};

export default RoofTypeSelector;
