import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DownloadIcon from '@mui/icons-material/GetApp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import CancelIcon from '@mui/icons-material/Cancel';
import format from 'date-fns/format';
import nb from 'date-fns/locale/nb';
import { MenuItem } from '@mui/material';
import { SettingsContext } from '../../../lib/Settings';
import exportQuotesXlsxFile from '../../../lib/xlsxFile/exportQuotesXlsxFile';
import roofTypesMap from './roofTypeMapping.json';
import * as S from './styles';
import ExpirationDate from './ExpirationDate';
import { QuoteShape } from '../proptypes/quote';
import EditCustomerInfo from './EditCustomerInfo';

const DashboardQuotePageUpperPanel = ({
  quote,
  merchantList,
  updateQuoteStatus,
  updateExpirationDate,
  updateQuoteLockedStatus,
  updateCustomerInfo,
  deleteQuote,
}) => {
  const settings = useContext(SettingsContext);
  const [editCustomerInfo, setEditCustomerInfo] = useState(false);
  const [updatingCustomerInfo, setUpdatingCustomerInfo] = useState(false);
  const [updatingQuoteStatus, setUpdatingQuoteStatus] = useState(false);
  const [lockLoading, setLockLoading] = useState(false);

  // Used to decide which domain to use in "Open quote in customer view" link
  const { merchant_domain: merchantDomain = '' } = useMemo(
    () => merchantList.find((merchant) => merchant.merchant_id === quote.merchant_id) || {},
    [merchantList?.length, quote?.merchant_id],
  );

  if (!settings || !quote) return null;

  const handleUpdateLockedStatus = async (newLockedStatus) => {
    setLockLoading(true);
    updateQuoteLockedStatus(newLockedStatus)
      .then(() => {
        setLockLoading(false);
      });
  };

  const handleUpdateQuoteStatus = async (status) => {
    setUpdatingQuoteStatus(true);
    updateQuoteStatus(status)
      .then(() => {
        setUpdatingQuoteStatus(false);
      });
  };

  const handleUpdateCustomer = async (customer) => {
    setUpdatingCustomerInfo(true);
    updateCustomerInfo(customer)
      .then(() => {
        setUpdatingCustomerInfo(false);
        setEditCustomerInfo(false);
      });
  };

  return (
    <S.UpperPanel>
      <S.UpperLeftPanel>
        {editCustomerInfo ? (
          <EditCustomerInfo
            quote={quote}
            handleUpdateCustomer={handleUpdateCustomer}
            updatingCustomerInfo={updatingCustomerInfo}
          />
        ) : (
          <>
            <div style={{ fontSize: '2rem' }}><b>{quote.name}</b></div>
            <div style={{ fontSize: '1.4rem' }}>
              {`${quote.address}, ${quote.postal_code} ${quote.city}`}
            </div>
            <div style={{ marginTop: '20px' }}>
              <span>Kundetype: </span>
              {quote.business_type === 'business' ? 'Bedrift' : 'Privat'}
            </div>
            <div>
              <span>E-post: </span>
              <S.BasicLink href={`mailto:${quote.email}`}>{quote.email}</S.BasicLink>
            </div>
            <div>
              <span>Telefon: </span>
              <S.BasicLink href={`tel:${quote.telephone.replace('+', '00').replace(' ', '')}`}>{quote.telephone}</S.BasicLink>
            </div>
          </>
        )}

        <S.ButtonWrapper>
          <S.StandardButton
            onClick={() => {
              setEditCustomerInfo(!editCustomerInfo);
            }}
          >
            {editCustomerInfo ? (
              <>
                <span>Avbryt redigering</span>
                <CancelIcon />
              </>
            ) : (
              <>
                <span>Rediger kunde</span>
                <PermContactCalendarIcon />
              </>
            )}
          </S.StandardButton>
          <S.StandardButton
            href={merchantDomain ? `${merchantDomain}/quote/${quote.uuid}` : `${process.env.REACT_APP_PAGE_URL}/quote/${quote.uuid}`}
            target="_blank"
          >
            <span>Se kundevisning</span>
            <LinkIcon />
          </S.StandardButton>
          <S.StandardButton
            onClick={() => handleUpdateLockedStatus(!quote.locked)}
            disabled={lockLoading}
          >
            <span>{quote.locked ? 'Lås opp beregning' : 'Lås beregning'}</span>
            {quote.locked ? <LockIcon /> : <LockOpenIcon />}
          </S.StandardButton>
          <S.StandardButton
            onClick={() => exportQuotesXlsxFile([quote], roofTypesMap, settings, merchantList)}
          >
            <span>Last ned .xsls</span>
            <DownloadIcon />
          </S.StandardButton>
          <S.StandardButton
            onClick={() => {
              if (window.confirm('Er du sikker på at du vil slette beregningen? Dette kan ikke angres.')) {
                deleteQuote();
              }
            }}
            disabled={quote.locked}
          >
            <span>Slett</span>
            <DeleteForeverIcon />
          </S.StandardButton>
        </S.ButtonWrapper>
      </S.UpperLeftPanel>
      <S.UpperRightPanel>
        <div>
          Status
          <br />
          <S.SelectDropdown
            name="status"
            value={quote.sale_status}
            disabled={quote.locked || updatingQuoteStatus}
            onChange={(event) => {
              handleUpdateQuoteStatus(event.target.value);
            }}
          >
            <MenuItem value="NOT_ORDERED">Beregning</MenuItem>
            <MenuItem value="OFFER_SENT">Tilbud</MenuItem>
            <MenuItem value="ORDERED">Bestilling</MenuItem>
            <MenuItem value="SALE">Salg</MenuItem>
            <MenuItem value="REJECTED">Avvist tilbud</MenuItem>
          </S.SelectDropdown>
        </div>
        <div style={{ marginTop: '20px' }}>
          Opprettet
          <br />
          <b>{`${format(new Date(quote.timestamp), 'dd. MMMM yyyy - HH:mm', { locale: nb })}`}</b>
        </div>
        {quote.timestamp !== quote.updated_at ? (
          <div style={{ marginTop: '10px' }}>
            Sist oppdatert
            <br />
            <b>{`${format(new Date(quote.updated_at), 'dd. MMMM yyyy - HH:mm', { locale: nb })}`}</b>
          </div>
        ) : null}
        <div style={{ marginTop: '10px' }}>
          Gyldig til
          <br />
          <ExpirationDate quote={quote} updateExpirationDate={updateExpirationDate} />
        </div>
      </S.UpperRightPanel>
    </S.UpperPanel>
  );
};

DashboardQuotePageUpperPanel.defaultProps = {
  quote: undefined,
  merchantList: [],
};

DashboardQuotePageUpperPanel.propTypes = {
  quote: QuoteShape,
  merchantList: PropTypes.arrayOf(PropTypes.shape({
    merchant_id: PropTypes.string,
    name: PropTypes.string,
  })),
  updateQuoteStatus: PropTypes.func.isRequired,
  updateExpirationDate: PropTypes.func.isRequired,
  updateQuoteLockedStatus: PropTypes.func.isRequired,
};

export default DashboardQuotePageUpperPanel;
