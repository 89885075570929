import React, { useCallback, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';
import { SearchTextField } from './styles';

const SEARCH_TOOL_TIP = 'Norske tegn skiller mellom store og små bokstaver';

const EndAdornment = ({ onClick, show }) => (
  show
    ? (
      <InputAdornment position="end">
        <IconButton
          size="large"
          onClick={onClick}
          color="secondary"
        >
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    )
    : null
);

EndAdornment.defaultProps = {
  onClick: () => {},
  show: false,
};

EndAdornment.propTypes = {
  onClick: PropTypes.func,
  show: PropTypes.bool,
};

const getHelpText = (str = '') => {
  if (str.toLocaleLowerCase().includes('å')) return SEARCH_TOOL_TIP;
  if (str.toLocaleLowerCase().includes('æ')) return SEARCH_TOOL_TIP;
  if (str.toLocaleLowerCase().includes('ø')) return SEARCH_TOOL_TIP;
  return ' ';
};

const SearchField = ({ changeUrlQuery, queryName, showHelpText }) => {
  const [internalValue, setInternalValue] = useState('');

  const onChangeUrlQuery = useCallback(({ target }) => {
    const { value = '' } = target;
    setInternalValue(value);
    const searchTerm = value
      .split(' ')
      .filter((e) => e.length > 2)
      .join(' ');

    changeUrlQuery({ [queryName]: encodeURIComponent(searchTerm) });
  }, [changeUrlQuery, queryName]);

  return (
    <SearchTextField
      helperText={showHelpText ? getHelpText(internalValue) : ' '}
      label="Søk"
      onChange={onChangeUrlQuery}
      variant="outlined"
      value={internalValue || ''}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: <EndAdornment position="end" onClick={onChangeUrlQuery} show={!!internalValue} />,
      }}
    />
  );
};

SearchField.defaultProps = {
  changeUrlQuery: () => {},
  urlQueries: {},
  showHelpText: false,
};

SearchField.propTypes = {
  changeUrlQuery: PropTypes.func,
  urlQueries: PropTypes.shape({}),
  queryName: PropTypes.string.isRequired,
  showHelpText: PropTypes.bool,
};

export default SearchField;
